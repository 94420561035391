/* App.css */
.privacy-policy-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .privacy-policy-container h1,
  .privacy-policy-container h2,
  .privacy-policy-container h3 {
    color: #ffffff;
  }
  
  .privacy-policy-container p,
  .privacy-policy-container ul
  .privacy-policy-container li {
    color: #ffffff;
    line-height: 1.6;
    text-align: left;
  }
  
  .privacy-policy-container ul {
    margin-left: 20px;
  }
  
  .linki{
    color: violet;
  }