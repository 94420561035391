/* src/components/ThreadsViewer.css */

/* Contenedor padre */
.threads-viewer {
  display: flex;
  flex-direction: row;
  width: 100%;
  /* Elimina height: 100vh; */
  min-height: 0;
  padding-top: 0.5em;
  /* Antes tenías max-height: 93vh; */
  /* Unificamos la altura en relación al header fijo (60px). */
  height: calc(100vh - 68px);
}


.agents-list {
  flex: 1;
  width: 20%;
  background-color: #20f4f135;
  padding: 20px;
  border-radius: 20px;
  border: 2px solid #20F4F2;
}

.agents-list-items {
  list-style: none;
  padding: 0;
  color: white;
}

.preview-text-message{
  margin-top: 5px;
}

.agent-item {
  padding: 15px;
  margin: 10px 0;
  border-radius: 0px;
  border-bottom: 1px solid white;
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
  background-color: #2a3b47;
  background: transparent;
  display: flex;
  align-items: left;
}

/* Estilos para .agent-item en modo claro */
.threads-viewer.light-mode .agent-item {
  border-bottom: 1px solid #43434373; /* Borde oscuro cuando está en modo claro */
}

.agent-item:hover {
  transform: scale(1.02);
}

.agent-name {
  font-size: medium;
  color: white;
  margin: 0;
}

.threads-list {
  display: flex;
  flex-direction: column;
  /* Elimina height: 100%; */
  overflow: hidden;
  flex: 1 1 40%;
  min-width: 0;
  background-color: #1A1742;
  padding: 0px;
  border-radius: 0px;
  min-height: 0;
}


/* src/components/ThreadsViewer.css */

/* Estilo para el avatar en la lista de hilos */
.avatar {
  width: 3.5em;
  height: 3.5em;
  border-radius: 8px; /* Esquinas ligeramente redondeadas */
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: bold;
  font-size: 1em;
  margin-right: 10px;
  flex-shrink: 0; /* Evita que la imagen se reduzca */
  /* Opcional: Añadir sombra para resaltar */
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  align-self: center;
}

/* src/components/ThreadsViewer.css */

/* Contenedor del avatar en la lista de hilos */
.avatar-container {
  position: relative; /* Necesario para posicionar el ícono de WhatsApp */
  width: 3em;
  height: 3em;
  border-radius: 8px; /* Esquinas ligeramente redondeadas */
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: bold;
  font-size: 1em;
  margin-right: 10px;
  flex-shrink: 0; /* Evita que la imagen se reduzca */
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  align-self: center;
}

/* Iniciales dentro del avatar */
.avatar-initials {
  z-index: 1; /* Asegura que las iniciales estén por encima del ícono de WhatsApp */
}

/* Ícono de WhatsApp en la lista de hilos */
.whatsapp-icon {
  position: absolute;
  bottom: -0.2em;
  right: -0.2em;
  background-color: #25D366;
  border-radius: 50%;
  padding: 0;
  color: #ffffff; /* Verde oficial de WhatsApp */
  font-size: 1.4em;
}


/* Estilo para el avatar en el detalle del hilo */
.avatar-header {
  width: 50px;
  height: 50px;
  border-radius: 8px; /* Esquinas ligeramente redondeadas */
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: bold;
  font-size: 1.2em;
  margin-right: 15px;
  flex-shrink: 0; /* Evita que la imagen se reduzca */
  /* Opcional: Añadir sombra para resaltar */
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

/* Opcional: Ajustar el tamaño y la tipografía de las iniciales si es necesario */
.avatar, .avatar-header {
  font-family: 'Arial', sans-serif; /* Puedes cambiar la fuente según tu preferencia */
}

/* Contenedor del avatar en el detalle del hilo */
.avatar-header-container {
  position: relative; /* Necesario para posicionar el ícono de WhatsApp */
  width: 50px;
  height: 50px;
  border-radius: 8px; /* Esquinas ligeramente redondeadas */
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: bold;
  font-size: 1.2em;
  margin-right: 15px;
  flex-shrink: 0; /* Evita que la imagen se reduzca */
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
.thread-phone-number {
  display: none;
}


.message-search-input{
  width: 10em !important;
}
}


/* Iniciales dentro del avatar-header */
.avatar-header-initials {
  z-index: 1; /* Asegura que las iniciales estén por encima del ícono de WhatsApp */
}

/* Ícono de WhatsApp en el detalle del hilo */
.whatsapp-icon-header {
  position: absolute;
  bottom: -0.2em;
  right: -0.3em;
  background-color: #25D366;
  border-radius: 50%;
  padding: 0;
  color: #ffffff; /* Verde oficial de WhatsApp */
  font-size: 1.1em;
}



/* Contenedor del encabezado de la lista */
/* Encabezado de la lista de hilos */
.threads-list-header {
  padding: 0.4em;
  padding-left: 1.5em;
  padding-right: 1.5em;
  border-bottom: solid 2px #010321;
  background-color: #1A1742;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-between; /* Distribuye los elementos a los extremos */
  height: 4em;
}

/* Contenedor Izquierdo */
.header-left {
  display: flex;
  align-items: center;
  flex: 1; /* Ocupa el espacio disponible */
  gap: 10px;
}

/* Contenedor Derecho */
.header-right {
  align-items: center;
  justify-content: flex-end; /* Alinea el contenido a la derecha */
  flex: 1; /* Ocupa el espacio disponible */
}


.pending-switch-container {
  display: flex;
  align-items: center;
  color: #987FE2;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: medium;
  gap: 10px; /* Espacio entre elementos */
}

.pending-switch-container span {
  margin: 0px;
}

/* Estilos del interruptor */
.switch {
  position: relative;
  display: inline-block;
  width: 2em;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.pending-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
  width: 2.5em;
}

.pending-slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

.switch input:checked + .pending-slider {
  background-color: rgba(170, 211, 161, 0.3);

}

.switch input:checked + .pending-slider:before {
  transform: translateX(20px);
  background-color: #59C34B;
}
/* Estilos para el filtro de ordenamiento */
.messages {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  color: #987FE2;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: medium;
  margin: 0;
  margin-left: 1.4em;
  margin-bottom: 0.4em;
  }
  
  .order-filter span {
  user-select: none; /* Evita la selección de texto al hacer clic */
  }
  

.caret-icon {
  margin-left: 5px;
  color: #987FE2;
}

.order-dropdown-messages {
  position: absolute;
  top: 2em;
  right: 0;
  background: linear-gradient(to bottom, #030320,  #4F2A7E);
  box-shadow: inset 0 0 25px #4F2A7E;
  border-radius: 20px;
  padding: 5px 0;
  z-index: 1000;
  width: 15em;
  padding: 0.8em;
}

/* Para indicar que hay un submenú */
.option.has-submenu {
  position: relative; /* el submenú se ubicará en relación a esta opción */
}

/* El submenú “Enviar al CRM” */
.move-dropdown-content {
  position: absolute;
  top: 90%;           /* justo debajo de la opción padre */
  left: -0.8em;        /* desplaza un poco a la izquierda */
  background: linear-gradient(to bottom,  #40226D, #4E297D);
  box-shadow: inset 0 0 25px #4F2A7E;
  z-index: 1000;
  width: 14.7em;
  padding: 6px;
  border-radius: 0px 0px 20px 20px;
  display: none;

}




.order-dropdown-messages div {
  padding: 5px 15px;
  color: #ffffff;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: medium;
  cursor: pointer;
  text-transform: uppercase;
  text-align: left;
  
}

.order-dropdown-messages div:hover {
  transform: translateY(0px); /* Levanta la imagen al hacer hover */
}


/* Estilos para la imagen Control-Agente-IA */
.control-agente-ia-icon {
  width: 2em; /* Ajusta el tamaño según tus necesidades */
  cursor: pointer; /* Añade un cursor de puntero si la imagen es interactiva */
}

/* Panel derecho con los mensajes */
.thread-details {
  flex: 2 1 60%;  /* Puede ser 60% del ancho */
  display: flex;
  flex-direction: column;
  background-color: #010321;
  /* Quitar max-height y dejar que ocupe el 100% */
  overflow: hidden;
  /* Fondo con imagen si quieres (ya lo tienes) */
  background-image: url('../assets/background-chat.png');
  background-color: #010321;
  background-size: auto;
  background-repeat: repeat;
  background-attachment: fixed;
  position: relative;
  /* Sin forzar height, hereda del padre (.threads-viewer) */
}



/* Contenido principal de la conversación */
.thread-details-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  /* Quitar la altura fija y dejar que crezca */
  overflow: hidden;
}


/* Contenedor scrolleable de mensajes */
.message-history {
  flex: 1;            /* Para que ocupe todo el espacio restante */
  overflow-y: auto;   /* Scroll en los mensajes */
  display: flex;
  flex-direction: column;
  width: 100%;
  list-style: none;
  margin: 0;
  padding: 10px;
  box-sizing: border-box;
  color: white;
}


/* Eliminar estilos innecesarios de .message-history en modo responsive */
/* Ya no se aplica max-height ni overflow yendo en el JS */

.loading,
.error {
  text-align: center;
  font-size: 18px;
  color: #ffffff;
}

.thread-item {
  padding: 25px 20px 25px 20px;
  border-radius: 0px;
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
  background-color: #2a3b47;
  background: transparent;
  display: flex;
  align-items: center;
  gap: 15px;
  border-bottom: solid 1px #68379D;
  min-width: 0; /* Allow shrinking */
  overflow: visible; /* Permitir que el contenido sobresalga */
  position: relative; /* Mantener relativo para el posicionamiento absoluto de .move-dropdown */
}


/* Estilos para .agent-item en modo claro */
.threads-viewer.light-mode .thread-item {
  border-bottom: 1px solid #a6a5a5; /* Borde oscuro cuando está en modo claro */
}

/* Ajustar el ancho de .thread-item */
.thread-item {
  width: 100%; /* Ocupa todo el ancho del contenedor */
  box-sizing: border-box; /* Incluye padding y border en el cálculo del ancho */
}

.threads-list-items {
  height: 90vh;
  max-height: 90vh; /* Establece la altura máxima al 30% de la altura del viewport */
  overflow-y: auto; /* Habilita el scroll vertical */
  overflow-x: hidden;
  list-style: none;
  padding: 0;
  margin: 0;
  min-width: 0;
}


/* Ajustar elementos internos */
.thread-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  flex-wrap: nowrap; /* Cambiar a wrap si prefieres que los elementos se envuelvan */
  flex: 1 1 0;
  min-width: 0; /* Allow shrinking */
}

.thread-date-info{
  font-size: small;
}

.thread-item:hover {

}

.whatsapp-logo {
  flex-shrink: 0; /* Prevent image from shrinking */
  width: 35px;
  height: 35px;
  margin-right: 10px;
  align-self: center;
}

/* Estilos para el contenedor del icono del chat web en la lista de hilos */
.web-chat-avatar-container {
  width: 3em;
  height: 3em;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  flex-shrink: 0;
}

.web-chat-icon {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0;
}

/* Estilos para el contenedor del icono del chat web en los detalles del hilo */
.web-chat-avatar-header-container {
  width: 3em;
  height: 3em;
  border-radius: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
  flex-shrink: 0;
}

.web-chat-icon-header {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0;
}



.favorite-star {
  flex-shrink: 0; /* Prevent the star icon from shrinking */
  margin-right: 5px;
}

.thread-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  flex: 1;
}

.thread-details-preview {
  flex: 1 1 0;
  min-width: 0; /* Allow shrinking */
  overflow: hidden; /* Hide overflow */
}

.thread-name {
  font-weight: medium;
  color: white;
  margin: 0;
}

/* Estilo para .thread-date-info en modo oscuro (verde agua) */
.thread-info .thread-date-info {
  color:#AC9ED4; /* Color verde agua para modo oscuro */
}

/* Estilo para .thread-date-info en modo claro */
.threads-viewer.light-mode .thread-info .thread-date-info {
  color: #434343; /* Color oscuro cuando está en modo claro */
}

/* Estilo para .message-timestamp en modo oscuro (verde agua) */
.message-timestamp {
  color: white; /* Color verde agua para modo oscuro */
  font-size: 1em;
}

/* Estilo para .message-timestamp en modo claro */
.threads-viewer.light-mode .message-timestamp {
  color: #434343; /* Color oscuro cuando está en modo claro */
}

.thread-preview-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Contenedor principal que incluye el logo y los detalles del remitente */
.thread-info-sender {
  display: flex;
  align-items: center; /* Alinea el logo con el inicio de los textos */
  gap: 15px; /* Espacio entre el logo y los textos */
  margin-left: 0.5em;
}


.thread-info-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap; /* Evita que los elementos se envuelvan a una nueva línea */
  padding: 0.2em 1.5em;
  border-bottom: solid 2px #010321;
  padding-top: 0.6em;
  height: 4em;
  flex-shrink: 0;
  position: sticky;
}



.thread-sender {
  display: flex;
  align-items: center;
  gap: 10px;
}

/* Estilos para el logo de WhatsApp */
.whatsapp-logo-header {
  width: 50px;
  height: 60px;
  border-radius: 50%;
  flex-shrink: 0; /* Evita que el logo se reduzca */
}

/* Contenedor de los detalles del remitente (nombre y teléfono) */
.sender-details {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Centra verticalmente los textos dentro del contenedor */
}

/* Estilos para el nombre del remitente */
.thread-sender-name {
  font-size: 1.1em;
  font-weight: 500;
  color: white;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.thread-last-message{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.9em;
}

/* Estilos para el número de teléfono */
span.thread-phone-number {
  font-size: 1em;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  color: #987FE2;
  margin-top: 4px; /* Espacio pequeño entre el nombre y el teléfono */
}

.message-item {
  margin-bottom: 10px;
  display: flex;
  padding: 0.5em;
  background: none;
}

.message-item.user {
  justify-content: flex-start;
}

.message-item.assistant {
  justify-content: flex-end;
}

.message-content {
  padding: 10%;
  color: white;
  text-align: left;
}

.message-text {
  font-size: 0.95em;
}
/* Estilos para enlaces dentro de los mensajes (modo oscuro) */
.message-text a {
  color: #ab96e9; /* Azul brillante para enlaces */
  text-decoration: none; /* Subrayado para indicar que es un enlace */
  word-break: break-all; /* Permite que las URLs largas se dividan en líneas */
  overflow-wrap: break-word; /* Asegura que las palabras largas se rompan si es necesario */
}

.message-content.user {
  /* Aplicación del degradado para mensajes del user */
  background: linear-gradient(
    to bottom,
    rgba(149, 59, 245, 0.179), 
    rgba(87, 35, 143, 0.85)
  );
}

.message-content.assistant {
  /* Aplicación del degradado para mensajes del assistant */
  background: linear-gradient(
    to bottom,
    rgba(64, 191, 213, 0.39),
    rgba(64, 106, 213, 0.39),
    rgba(103, 64, 213, 0.39)
  );
}

.message-timestamp {
  font-size: 12px;
  margin-top: 5px;
  display: block;
  color: white;
}

.new-message-container {
  display: flex;
  gap: 10px;
  padding: 1em;
  align-items: center
}
/* Estilos para los nuevos íconos de mensajes */
.message-icons {
  display: flex;
  gap: 10px; /* Espacio entre los íconos */
  margin-right: 10px; /* Espacio entre los íconos y el input */
  align-items: center;
}

.message-icon {
  width: 40px; /* Ajusta el tamaño según tus preferencias */
  height: 40px;
  cursor: pointer;
  transition: transform 0.2s;
}
.message-icon-one {
  width: 55px; /* Ajusta el tamaño según tus preferencias */
  cursor: pointer;
  transition: transform 0.2s;
}


.message-icon:hover {
  transform: scale(1.2); /* Efecto al pasar el mouse */
}
.new-message-input {
  flex: 1;
  padding: 13px 20px; /* Reducido para disminuir la altura */
  border-radius: 50px; /* Ajustado para una apariencia más compacta */
  border: 1px solid #ffffff;
  background-color: #ffffff36;
  color: white;
  font-family: 'Poppins';
  font-size: medium;
  padding-left: 4.5em;
}

.new-message-input::placeholder {
  color: white;
}

.send-message-button {
  padding: 0px 0px;
  border-radius: 20px;
  border: none;
  background-color: #ffffff00;
  color: white;
  cursor: pointer;
  transition: background-color 0.2s;
  font-family: 'Poppins';
}
/* Spinner básico */
.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border-left-color: #09f;
  animation: spin 1s linear infinite;
  display: inline-block;
}

@keyframes spin {
  to { transform: rotate(360deg); }
}

.send-button-image {
  width: 55px;
}

.whatsapp-logo-header {
  width: 40px;
  height: 40px;
  margin-right: 8px;
  vertical-align: middle;
}

.thread-phone-number {
  font-size: 14px;
  color: white;
  margin-bottom: 5px;
  font-weight: 600;
}

.toggle-response-button {
  padding: 10px;
  margin-left: 20px;
  border-radius: 20px;
  border: none;
  cursor: pointer;
  font-size: 14px;
}

.toggle-response-button.on {
  background-color: #20F4F2;
  color: black;
}

.toggle-response-button.off {
  background-color: #E10BAB;
  color: white;
}

/* Añadir media queries para pantallas más pequeñas */
@media (max-width: 1634px) {
  .threads-viewer {
    width: 100%; /* Ocupa el 90% del ancho de la pantalla */
    margin: 0 auto; /* Centrar el contenedor */
    padding-left: 0;
    padding-right: 0;
  }

  .agents-list,
  .threads-list,
  .thread-details {
    max-width: none; /* Eliminar max-width en píxeles */
  }

  .message-history {
    width: 100%; /* Ocupa todo el ancho disponible dentro de thread-details */
  }

  /* Ajustar el ancho de .thread-item */
  .thread-item {
    width: 100%; /* Ocupa todo el ancho del contenedor */
    box-sizing: border-box; /* Incluye padding y border en el cálculo del ancho */
  }

  /* Evitar desbordamiento horizontal */
  .threads-list-items {
    overflow-x: hidden;
  }

  /* Ajustar elementos internos */
  .thread-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    flex-wrap: nowrap; /* Cambiar a wrap si prefieres que los elementos se envuelvan */
  }

  .thread-details-preview {
    flex: 1;
    min-width: 0; /* Necesario para que el overflow funcione correctamente */
  }

  .thread-details-preview p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; /* Cambia a normal si prefieres que el texto se envuelva */
    max-width: 110px;
  }

  .thread-date-info {
    flex-shrink: 0; /* Evita que este elemento se reduzca */
  }

  .whatsapp-logo {
    flex-shrink: 0; /* Evita que el logo se reduzca */
  }
}

/* Sidebar styles */
.threads-sidebar {
  background-color: #000; /* Fondo negro */
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Alinear elementos al inicio */
  padding-top: 20px;
  padding-left: 10px; /* Añadir padding izquierdo si es necesario */
  padding-right: 1%;
}

.threads-sidebar-menu {
  list-style: none;
  padding: 0;
  margin: 0;
}

.threads-sidebar-icon {
  width: 35px;
  height: 35px;
  margin-right: 10px; /* Espacio entre el ícono y el texto */
}

.threads-sidebar-item {
  position: relative; /* Necesario para el posicionamiento relativo del tooltip */
  display: flex;
  align-items: center;
  margin-bottom: 35px;
  cursor: pointer;
}

.threads-sidebar-text {
  color: #b8b2b2;
  font-size: small;
  font-weight: 600;
}

.threads-sidebar-item:hover .threads-sidebar-text {
  color: #fff; /* Cambia a blanco al pasar el mouse */
}
.titulos-threads{
  font-size: x-large;
  text-align: start;
  padding-left: 3%;
}

/* Tooltip */
.threads-sidebar-item::after {
  content: attr(data-tooltip); /* El texto del tooltip proviene del atributo data-tooltip */
  position: absolute;
  left: 110%; /* Posicionar el tooltip justo al lado del icono */
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: smaller;
  white-space: nowrap;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  transform: translateY(-30%);
  top: 50%; /* Alineación vertical centrada */
}

/* Mostrar el tooltip al pasar el mouse */
.threads-sidebar-item:hover::after {
  opacity: 1;
  transform: translateY(-30%) translateX(2px); /* Pequeño desplazamiento al mostrarlo */
}

.auto-response-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.add-contact{
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  margin-right: 0.5em;
}

.auto-response-icon {
  width: 30%;
  margin-right: 15px;
}

/* Estilos para el interruptor */
.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
  top: 0;
  left: 0;
}

.move-option{
  cursor: pointer;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.threads-viewer .threads-sidebar-item,
.threads-viewer .threads-sidebar-icon {
  color: white;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 20px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #20F4F2;
}

input:checked + .slider:before {
  transform: translateX(20px);
}

.tooltip-wrapper {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.tooltip-wrapper-dos {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.tooltip-wrapper-dos:hover::after {
  content: attr(title); /* Mostrar el texto del título en el tooltip */
  position: absolute;
  bottom: 125%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  white-space: nowrap;
  font-size: 12px;
  z-index: 1;
  opacity: 1;
  transition: opacity 0.2s;
}

.tooltip-wrapper-dos:hover::before {
  content: '';
  position: absolute;
  bottom: 110%;
  left: 50%;
  transform: translateX(-50%);
  border-width: 5px;
  border-style: solid;
  border-color: #333 transparent transparent transparent;
}

/* Modo Claro */
.threads-viewer.light-mode {
  background-color: #F2F2F2; /* Fondo general */
}

.threads-viewer.light-mode .agents-list,
.threads-viewer.light-mode .threads-list,
.threads-viewer.light-mode .thread-details {
  background-color: #F2F2F2;
  border: 2px solid #434343; /* Bordes */
}

.threads-viewer.light-mode .agent-item,
.threads-viewer.light-mode .thread-item {
  background-color: #F2F2F2;
  color: #434343; /* Texto */
}

.threads-viewer.light-mode .thread-sender-name,
.threads-viewer.light-mode .thread-phone-number,
.threads-viewer.light-mode .message-timestamp {
  color: #434343; /* Color del texto */
}

.threads-viewer.light-mode .new-message-input {
  background-color: #F2F2F2; /* Fondo del input */
  color: #434343; /* Texto del input */
  border: 1px solid #434343; /* Bordes */
}

.message-history.light-mode {
  background-color: #F2F2F2; /* Fondo del input */
}

.threads-viewer.light-mode .send-message-button {
  background-color: #584DF2; /* Botón de enviar */
  color: white;
}

.threads-viewer.light-mode .message-content.assistant {
  background-color: #D3D3D3; /* Mensajes del asistente */
}

.threads-viewer.light-mode .message-content.user {
  background-color: #FFFFFF; /* Mensajes del usuario */
}

.threads-viewer.light-mode .threads-sidebar {
  background-color: #F2F2F2; /* Fondo de la barra lateral */
}

.threads-viewer.light-mode .threads-sidebar-item,
.threads-viewer.light-mode .threads-sidebar-icon {
  color: #434343;
}

/* Modo Claro General */
.threads-viewer.light-mode {
  background-color: #F2F2F2; /* Fondo general */
  color: #434343; /* Color del texto general */
}

/* Ajuste específico para nombres y números de teléfono */
.threads-viewer.light-mode p.thread-sender-name,
.threads-viewer.light-mode p.thread-phone-number {
  color: #434343; /* Color del texto para el nombre del remitente y número de teléfono */
}

/* Estilos específicos para el modo claro */
.threads-viewer.light-mode .agents-list-items,
.threads-viewer.light-mode .thread-details-content,
.threads-viewer.light-mode .thread-info-header,
.threads-viewer.light-mode .thread-info,
.threads-viewer.light-mode .message-history,
.threads-viewer.light-mode .new-message-container,
.threads-viewer.light-mode .threads-list-items {
  color: #434343; /* Cambiar color de todos los textos */
}

.threads-viewer.light-mode .titulos-threads {
  color: #434343; /* Cambiar el color de los títulos de secciones (h2) */
}

.threads-viewer.light-mode .agent-name,
.threads-viewer.light-mode .thread-sender-name,
.threads-viewer.light-mode .thread-phone-number,
.threads-viewer.light-mode .thread-preview-text,
.threads-viewer.light-mode .message-timestamp,
.threads-viewer.light-mode .message-text {
  color: #434343; /* Cambiar color del texto de elementos de agentes, hilos y mensajes */
}

.threads-viewer.light-mode .message-content.user,
.threads-viewer.light-mode .message-content.assistant {
  color: #434343; /* Color del texto dentro de los mensajes */
}

.threads-viewer.light-mode .thread-details-preview p,
.threads-viewer.light-mode .loading,
.threads-viewer.light-mode .error {
  color: #434343; /* Otros elementos con texto, como el cargando/error */
}

/* Colores del sidebar en modo claro */
.threads-viewer.light-mode .threads-sidebar {
  background-color: #F2F2F2; /* Fondo de la barra lateral */
}

.threads-viewer.light-mode .threads-sidebar-item,
.threads-viewer.light-mode .threads-sidebar-icon {
  color: #434343; /* Color de los iconos del sidebar */
}

/* Colores de botones e inputs */
.threads-viewer.light-mode .new-message-input {
  background-color: #F2F2F2; /* Fondo del input */
  color: #434343; /* Texto del input */
  border: 1px solid #434343; /* Bordes */
}

.threads-viewer.light-mode .send-message-button {
  background-color: #584DF2; /* Fondo del botón enviar */
  color: white;
}

.threads-viewer.light-mode .thread-item:hover,
.threads-viewer.light-mode .agent-item:hover {
  transform: scale(1.02);
  background-color: #E0E0E0; /* Fondo al pasar el ratón */
}

/* Colores de los mensajes en modo claro */
.threads-viewer.light-mode .message-content.assistant {
  background-color: #D3D3D3; /* Fondo del mensaje del asistente */
}

.threads-viewer.light-mode .message-content.user {
  background-color: #FFFFFF; /* Fondo del mensaje del usuario */
}

.threads-viewer.light-mode .message-timestamp {
  color: #434343; /* Cambiar color de la fecha del mensaje */
}

/* Ajustes de iconos y textos */
.threads-viewer.light-mode .auto-response-icon {
  filter: brightness(0); /* Asegura que los iconos también se vean oscuros */
}

.threads-viewer.light-mode .mode-toggle {
  color: #434343; /* Color del texto del modo */
}

/* Estilo del search-input para modo claro */
.threads-viewer.light-mode .search-input {
  border: 1px solid #434343; /* Color del borde en modo claro */
  background-color: #F2F2F2; /* Fondo del input en modo claro */
  color: #434343; /* Color del texto en modo claro */
}

.threads-viewer.light-mode .search-input::placeholder {
  color: #757575; /* Placeholder en modo claro */
}

/* Estilos para el input de nuevo mensaje en modo claro */
.threads-viewer.light-mode .new-message-input {
  background-color: #F2F2F2; /* Fondo del input */
  color: #434343; /* Texto del input en modo claro */
  border: 1px solid #434343; /* Bordes oscuros */
}

.threads-viewer.light-mode .new-message-input::placeholder {
  color: #757575; /* Color del placeholder en modo claro */
}

/* Tooltip modificado para mostrar ON u OFF según el estado */
.tooltip-wrapper:hover::after {
  content: attr(data-tooltip);
  position: absolute;
  top: 50%; /* Centrar verticalmente */
  right: 125%; /* Posicionar a la izquierda del elemento */
  transform: translateY(-50%);
  background-color: #333;
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  white-space: nowrap;
  font-size: 12px;
  z-index: 1;
  opacity: 1;
  transition: opacity 0.2s;
}

.tooltip-wrapper:hover::before {
  content: '';
  position: absolute;
  top: 50%; /* Centrar verticalmente */
  right: 115%; /* Posicionar justo al lado del tooltip */
  transform: translateY(-50%);
  border-width: 5px;
  border-style: solid;
  border-color: transparent #333 transparent transparent; /* Triángulo apuntando a la derecha */
}

/* Slider desactivado (modo claro y oscuro) */
.slider-threads {
  position: absolute;
  width: 2.3em;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #ccc; /* Fondo gris simple cuando está desactivado */
  transition: 0.4s;
  border-radius: 20px;
}

.slider-threads:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  background-color: rgba(255, 255, 255, 0.759);
  transition: 0.4s;
  border-radius: 50%;
}


input:checked + .slider-threads:before {
  transform: translateX(17px);
}

/* Estilos del slider cuando está activado (modo oscuro) */
.threads-viewer:not(.light-mode) input:checked + .slider-threads {
  background-color: rgba(170, 211, 161, 0.3);

}

.threads-viewer:not(.light-mode) input:checked + .slider-threads:before {
  background-color: #59C34B; /* Bolita blanca en modo oscuro cuando está activado */
  
}

/* Actualizar el contenido del tooltip dinámicamente */
input:checked ~ .tooltip-wrapper::after {
  content: "Autorespuesta IA: ON";
}

input:not(:checked) ~ .tooltip-wrapper::after {
  content: "Autorespuesta IA: OFF";
}

/* Estilo para el puntito rojo de mensajes no leídos */
.unread-dot {
  width: 8px;
  height: 8px;
  background-color: red;
  border-radius: 50%;
  margin-left: 5px;
}

.option .trash-icon {
  font-size: 1em;
  margin-right: 0em;
  color: #ccc;
}
.option:hover .trash-icon {
  color: #FF4D4D;
  transform: scale(1.2);
}

/* Responsive adjustments for screens smaller than 1024px */

@media (max-width: 1024px) {


  
  /* General container adjustments */
  .threads-viewer {
    flex-direction: column; /* Stack threads list and details vertically */
    padding: 0;
    width: 100%;
  }

  .threads-list-header {
    height: 3em;
  }

 
  /* Hide the sidebar on smaller screens */
  .threads-sidebar {
    display: none;
  }



.control-agente-ia-icon {
  width: 1.5em;
}



.thread-info-header {
  padding: 0.2em 1em;
}

  /* Adjust agent list to stack elements */
  .agents-list {
    width: 100%;
    padding: 10px;
  }

  /* Adjust the agent item styles to be more compact */
  .agent-item {
    padding: 10px;
  }

  /* Thread item adjustments for stacking elements vertically */
  .thread-item {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }


  .header-left {
    justify-content: center;
    margin-bottom: 0px;
    gap: 10px;
  }

  .notification-bell {
    position: static;
    margin-bottom: 10px;
  }

  .order-filter,
  .pending-switch-container {
    flex: 1;
    width: 100%;
    justify-content: center;
  }
}

@media(min-width: 1001px) and (max-width: 1024px) {

.threads-list {
  width: 100%;
  flex: none;
  max-height: 38vh; /* Limit height to 50% of viewport */
  overflow-y: auto;
}

.threads-list-header {
  height: 3em;
}
  /* Limit message history to avoid overflowing on small screens */
  .message-history {
    max-height: calc(50vh - 100px);
  }

/* Threads list items adjustments */
.threads-list-items {
  max-height: 50vh;
  overflow-y: scroll;
}

/* Hide the sidebar on smaller screens */
.threads-sidebar {
  display: none;
}

/* Thread details adjustments for full width */
.thread-details {
  width: 100%;
  flex: none;
  max-height: 55vh; /* Limit height to 50% of viewport */
}

}
/* Responsive Adjustments for Threads Viewer */

@media (max-width: 1024px) {
  /* General container adjustments */
  .threads-viewer {
    flex-direction: column;
    width: 100%;
  }



  /* Thread item adjustments to fit in one line on small screens */
  .thread-item {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    gap: 5px;
    font-size: 14px;
  }

  .thread-info {
    flex: 1;
    min-width: 0;

  }

  .thread-preview-text {
    font-size: 12px;
  }

  .thread-date-info {
    font-size: 12px;
    text-align: right;
  }

  /* Adjust icon sizes for better fit on small screens */
  .message-icon-one {
    width: 2.5em;
    height: 2.5em;
  }

  .avatar,
  .avatar-header {
    width: 2em;
    height: 2em;
    font-size: 0.8em;
  }



  /* Make send button more compact */
  .send-button-image {
    width: 25px;
  }
}

/* Additional Small Screen Adjustments */

@media (max-width: 768px) {
  .threads-viewer {
    width: 100%;
    margin: 0;
    padding: 0;
  }

  .tooltip-wrapper:hover::after{
    display: none;
  }

  .thread-details-header {
    font-size: 16px;
  }

  .thread-item {
    padding: 8px;
  }

  .avatar,
  .avatar-header {
    width: 1.8em;
    height: 1.8em;
  }

  .message-timestamp {
    font-size: 0.8em;
  }
}

@media (max-width: 1024px) {
  .new-message-container {
      flex-direction: row;
      align-items: center;
      justify-content: space-between; /* Asegura que todos los elementos se distribuyan en el espacio disponible */
      gap: 5px;
      padding: 1em;
      width:auto;
  }

  .message-icons {
      flex: 0 0 auto; /* No permite que los íconos se compriman */
      display: flex;
      gap: 5px;
      margin: 0;
  }

  .new-message-input {
      flex: 1 1 auto; /* Permite al input crecer y ocupar el espacio disponible */
      padding: 10px;
      border-radius: 50px;
      border: 1px solid #ffffff;
      background-color: #ffffff36;
      color: white;
      font-family: 'Poppins';
      font-size: 0.8em;
      min-width: 0;
      width: 100%;
      padding-left: 3.5em;
  }

  .thread-sender-name {
    font-size: 1em;
  }

  span.thread-phone-number{
    font-size: 0.9em;
  }

  .new-message-input::placeholder {
      color: white;

  }

  .send-message-button {
      flex: 0 0 auto; /* No permite que el botón se comprima */
      display: flex;
      align-items: center;
  }

  .preview-text-message{
    margin-top: 0px;
  }

  .send-button-image {
      width: 40px; /* Ajusta el tamaño del ícono del botón de env��o para que sea más pequeño en pantallas pequeñas */
  }
}

@media (max-width: 1024px) {
  .message-content {
    font-size: 0.9em; /* Ajusta este valor para obtener un tamaño pequeño y legible */
  }

  .message-text {
    font-size: 0.95em; /* Ajusta el tamaño del texto dentro del mensaje */
  }

  .message-timestamp {
    font-size: 0.9em; /* Ajusta el tamaño del timestamp para mantener proporción */
  }
  
}
/* Ajustes específicos para pantallas menores a 1024px */
@media (max-width: 1024px) {
  .message-icon {
    width: 1.7em; /* Ajusta el tamaño según sea necesario */
    height: 1.7em; /* Ajusta el tamaño según sea necesario */
  }
}

.conversation-limit-modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(0 0 0 / 87%);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.conversation-limit-modal {
  position: relative;
  background: linear-gradient(180deg, #953BF526 0%, #57238FB3 100%) 0% 0% no-repeat padding-box;
  padding-left: 70px;
  padding-right: 70px;
  padding-top: 6%;
  padding-bottom: 8%;
  border-radius: 70px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  width: 30em;
  height: 16em;
  text-align: center; /* Centra el contenido */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: solid 1px #9101FF;
}

.conversation-limit-close-button {
  position: absolute;
  top: 20px;
  right: 30px;
  background: transparent;
  border: none;
  font-size: 24px;
  color: white;
  cursor: pointer;
  font-weight: bold;
}

.conversation-limit-title {
  font-family: 'Outfit';
  text-align: center; /* Título centrado */
  font-weight: 700;
  font-size: 42px;
  color: white;
  margin-bottom: 20px;
}

.conversation-limit-text {
  color: white;
  font-size: 16px;
  margin-bottom: 1em;
}

.conversation-limit-text a {
  color: #b268f7;
  text-decoration: none;
  font-weight: bold;
}

.conversation-limit-text a:hover {
  text-decoration: underline;
}

.conversation-limit-button {
  background: #9101FF;
  border-radius: 30px;
  font: normal normal 500 1.2em Outfit;
  letter-spacing: 0px;
  color: #FFFFFF;
  width: 80%;
  padding: 15px;
  margin: 10px 0;
  border: none;
  cursor: pointer;
}

.conversation-limit-button:hover {
  background: #A428FF;
}


/* Opciones de menú en thread-item */
.options-thread {
  position: relative;
  cursor: pointer;
  padding: 0 8px;
}

.options-thread-mobile {
  position: relative;
  cursor: pointer;
  padding: 0;
  margin: 0;
  align-self: center;
  justify-self: center;
}
@media (min-width: 1000px) {
  .options-thread-mobile {
display: none;
  }
}


.options-icon {
  font-size: 1em;
  color: #987FE2;
}

.move-dropdown {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #502685;
  border-radius: 10px;
  box-shadow: 0px 4px 6px rgba(0,0,0,0.1);
  z-index: 1000;
  min-width: 150px;
  padding: 0.1em 0.1em;
}

.move-dropdown-item {
  padding: 0.8em 1.8em;
  cursor: pointer;
}

.move-dropdown-item:hover {
  background-color: #7a4ab5f0;
}

/* Indicador de menú abierto */
.thread-item .move-dropdown {
  display: block;
}

/* Contenedor del ícono de búsqueda */
.search-icon-wrapper {
  margin-left: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #ffffff;
  transition: color 0.3s ease;
}

.search-icon-wrapper:hover {
  color: #9f9c9c;
}

/* Ícono de búsqueda */
.search-icon {
  font-size: 1.2em;
}

/* Contenedor del input de búsqueda y botones de navegación */
.message-search-container {
  display: flex;
  align-items: center;
}

/* Input de búsqueda */
.message-search-input {
  margin-left: 10px;
  padding: 0.4em 0.6em;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 0.9em;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  width: 10em;
}

.message-search-input:focus {
  border-color: #626262;
  box-shadow: 0 0 5px rgba(60, 61, 61, 0.5);
  outline: none;
}

/* Resaltar texto encontrado */
.highlight {
  background-color: #af98f7;
  color: rgb(255, 255, 255);
  padding: 0em 0.1em;
  border-radius: 2px;
}
/* Contenedor de navegación de búsqueda */
.search-navigation {
  display: flex;
  align-items: center;
  margin-left: 5px;
}

/* Botones de navegación */
.search-nav-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 1.2em;
  padding: 0 5px;
  color: #ffffff;
  transition: color 0.3s ease;
}

.search-nav-button:hover {
  color: #c2c2c2;
}

/* Contador de resultados */
.search-counter {
  margin: 0 5px;
  font-size: 0.9em;
  color: #ffffff;
  font-family: 'Outfit';
}

/* Estilos para la sección de búsqueda global */
.global-search-section {
  display: flex;
  align-items: center;
  margin-right: 0em; /* Ajusta el margen según necesites */
}
.global-search-close-button{
  background-color: transparent;
  background: none;
  box-shadow: none;
  color: #ffffff;
  font-size: large;
  margin-left: 0.5em;
  align-self: center;
}
.global-search-icon-wrapper {
  cursor: pointer;
  color: #987FE2; /* Ajusta al color que desees */
  font-size: 1.2em;
  margin-right: 0.8em;
}

.global-search-container {
  display: flex;
  align-items: center;
  margin-left: 0.5em;
}

.global-search-input {
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  font-size: 0.8em;
  outline: none;
  width: 14em; /* Ajusta el ancho según necesites */
  font-family: 'Poppins';
}

.global-search-button {
  margin-left: 0.5em;
  padding: 5px 10px;
  background-color: #411E75; /* Color de fondo actual */
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.8em;
  font-family: 'Poppins';
  
  /* Sombra interna lila */
  box-shadow: inset 0px 0px 10px 1px rgba(198, 123, 238, 0.401); /* Ajusta los valores según sea necesario */
}

/* Estilos para el hover del botón */
.global-search-button:hover {
  background-color: rgba(112, 32, 165, 0.682);
  /* Opcional: Puedes mantener o ajustar la sombra interna en hover */
  box-shadow: inset 0px 0px 10px 1px rgba(198, 123, 238, 0.3); /* Ajusta los valores según sea necesario */
}


.loading-global-search,
.no-search-results {
  padding: 10px;
  color: #ac9ed4;
  font-family: 'Outfit', sans-serif;
}

.loading-global-search {
  text-align: center;
}

.no-search-results {
  text-align: center;
  font-style: italic;
}


.team-modal-messages{
  position: absolute;
  top: 7em;
  right: 6em;
  background: #55378E 0% 0% no-repeat padding-box;
  box-shadow: inset 0px 0px 50px #8F54EACE;
  opacity: 1;
  padding: 0.8em 0.8em;
  border-radius: 4px;
  z-index: 999;
  border-radius: 20px;
  animation: fadeIn 0.3s ease-in-out;
  color: #ffffff;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 1.2em;

  text-align: left;
  max-height: 25em;
  overflow-y: auto;
}
/* Modal Backdrop */
.team-modal-backdrop {
  position: fixed;
  top: 0;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}



/* Título del Modal */
.team-modal-title {
  margin: 0 0 1.2em 0;
  font-size: 0.8em;
  text-align: center;
  color: #ac9ed4;
  font-weight: 500;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

/* Lista de Miembros */
.team-modal-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

/* Ítem de Miembro */
.team-modal-item {
  padding: 0.8em;
  border-bottom: 1px solid rgba(172, 158, 212, 0.1);
  cursor: pointer;
  display: flex;
  justify-content: start;
  align-items: center;
  transition: background-color 0.2s;
}

.team-modal-item:hover {
  background-color: rgba(172, 158, 212, 0.05);
}

/* Nombre del Miembro */
.team-member-name {
  font-size: 0.9em;
  font-weight: 400;
}

/* Cargo del Miembro */
.team-member-role {
  font-size: 0.75em;
  color: #6c6684;
  margin-left: 0.5em;
}

/* Botón de Cerrar */
.team-modal-close-button {
  margin-top: 1.2em;
  width: 60%;
  padding: 0.6em;
  background-color: #47248B;
  border: none;
  color: #ffffff;
  text-transform: uppercase;
  border-radius: 6px;
  cursor: pointer;
  font-size: 0.7em;
  transition: background-color 0.2s;
  letter-spacing: 0.5px;
  font-family: 'Poppins';
}


/* Estilos para la sección "Tomó el chat" */
.conversation-owner {
  display: flex;
  align-items: center;
  font-family: 'Poppins', sans-serif;
  color: #987FE2;
  font-weight: 400;
  margin-right: 0.5em;
}

.conversation-owner strong {
  margin-right: 0.5em;
  font-weight: 500;
}

.owner-name-threads {
  background-color: #502784; /* Fondo morado */
  color: #d4c9f1; /* Texto blanco */
  opacity: 1;
  padding: 0.3em 0.6em;
  border-radius: 12px;
  font-weight: 500;
  font-size: 0.9em;
  font-family: 'Poppins';
  font-style: italic;
}

/* Hover effect para owner-name */
.owner-name-threads:hover {
  cursor: default;
}
/* Responsividad para dispositivos móviles */
@media (max-width: 768px) {
  .conversation-owner {
    display: none;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 0.5em;
  }

  .conversation-owner strong {
    font-size: 0.7em;
  }

  .owner-name-threads {
    font-size: 0.9em;
    padding: 0.2em 0.5em;
  }
}

/* Estilos para conversation-owner en pantallas pequeñas */
.conversation-owner-mobile {
  margin-top: 0px; /* Espacio arriba para separarlo de los elementos superiores */
  padding: 0.8em 1em; /* Espaciado interno */
  background-color: rgba(30, 29, 29, 0.1); /* Fondo semi-transparente */
  border-radius: 0px; /* Bordes redondeados */
  color: white; /* Texto en blanco para contraste */
  font-family: 'Outfit', sans-serif; /* Asegúrate de que la fuente coincida con el resto */
}

.owner-name-threads {
  margin-left: 5px; /* Espacio entre 'Tomó el chat:' y el nombre */
}

/* Opcional: Ajustes adicionales para mejor apariencia */
@media (max-width: 1000px) {
  .conversation-owner-mobile {
    /* Puedes ajustar el margen, padding o colores según sea necesario */
    margin-top: 0px;
  }
  .new-message-input {
    padding-left: 4.2em;
  }
  
}

/* Botón de quitar propietario */
.remove-owner-button {
  background: none;
  border: none;
  color: #ff4444;
  font-size: 1.1em;
  cursor: pointer;
  padding: 0.2em 0.4em;
  opacity: 0.7;
  transition: opacity 0.2s;
}

.remove-owner-button:hover {
  opacity: 1;
}

/* Estilos responsive para el modal de equipo */
@media (max-width: 768px) {
  .team-modal {
    width: 90%;
    max-width: 320px;
    margin: 0 15px;
    padding: 1.2em;
  }

  .team-modal-title {
    font-size: 0.9em;
    margin-bottom: 1em;
  }

  .team-modal-item {
    padding: 0.7em;
  }

  .team-member-name {
    font-size: 0.85em;
  }

  .team-member-role {
    font-size: 0.7em;
  }

  .team-modal-close-button {
    margin-top: 1em;
    padding: 0.5em;
    font-size: 0.8em;
  }
}

@media (max-width: 480px) {
  .team-modal {
    width: 95%;
    padding: 1em;
    max-height: 70vh;
  }

  .team-modal-title {
    font-size: 0.85em;
  }

  .team-modal-item {
    padding: 0.6em;
  }

  .team-member-name {
    font-size: 0.8em;
  }

  .team-member-role {
    font-size: 0.65em;
  }

  .remove-owner-button {
    font-size: 1em;
    padding: 0.15em 0.3em;
  }
}

/* Ajustes para pantallas muy pequeñas */
@media (max-width: 360px) {
  .team-modal {
    width: 100%;
    margin: 0 10px;
    padding: 0.8em;
  }

  .team-modal-title {
    font-size: 0.8em;
  }

  .team-member-name {
    font-size: 0.75em;
  }

  .team-member-role {
    font-size: 0.6em;
  }

  .team-modal-close-button {
    font-size: 0.75em;
    padding: 0.4em;
  }
}

/* Contenedor de previsualización de archivos */
.file-preview-container {
  position: relative; /* Necesario para posicionar elementos hijos absolutamente */
  width: 120px; /* Ajusta el ancho según necesites */
  height: 120px; /* Ajusta la altura según necesites */
  margin-top: 10px;
}

/* Elemento individual de previsualización */
.file-preview-item {
  position: absolute;
  width: 100px; /* Tamaño de cada previsualización */
  height: 100px;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  transition: transform 0.3s, box-shadow 0.3s;
}

/* Hover efecto para resaltar la imagen */
.file-preview-item:hover {
  transform: translate(0, 0) scale(1.05);
  box-shadow: 0 4px 10px rgba(0,0,0,0.2);
}

/* Imagen dentro de la previsualización */
.preview-image,
.preview-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Documento dentro de la previsualización */
.preview-document {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0;
  height: 100%;
}

/* Botón para eliminar archivos en previsualización */
.remove-file-button {
  position: absolute;
  top: 2px;
  right: 2px;
  background: rgba(255, 255, 255, 0.7);
  border: none;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  cursor: pointer;
  font-weight: bold;
  color: #333;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
}

.remove-file-button:hover {
  background: rgba(255, 0, 0, 0.8);
  color: white;
}

/* Modal de vista previa */
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
}

.modal-content {
  position: relative;
  background-color: #1A1743;
  padding: 3em;
  border-radius: 15px;
  width: 44em;
  height: 15em;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden; /* Ocultar desbordes */
}

button.modal-close-button {
  position: absolute;
  top: 10px;
  right: 15px;
  background: transparent;
  border: none;
  font-size: 2em;
  cursor: pointer;
  color: #ffffff;
}

/* Contenedor de las imágenes dentro del modal */
.modal-images-container {
  display: flex;
  flex-wrap: wrap; /* Permitir que las imágenes salten a la siguiente línea */
  gap: 10px; /* Espaciado entre imágenes */
  width: 100%;
  height: 100%;
  overflow: auto; /* Scroll si el contenido excede */

    /* Personalización de la scrollbar */
    scrollbar-width: thin; /* Para Firefox */
    scrollbar-color: white transparent; /* Para Firefox */
}

/* Estilos para scrollbar en navegadores Webkit (Chrome, Safari) */
.modal-images-container::-webkit-scrollbar {
  width: 12px; /* Ancho de la scrollbar */
  background-color: transparent; /* Fondo de la scrollbar track */
}

.modal-images-container::-webkit-scrollbar-track {
  background: transparent; /* Fondo de la pista de scrollbar */
}

/* Hacer scrollbar siempre visible en navegadores Webkit */
.modal-images-container {
  /* Para navegadores que soportan CSS Scrollbar styling */
  overflow: scroll;
}

.modal-images-container::-webkit-scrollbar-thumb {
  background-color: white; /* Color del thumb del scrollbar */
  border-radius: 6px; /* Bordes redondeados */
  border: 3px solid transparent; /* Espacio alrededor del thumb */
  background-clip: content-box; /* Asegura que el color no sobresalga */
}

/* Opcional: Cambiar color del thumb al pasar el cursor */
.modal-images-container::-webkit-scrollbar-thumb:hover {
  background-color: #e0e0e0; /* Color al hacer hover */
}

/* Opcional: Forzar que el scrollbar sea visible en Webkit */
.modal-images-container::-webkit-scrollbar {
  display: block;
}
/* Elemento individual de la imagen en el modal */
.modal-image-item {
  width: 14em; /* Tamaño fijo de cada previsualización */
  height: 14em;
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

/* Imagen dentro del modal */
.modal-image,
.modal-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Documento dentro del modal */
.modal-document {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0;
  height: 100%;
}

.remove-file-button-modal {
  position: absolute;
  top: 5px;
  right: 5px;
  background: rgba(255, 255, 255, 0.7);
  border: none;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  cursor: pointer;
  font-weight: bold;
  color: #333;
  display: flex;
  justify-content: center;
  align-items: center;
}

.remove-file-button-modal:hover {
  background: rgba(255, 0, 0, 0.8);
  color: white;
}

/* Estilos para botones accesibles */
.remove-file-button,
.remove-file-button-modal,
.modal-close-button {
  outline: none;
}

.remove-file-button:focus,
.remove-file-button-modal:focus,
.modal-close-button:focus {
  box-shadow: 0 0 0 2px #987FE2;
}
@media (max-width: 1000px) {
  .team-modal-backdrop{
    left: 0;
  }
}
@media (max-width: 1000px) {
  .threads-list {
    width: 100%;
    height: auto; /* Deja que crezca en altura */
    overflow: hidden; /* El UL interno scrollea */

  }

  .order-dropdown-messages{
    background:linear-gradient(to bottom, #030320, #4F2A7E);
    box-shadow: inset 0 0 25px #4F2A7E;
  }
  .thread-item{
    background-color: #030320;
    font-size: 1em;
    height: 5em;
  }

  span.thread-sender-name {
    font-size: 1.05em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  
    /* Añade estas dos (o define un ancho fijo): */
    display: inline-block;    /* o block */
    max-width: 70px;        /* Ajusta el ancho a tu gusto */
}

.thread-preview-text {
  font-size: 0.8em;
}

  .order-filter{
    font-size: 0.9em !important;
  }

  .threads-list-header {
background-color:  #101231;
border-bottom: solid 1px #4F2A7E;
padding-right: 0em;
  }


/* Panel derecho */
/* Panel derecho */
/* Contenedor principal de la columna derecha */
.thread-details {
  display: flex;
  flex-direction: column;
  /* ¡Importante! Haz que llene todo el espacio vertical */
  height: calc(100vh - 60px);
  /* o si tu contenedor padre usa calc(...) 
     también puedes hacer: flex: 1; min-height: 0; */
}

/* El header arriba (conversación) */
.thread-info-header {
  flex: 0 0 auto; /* No crece */
}
/* Contenedor principal de los mensajes + header + input */
.thread-details-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  min-height: 0; /* Para que .message-history pueda scrollear */
  height: calc(100vh - 60px);
  max-height: 100vh;
}


  .global-search-icon-wrapper{
    font-size: 0.9em;
    margin-right: 0.3em;
  }

  .pending-switch-container span{
    margin-right: 0;
  }

  .pending-switch-container{
    align-items: baseline;
  }
  .threads-list-items{
    max-height: 100vh;
    height: 100vh;

  }
  .threads-viewer {
    /* Por si quieres forzar 100% de la altura total sin scroll 
       (o con scroll interno) */
       height: calc(100vh - 60px);
       overflow: hidden;
       /* Sin max-height extra. */
    border-top: solid 1px #4F2A7E;

  }
  .threads-list {
    display: flex;
    flex-direction: column;
    overflow: auto;
    flex: none;
    min-width: 0;
    background-color: #030320;
    padding: 0px;
    border-radius: 0px;
    min-height: 0;
    height: calc(100vh - 60px);
}
.threads-list-item{
  height: calc(100vh - 60px - 4em); 
  /* Resta el alto total del header mobile - la cabecera local (4em).
     Ajusta si tu header general difiere. */
  overflow-y: auto;
}

.message-content{
  max-width: 70% !important;
} 
/* El medio donde van los mensajes */
.message-history {
  flex: 1 1 auto; 
  overflow-y: auto;
  min-height: 0;
  max-height: 100vh;
  margin-top: 0em;
  /* Elimina o comenta max-height: 60vh; */
  /* max-height: none;  (puedes forzarlo en un media query para <1000px) */
}

.thread-info-header{
  padding: 0;
  padding-right: 0.5em;
  position: sticky;
  width: 100%;
}
.thread-info-sender{
  margin-left: 0;
  gap: 0;
}

.add-contact{
  gap: 0;
}


/* El input y botones abajo */
.new-message-container {
  flex: 0 0 auto; /* No crece, queda fijo abajo */
}

  .file-preview-container {
    /* Para que no sea position: relative con tamaño fijo */
    position: static; 
    width: auto;
    height: auto;
    margin-top: 0;
    display: flex;
    flex-wrap: nowrap; /* o wrap, depende cómo quieras */
    overflow-x: auto;  /* scroll horizontal si hay varias */
    gap: 8px; /* espacio entre previews */
  }

  .file-preview-item {
    position: relative;
    width: 10em;
    height: 3em;
    transform: none;
    z-index: 1;
    border-radius: 6px;
  }

  .preview-image, 
  .preview-video {
    width: 100%;
    height: 100%;
    object-fit: cover; 
  }

  .message-search-container {
    display: flex;
    flex-wrap: nowrap;    /* Importante: no permitir que se baje a otra línea */
    align-items: center;
    justify-content: flex-start; 
    gap: 6px;            /* Espacio horizontal mínimo */
    margin-top: 0;       /* Ajusta si deseas */
    width: 100%;         /* Ocupa todo el ancho si quieres */
  }

  .message-search-input {
    width: auto;         /* Deja que crezca/shrink según espacio */
    max-width: 110px;    /* Ajustar para evitar romper layout en pantallas muy pequeñas */
    font-size: 0.85em; 
  }

  .search-now-button {
    flex-shrink: 0;      /* Que no se reduzca */
    font-size: 0.8em; 
    padding: 0.4em 0.6em;
    margin-right: 4px;   /* Un pequeño espacio antes de la flechas */
  }

  .search-navigation {
    display: flex;
    align-items: center;
    gap: 3px;
    margin-left: 0; 
    flex-shrink: 0; 
  }

  .search-nav-button {
    font-size: 1em; 
    padding: 0 4px;
  }

  .search-counter {
    font-size: 0.9em;
    /* Sin margen extra */
  }

  .global-search-close-button {
    flex-shrink: 0; 
    font-size: 1.2em; 
    padding: 0 6px;
  }

}

/* Botón "Volver" en mobile */
.mobile-back-button {
  position: relative;
  /* O si lo quieres fijado arriba: 
     position: absolute;
     top: 10px;
     left: 10px; 
  */
  background: none;
  color: white;
  border: none;
  margin: 0.5em;
  margin-right: 0;
  cursor: pointer;
  z-index: 999; /* para que quede encima de todo */
}
.mobile-arrow-icon-back {
  font-size: 1.5em;
  color: #977FE2;
  transform: rotate(0deg);
}


.search-now-button {
  background-color: transparent;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-left: 5px;
  cursor: pointer;
  font-size: 0.9em;
  padding: 0.4em 0.6em;
  color: #fff;
  transition: background-color 0.2s;
}

.search-now-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}
.spinner-plantilla {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
  display: inline-block;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
/* Botones con degradado para "Enviar plantilla" y "Crear plantilla" */
.gradient-button {
  margin-top: 0.8em;
  padding: 0.8em 1.2em;
  background: linear-gradient(160deg, #953BF5, #57238F);
  border: none;
  border-radius: 8px;
  color: #ffffff;
  font-family: 'Outfit', sans-serif;
  font-size: 1em;
  cursor: pointer;
  transition: transform 0.3s, box-shadow 0.3s;
}

.gradient-button:hover {
  transform: scale(1.02);
  box-shadow: 0 4px 8px rgba(149, 59, 245, 0.3);
}

.gradient-button:disabled {
  cursor: not-allowed;
  opacity: 0.7;
  box-shadow: none;
}
.meta-warning {
  margin: 0;
  margin-top: 0.3em;
  margin-bottom: 1em;
  font-size: 0.9em; /* ajusta según necesites */
  color: #f0f0f0;
  opacity: 0.8;
}
/* =========================== */
/* Estilos para el <select> de plantillas */
/* =========================== */
/* Reutilizamos los mismos estilos que el .base-selector select */
.template-select {
  margin-top: 0px;
  margin-left: 0.5em;
  font-family: 'Poppins';
  width: 14em;
  background: linear-gradient(10deg, #953BF5, #57238F);
  color: white;
  border: none;
  border-radius: 70px;
  padding: 0.5em 1em;
  font-size: 0.9em;
  text-transform: uppercase;
  font-weight: 500;
  transition: all 0.3s ease;
  outline: none;      /* Evita borde azul en navegadores */
  cursor: pointer;
  -webkit-appearance: none; /* elimina estilo por defecto en iOS */
  vertical-align: middle;
  line-height: normal; /* fuerza altura de línea “normal” */
  align-self: center;
  align-items: center;
  justify-content: center;

  /* Insertamos la flechita como background (un pequeño caret en SVG). */
  background: linear-gradient(10deg, #953BF5, #57238F) url("data:image/svg+xml;charset=UTF-8,%3Csvg fill='white' height='20' viewBox='0 0 24 24' width='20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7 10l5 5 5-5z'%3E%3C/path%3E%3C/svg%3E") 
              no-repeat right 1em center / 1.3em auto; 
  /* 
     right 1em center => flecha a la derecha (1em de margen),
     / 1em auto       => tamaño de la imagen (ancho 1em, alto automático)
  */
}

/* Para navegadores que usan pseudo-elemento para la flecha:
   Podemos forzar la eliminación con:
*/
.template-select::-ms-expand {
  display: none; /* oculta la flechita por defecto en IE/Edge antiguos */
}


/* Botones con degradado para "Enviar plantilla" y "Crear plantilla" */
.gradient-button-icons {
  margin-top: 0em;
  padding: 0.8em 1.2em;
  background: linear-gradient(160deg, #953BF5, #57238F);
  border: none;
  border-radius: 8px;
  color: #ffffff;
  font-family: 'Outfit', sans-serif;
  font-size: 0.8em;
  cursor: pointer;
  transition: transform 0.3s, box-shadow 0.3s;
}

.gradient-button-icons:hover {
  transform: scale(1.02);
  box-shadow: 0 4px 8px rgba(149, 59, 245, 0.3);
}

.gradient-button-icons:disabled {
  cursor: not-allowed;
  opacity: 0.7;
  box-shadow: none;
}
.template-select-icons {
  margin-top: 0px;
  margin-left: 0em;
  font-family: 'Poppins';
  width: 10em;
  background: linear-gradient(10deg, #953BF5, #57238F);
  color: white;
  border: none;
  border-radius: 70px;
  padding: 0.8em 1em;
  font-size: 0.8em;
  text-transform: uppercase;
  font-weight: 500;
  transition: all 0.3s ease;
  outline: none;      /* Evita borde azul en navegadores */
  cursor: pointer;
  -webkit-appearance: none; /* elimina estilo por defecto en iOS */
  vertical-align: middle;
  line-height: normal; /* fuerza altura de línea “normal” */
  align-self: center;
  align-items: center;
  justify-content: center;

  /* Insertamos la flechita como background (un pequeño caret en SVG). */
  background: linear-gradient(10deg, #953BF5, #57238F) url("data:image/svg+xml;charset=UTF-8,%3Csvg fill='white' height='20' viewBox='0 0 24 24' width='20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7 10l5 5 5-5z'%3E%3C/path%3E%3C/svg%3E") 
              no-repeat right 1em center / 1.3em auto; 
  /* 
     right 1em center => flecha a la derecha (1em de margen),
     / 1em auto       => tamaño de la imagen (ancho 1em, alto automático)
  */
}
.tooltip-switch {
  position: relative;
  display: inline-block;
}

/* El tooltip se obtiene del atributo data-tooltip */
.tooltip-switch::after {
  content: attr(data-tooltip);
  position: absolute;
  bottom: 100%;
  left: 60%;
  transform: translateX(-50%);
  background-color: #333;
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  white-space: nowrap;
  font-size: 12px;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s;
}

.tooltip-switch:hover::after {
  opacity: 1;
}
/* Clase para tooltip que aparece a la izquierda */
.tooltip-left {
  position: relative;
  display: inline-block;
}

/* El tooltip se obtiene del atributo title */
.tooltip-left:hover::after {
  content: attr(title);
  position: absolute;
  top: 50%;
  right: 125%;  /* Coloca el tooltip a la izquierda del elemento */
  transform: translateY(-50%);
  background-color: #333;
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  white-space: nowrap;
  font-size: 12px;
  z-index: 1;
  opacity: 1;
  transition: opacity 0.2s;
}

/* Opcional: agregar una flechita (arrow) apuntando al ícono */
.tooltip-left:hover::before {
  content: "";
  position: absolute;
  top: 50%;
  right: 115%;  /* Ajusta para que la flecha quede justo entre el tooltip y el ícono */
  transform: translateY(-50%);
  border-width: 5px;
  border-style: solid;
  /* La flecha se dibuja con el color de fondo del tooltip (en este caso, #333) */
  border-color: transparent #333 transparent transparent;
}

/* Contenedor relativo donde metemos el input y el ícono */
.input-with-icon-container {
  display: flex;   /* Importante si quieres manejar fácilmente la iconografía */
  position: relative;
  flex: 1 1;
}

/* El input normal */
.new-message-input {
  width: 100%;
}

/* Ícono FaFile */
.file-icon-inside-input {
  position: absolute;
  left: 1em;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  color: #fff; /* O el color que quieras */
  width: 2.6em;
}

/* Dropdown de plantillas */
.template-dropdown {
  position: absolute;
  bottom: 110%; /* para que aparezca arriba del input */
  left: 0;
  background: #55378E 0% 0% no-repeat padding-box;
  box-shadow: inset 0px 0px 50px #8F54EACE;
  opacity: 1;
  padding: 0.5em 0em;
  border-radius: 4px;
  z-index: 999;
  border-radius: 20px;
}

.template-option {
  display: flex;          /* Para alinear el check y el texto en fila */
  align-items: center;    /* Centra verticalmente */
  padding: 0.3em 1.2em;
  cursor: pointer;
  color: #fff;
}

.template-option-checkbox {
  width: 16px;
  height: 16px;
  border: 1px solid #fff;
  border-radius: 3px; /* O 0 para que sea un cuadrado perfecto */
  margin-right: 0.5em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.template-option-checkbox span {
  color: #fff;
  font-size: 0.9em; /* Ajusta según cómo quieras verlo */
}

.template-option:hover {
  color: #fff;
    background: linear-gradient(
      180deg,
      rgba(64, 191, 213, 0.33) 0%,   /* #40BFD5 sin transparencia */
      rgba(64, 106, 213, 0.33) 45%,  /* #406AD5 sin transparencia */
      rgba(9, 27, 34, 0.33) 100%  /* #091B22 con 87% de opacidad */
    );
  }
  
.recontact-advice {
  background: rgba(74, 150, 222, 0.48);
  padding: 1em;
  padding-left: 1.5em; 
  margin: 0;
  color: #fff;
  font-size: 0.95em;
  font-weight: 500;
  text-align: start;
  line-height: 1.1em;
  /* Ajusta según tu preferencia */
}
@media (max-width: 1024px) {

  .file-icon-inside-input{
    width: 2em;
    left: 0.5em;
  }
}

.spinner-plantillas {
  width: 24px;
  height: 24px;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-top-color: #fff;       /* Color del borde "superior" (la parte que gira) */
  border-radius: 50%;
  animation: spin-plantillas 0.8s linear infinite;
}

/* El keyframes para la animación */
@keyframes spin-plantillas {
  0%   { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
/* Ejemplo de “checkbox style” para los miembros,
   muy parecido al .template-option */
   .member-option {
    display: flex;
    align-items: start;
    padding: 0.6em 1.2em;
    cursor: pointer;
    color: #fff;
    transition: background-color 0.2s;
    position: relative;
  }
  
  /* El "cuadrito" donde va el check ✓ */
  .member-option-checkbox {
    width: 16px;
    height: 16px;
    border: 1px solid #fff;
    border-radius: 3px;
    margin-right: 0.8em;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  /* El check ✓ */
  .member-option-checkbox span {
    color: #fff;
    font-size: 0.8em;
  }
  
  /* Hover style, similar al que usas en templates */
  .member-option:hover {
    background: linear-gradient(
      180deg,
      rgba(64, 191, 213, 0.33) 0%,
      rgba(64, 106, 213, 0.33) 45%,
      rgba(9, 27, 34, 0.33) 100%
    );
  }
  .order-filter-wrap{
    position: relative;
    display: flex;
    flex-direction: row;
    gap: 1em;
  }
  .filter-icon{
color: #e5defc;
cursor: pointer;
  }
  /* Estilos para el filtro de ordenamiento personalizado */
.order-filter-text {
  cursor: pointer;
  color: #987FE2;
  font-family: 'Poppins', sans-serif;
  }
  
  .order-filter-text span {
  user-select: none; /* Evita la selección de texto al hacer clic */
  }
  .remove-filter {
    margin-left: 0.5em;
    opacity: 0.5;
    /* Puedes agregar otros estilos, como color, etc. */
  }
  .filter-date{
    margin-left: 1em;
  }
  /* Estilos para los inputs de fecha del filtro */
#filter-start-date,
#filter-end-date {
  background-color: #f5f5f5;      /* Fondo claro */
  border: 1px solid #ccc;         /* Borde gris claro */
  border-radius: 4px;             /* Bordes redondeados */
  padding: 0.5em;                 /* Espaciado interno */
  font-size: 0.9em;               /* Tamaño de fuente */
  color: #333;                    /* Color del texto */
  width: 100%;                    /* Opcional, para que ocupen todo el ancho del contenedor */
  box-sizing: border-box;         /* Para incluir el padding en el ancho total */
  margin-top: 0em;              /* Separación superior */
  margin-left: 1em;
}
#filter-start-date{
margin-bottom: 1em;
}
/* Estilos al tener foco (clic o tabulando) */
#filter-start-date:focus,
#filter-end-date:focus {
  outline: none;                                 /* Quita el contorno predeterminado */
  border-color: #953BF5;                         /* Borde de color acorde al diseño */
  box-shadow: 0 0 5px rgba(149, 59, 245, 0.5);    /* Sombra suave */
}

/* Opcional: Si deseas controlar el ancho de los inputs de fecha en su contenedor */
.filter-by-date input[type="date"] {
  max-width: 12em;   /* Ajusta este valor según tus necesidades */
}
.filter-by-date label{
text-transform: none;
}
.filter-date{
  text-transform: uppercase;
}

/* =========== Para inputs DATE de filtro =========== */

/* Anula la apariencia nativa en varios navegadores */
#filter-start-date,
#filter-end-date {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  /* Color de fondo y del texto */
  background-color: #f5f5f5; /* tu fondo deseado */
  color: #333;              /* tu color de texto deseado */

  /* Tamaño, padding y borde */
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 0.8em 1em;       /* Aumenta padding top/bottom y laterales */
  font-size: 0.8em;           /* Ajusta el tamaño de fuente */
  line-height: 1.2;         /* Asegura algo de espacio vertical */
  min-height: 40px;         /* Evita que se vean muy finitos */

  /* Box-sizing y ancho */
  box-sizing: border-box;
  width: 100%;              /* O un ancho fijo si prefieres */
  outline: none;            /* Sin borde azul al hacer focus */
  font-family: 'Poppins', sans-serif; /* Por si quieres tu fuente */
}




/* En iOS, a veces se necesita forzar el estilo del texto dentro del “date picker” */
#filter-start-date::-webkit-date-and-time-value,
#filter-end-date::-webkit-date-and-time-value {
  color: #333;
  background-color: transparent;
  padding: 0.8em 1em; /* mismo padding que arriba */
  line-height: 1.2;
  font-size: 0.6em;
  font-family: 'Poppins', sans-serif; /* o la que uses */
}
.date-wrapper {
  position: relative; /* Para superponer el “fake-placeholder” */
  display: inline-block;
}

.date-wrapper {
  position: relative;
  /* ... */
}

.date-input {
  /* Aplica tus estilos para date-input */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #f5f5f5;
  color: #333;
  padding: 0.8em 1em;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  display: block;
  padding: 1.2em;
  /* Evita placeholder nativo */
  /* Por si algún navegador insiste: */
}

/* El “placeholder” falso */
.fake-placeholder {
  position: absolute;
  left: 1.5em;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  color: #3e3d3d;
  text-transform: lowercase;
}
@media (min-width: 1000px) {
  .fake-placeholder {
    display: none;
  }
}
.date-wrapper {
  position: relative;
  width: 100%;      /* Para que ocupe todo el ancho */
  display: block;   /* para que no sea inline-block */
  margin-left: 1em; /* si lo necesitas, etc. */
}
#filter-start-date,
#filter-end-date {
  padding: 1.2em;
}
.date-wrapper {
  width: 100%;         /* Ocupa todo el ancho disponible */
  display: block;      /* Para que no sea inline-block */
  position: relative;  /* Por si usas un fake placeholder */
}

.date-input {
  display: block;      /* Se comporta como bloque, no inline */
  width: 100%;         /* Ocupa todo el ancho del contenedor */
  padding: 0.8em 1em;  /* Ajusta el padding; aquí mayor “alto” */
  box-sizing: border-box; /* Para que el padding no desborde */
  font-size: 1em;      /* Ajusta el tamaño de fuente si lo deseas */
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
}
