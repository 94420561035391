/* subscriptionexpiredmodal.css */

/* Fondo oscuro cubriendo toda la pantalla */
.subscription-modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.87);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  /* Contenedor principal del modal */
  .subscription-modal {
    position: relative;
    background: linear-gradient(180deg, #953BF526 0%, #57238FB3 100%) 0% 0% no-repeat padding-box;
    border: 1px solid #9101FF;
    border-radius: 70px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  
    /* Medidas "de escritorio" (versión desktop) */
    width: 30em; /* 30em ~ 480px (dependiendo del root font-size) */
    height: 15em;
    padding-left: 70px;
    padding-right: 70px;
    padding-top: 6%;
    padding-bottom: 8%;
  
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  
  /* Título del modal */
  .subscription-modal h2 {
    font-family: 'Outfit', sans-serif;
    font-weight: 700;
    font-size: 42px;
    color: white;
    margin-bottom: 0.5em;
    text-align: left;
    width: 100%; /* Para que se note el 'text-align: left' */
  }
  
  /* Párrafos del modal */
  .subscription-modal p {
    color: white;
    font-size: 16px;
    margin-bottom: 1em;
    line-height: 1.4em;
  }
  
  /* Enlaces */
  .subscription-modal a {
    color: #b268f7;
    text-decoration: none;
    font-weight: bold;
  }
  
  .subscription-modal a:hover {
    text-decoration: underline;
  }
  
  /* Botón principal */
  .subscription-modal .auth-button {
    background-color: #b268f7;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    padding: 0.7em 1.2em;
    font-size: 16px;
    cursor: pointer;
    font-weight: bold;
    transition: background-color 0.2s ease-in-out;
  }
  
  .subscription-modal .auth-button:hover {
    background-color: #a550f5;
  }
  
  /* ========================= */
  /*       Responsiveness      */
  /* ========================= */
  
  /* Para pantallas hasta ~768px de ancho */
  @media (max-width: 768px) {
    .subscription-modal {
      width: 80%;
      height: auto;
      padding: 2em 2em;
      border-radius: 30px;
    }
  
    .subscription-modal h2 {
      font-size: 2rem; /* Ajustamos el tamaño del título */
      text-align: center; /* Opcional: centrar en mobile */
      margin-bottom: 1em;
    }
  
    .subscription-modal p {
      font-size: 0.95rem;
      margin-bottom: 1em;
    }
  
    .subscription-modal .auth-button {
      font-size: 0.95rem;
      padding: 0.6em 1em;
    }
  }
  
  /* Para pantallas muy pequeñas (~480px o menos) */
  @media (max-width: 480px) {
    .subscription-modal {
      width: 90%;
      padding: 1.5em;
      border-radius: 20px;
    }
  
    .subscription-modal h2 {
      font-size: 1.6rem;
    }
  
    .subscription-modal p {
      font-size: 0.9rem;
    }
  
    .subscription-modal .auth-button {
      font-size: 0.9rem;
      padding: 0.5em 0.8em;
    }
  }
  