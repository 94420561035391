.assistants-page {
  top: 10px;
  left: 25%;
  display: flex;
  justify-content: center; /* Centra todo el contenido horizontalmente */
  align-items: flex-start; /* Alinea todo al inicio verticalmente */
  padding: 10px;
  width: 100%;
  margin: 0 auto; /* Centra el contenedor dentro del área disponible */
  box-sizing: border-box;
}


/* Contenedor principal para centrar todo */
.center-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  text-align: center;
  width: 100%; /* Asegúrate de que ocupe todo el ancho */
}

@media (min-width: 1900px) {
/* Contenedor principal para centrar todo */
.center-content {
  width: 80%; /* Asegúrate de que ocupe todo el ancho */
}
}



.subiendo{
  font-size: 1.5em;
}

.mis-agentes-texto{
  font-family: 'Outfit';
  font-weight: 500;
  font-size: 1.6em; /* Tamaño de fuente reducido */
}

@media (max-width: 767px) {
  /* Contenedor principal para centrar todo */
  .mis-agentes-texto {
    font-size: 1.3em; /* Asegúrate de que ocupe todo el ancho */
  }
  }

/* Cambia de Grid a Flexbox */
.section-container {
  display: grid; /* Utiliza CSS Grid */
  grid-template-columns: repeat(auto-fit, minmax(250px, 0fr)); /* Columnas responsivas */
  row-gap: 2em; /* Espacio entre las tarjetas */
  column-gap: 4em; /* Espacio entre las tarjetas */
  margin-bottom: 40px;
  margin-top: 10px;
  width: 100%;
  box-sizing: border-box;
}





/* Estilos para el botón de crear agente con fondo transparente */
.create-agent-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 60px;
  margin-bottom: 40px;
}

.create-agent-icon {
  width: 100px; /* Tamaño del ícono */
  margin-bottom: 0; /* Espaciado entre el ícono y el texto */
}

.create-agent-button {
  background: rgba(255, 255, 255, 0);
  margin: 0;
  text-transform: none;
  font-family: 'Poppins', sans-serif;
  color: white;
  box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.6); /* Borde fino blanco */
  border-radius: 30px;
  padding: 10px 70px;
  font-size: x-large;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  border: none;
  
}


/* Modal de creación */

.crear-asistente{
  font-size: 2.2em;
  padding-top: 0;
  font-weight: 500;
  font-family: 'Outfit';
  text-align: left;
  margin: 0;
  margin-bottom: 0.5em;
}

.subir-archivos{
  font-size: 1.2em;
  padding-top: 0;
  font-weight: 300;
  font-family: 'Poppins';
  text-align: left;
  margin: 0;
  margin-bottom: 1em;
}

.base-archivos{
  font-size: 1.2em;
  padding-top: 0;
  font-weight: 300;
  font-family: 'Poppins';
  text-align: left;
  margin: 0;
  margin-bottom: 0.5em;
  margin-top: 1.6em;
}

.nombre-asistente{
  font-size: 1.2em;
  padding-top: 0;
  font-weight: 400;
  font-family: 'Poppins';
  text-align: left;
  margin: 0;
  margin-bottom: 0.8em;
  align-self: flex-start;
}
.asistente{
  font-size: 1.2em;
    font-weight: 400;
    font-family: 'Poppins';
    text-align: left;
    margin: 0;
    margin-top: 1em;
    margin-bottom: 0.8em;
    align-self: flex-start;
}

/* Estilo para los campos de entrada */
.crear-asistente-input {
width: 38%;
padding: 15px 30px;
margin-bottom: 2em; /* Espacio entre los inputs */
margin-left: 0;
margin-top: 0em;
border-radius: 60px;
border: solid 1px #FFFFFF;
color: white;
font-size: 1em;
font-family: 'Poppins';
font-weight: 400;
/* UI Properties */
background: #ffffff2f;
align-self: flex-start;
}


input.crear-asistente-input {
color: white;
font-size: 1em;
font-family: 'Poppins';
font-weight: 400;
}
/* Placeholder tenue */
input.crear-asistente-input::placeholder {
font-family: 'Poppins';
font-weight: 400;
font-size: 1.1em;
color: #ffffff;
opacity: 0.6;
}


.crear-base-input {
width: 90%;
padding: 15px 30px;
margin-bottom: 2em; /* Espacio entre los inputs */
margin-left: 0;
margin-top: 0em;
border-radius: 60px;
border: solid 1px #FFFFFF;
color: white;
font-size: 1em;
font-family: 'Poppins';
font-weight: 400;
/* UI Properties */
background: #ffffff2f;
align-self: flex-start;
}


input.crear-base-input {
color: white;
font-size: 1em;
font-family: 'Poppins';
font-weight: 400;
}
/* Placeholder tenue */
input.crear-base-input::placeholder {
font-family: 'Poppins';
font-weight: 400;
font-size: 1.1em;
color: #ffffff;
opacity: 0.6;
}


.instrucciones-textarea{
background-color: #ac9ed433;
width: 94% !important;
padding: 1.6em;
padding-bottom: 80px;
font-family: 'Poppins' !important;
font-size: 1em;
border: none;
border-radius: 22px;
color: white;
box-shadow: inset 0 0 8px #000000; /* Sombra interna uniforme */
resize: none;
outline: none;
}
.create-button{
margin-top: 1.5em;
background-color: #9100FF;
border: none;
padding: 0.9em 2em;
color: #fff;
font-family: 'Poppins';
font-size: 0.9em;
text-transform: uppercase;
border-radius: 30px;
box-sizing: border-box; /* Ensures padding and border are included in element's total width/height */
cursor: pointer;

}

.modal-base-conocimiento{
position: relative;
width: 33%;
background: #000321; /* Colores con transparencia */
padding: 2.3em 3em;
border-radius: 60px;
box-shadow: inset 0 0 16px rgba(193, 143, 230, 0.67); /* Sombra interna uniforme */
height: fit-content;
max-height: 65vh;
margin-right: 1%;
border: solid 3px #9101FF;
overflow-y: auto; /* Oculta cualquier desbordamiento del contenido */
outline: none;
}
/* Estilos para el textarea */
textarea.instrucciones-textarea {
text-align: left; /* Asegura que el texto esté alineado a la izquierda */
}

/* Estilos para el placeholder del textarea */
textarea.instrucciones-textarea::placeholder {
font-family: 'Poppins';
font-weight: 400;
font-size: 0.9em;
color: #ffffff;
opacity: 0.6;
white-space: pre-wrap; /* Preserva los saltos de línea */
text-align: left; /* Alinea el texto del placeholder a la izquierda */
}


.modal-creator-content{
  width: 34%;
  background: #000321; /* Colores con transparencia */
  padding: 2.3em 2.3em;
  border-radius: 45px;
  box-shadow: inset 0 0 16px rgba(193, 143, 230, 0.67); /* Sombra interna uniforme */
  height: 70vh;
  max-height: 68vh;
  margin-right: 1%;
  border: solid 3px #9101FF;
  overflow-y: auto; /* Permite el scroll vertical */
  position: relative;
}

/* Fondo del modal */
.modal-background {
flex-direction: column;
top: 0;
left: 0;
width: 100vw;
height: 100vh;
background-color: rgba(0, 0, 0, 0.9); /* Fondo oscuro */
display: flex;
justify-content: center;
align-items: center;
z-index: 1000;
}

.create-card {
  display: flex;
  justify-content: center; 
  height: 10em;
  width: 15em;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  background: linear-gradient(180deg, rgba(32,15,76,1) 0%, rgba(62,26,111,1) 25%);
  border-radius: 40px;
  border: solid 2px #9101FF;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-align: center;
  position: relative; /* Para posicionar el ícono de fondo */
  color: white;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
}

.create-text {
  position: relative;
  z-index: 3; /* Coloca el texto por encima del ícono */
  font-size: 1.1em;
}

.plus-icon {
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: 6em;
  z-index: 1; /* Coloca el ícono detrás del texto */
}

.icon-agents {
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: 120px;
  z-index: 1; /* Coloca el ícono detrás del texto */
background: transparent 0% 0% no-repeat padding-box;
mix-blend-mode: screen;
opacity: 1;
}

.create-card::before {
  content: '';
  background-image: url('../assets/+.png');
  background-size: 80px 80px; /* Ajusta el tamaño según necesites */
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.2; /* Ajusta la opacidad para que sea sutil */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100px;
  height: 100px;
  z-index: -1; /* Coloca el ícono detrás del texto */
}

.create-card:hover {
  transform: translateY(-10px); /* Efecto al hacer hover */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.4);
}


/* Hover en el botón */

/* src/components/MisAsistentes.css */

/* Estilo para el mensaje de error */
.error-message {
background-color: #ffdddd;
border-left: 6px solid #f44336;
padding: 10px;
margin: 10px 0;
border-radius: 4px;
color: #a94442;
}



/* Estilos para las tarjetas de asistentes */
.assistant-card {
  display: flex;
  justify-content: center;
  height: 10em;
  width: 15em;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  background: linear-gradient(180deg, rgba(16,35,82,1) 0%, rgb(49, 123, 192) 100%);
  border-radius: 40px;
  border: solid 2px #4A96DE;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-align: center;
  position: relative; /* Para posicionar el ícono de fondo */
  color: white;
  font-family: 'Poppins', sans-serif;
  font-size: 1em;
  font-weight: 500;
}
/* Efecto hover para las tarjetas */
.assistant-card:hover {
  transform: translateY(-10px); /* Levanta la tarjeta al hacer hover */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.4); /* Sombra más intensa */
}

/* Estilo para la imagen del asistente */
.assistant-image {
  width: 48%;
  object-fit: cover; /* Asegura que la imagen se ajuste bien al espacio */
  margin-bottom: 10px; /* Espacio entre la imagen y el nombre */

background: transparent 0% 0% no-repeat padding-box;
mix-blend-mode: screen;
opacity: 1;
}

/* Estilos para el modal */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.855); /* Fondo semi-transparente */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Asegura que el modal esté por encima de otros elementos */
}




.upload-button {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: start;
  width: 24%;
  background-color: #6C0E62 !important; /* Color de fondo púrpura */
  color: white;
  font-family: 'Poppins', sans-serif;
  font-size: medium;
  font-weight: 600;
  padding: 10px 80px 10px 80px; /* Ajustar según sea necesario */
  border-radius: 30px; /* Bordes redondeados */
  border: none;
  cursor: pointer;
  box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.503); /* Borde blanco */
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.upload-button:hover {
  background-color: #8A207D; /* Un poco más claro en hover */
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.8); /* Borde blanco más brillante en hover */
}

.upload-icon {
  margin-left: 15px; /* Espaciado entre el texto y el ícono */
}
.archivos-lista {
  position: relative;
  padding: 0;
  list-style-type: none;
}

.archivos-asociados {
  position: relative;
  background: #ffffff00;
  padding: 10px 40px 10px 10px; /* Añadimos un padding derecho adicional para dejar espacio al ícono */
  border-radius: 18px;
  margin-bottom: 10px;
  color: white;
  font-family: 'Poppins', sans-serif;
  display: flex;
  align-items: center;
  overflow: hidden; /* Ocultar desbordamiento */
}

  /* Estilos para el texto dentro de archivos-asociados */
  .archivos-asociados .file-text {
    flex: 1; /* Permitir que el texto ocupe el espacio disponible */
    overflow: hidden; /* Ocultar desbordamiento */
    text-overflow: ellipsis; /* Añadir puntos suspensivos */
    white-space: nowrap; /* Evitar saltos de línea */
    margin-right: 10px; /* Espacio entre el texto y el ícono */
  }
.check-icon {
  position: absolute;
  right: 40px; /* Coloca el ícono a 10px del borde derecho */
  font-size: 18px;
}

/* Estilo para el botón "Editar" */
.edit-button {
  position: absolute;
  bottom: 12px;
  left: 12px;
  background-color: #659ad6;
  color: white;
  font-family: 'Poppins', sans-serif;
  text-transform: uppercase;
  font-size: smaller;
  font-weight: 400;
  padding: 9px 22px;
  border: solid 2px #FFF;
  border-radius: 30px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}


.buttons-container {
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  gap: 10px;
  
}

.default-text-button {

  margin-top: 1.5em;
background-color: #FFFFFF30;
border: solid 1px;
padding: 0.9em 2em;
color: #fff;
font-family: 'Poppins';
font-size: 0.9em;
text-transform: uppercase;
border-radius: 30px;
box-sizing: border-box; /* Ensures padding and border are included in element's total width/height */
cursor: pointer;
}

.tutorial-video-container {
  width: 100%;
  max-width: 60em; /* Ancho máximo reducido a 960px */
  margin: 20px 0; /* Elimina el margen horizontal automático */
  padding: 15px; /* Reducir el padding para menos espacio */
  background: #000321; /* Fondo oscuro para resaltar el video */
  border-radius: 15px; /* Bordes ligeramente más pequeños */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  align-self: flex-start; /* Asegura que el contenedor se alinee al inicio en un contenedor flex */
}



.tutorial-video-container video {
  border-radius: 10px;
}

.create-card.disabled {
  pointer-events: none;
  opacity: 0.5;
}
/* Contenedor responsivo para Vimeo */
.vimeo-responsive {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 25px;
  height: 0;
}

.vimeo-responsive iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.error-openai {
  color: #f44336; /* Rojo para errores */
  font-size: 0.9em;
}

.info-openai {
  color: #ffffff; /* Blanco para mensajes generales, subiendo, etc. */
  font-size: 0.9em;
}



/* ... Tus estilos existentes ... */

/* Media Query para Dispositivos Móviles */
@media (max-width: 767px) {
  /* Ajustes Generales para la Página de Asistentes */
  .assistants-page {
    padding: 5px; /* Reducir el padding general */
    padding-left: 2em;
    left: 0%; /* Centrar la página */
  }

  .center-content {
    width: 100%; /* Asegurarse de que ocupe todo el ancho */
  }

  /* Ajustes para el Texto */
  .mis-agentes-texto {
    font-size: 1.2em; /* Reducir el tamaño de la tipografía */
  }

  /* Ajustes para las Tarjetas */
  .assistant-card,
  .create-card,
  .modal-base-conocimiento,
  .modal-creator-content {
    padding: 10px 15px; /* Reducir el padding interno */
    border-radius: 20px; /* Reducir el radio de los bordes */
    font-size: 0.9em; /* Reducir el tamaño de la tipografía */
  }

  /* Específicamente para .assistant-card */
  .assistant-card {
    height: 7em; /* Permitir que la altura se ajuste al contenido */
    padding-top: 10px;
    padding-bottom: 10px;
    width: 12em;
    font-size: 0.9em; /* Tipografía más pequeña */
  }

  /* Específicamente para .create-card */
  .create-card {
    height: 7em; /* Ajustar altura automáticamente */
    width: 12em;
    padding: 10px 15px; /* Reducir el padding */
    font-size: 0.9em; /* Tipografía más pequeña */
  }


  /* Reducir el tamaño del botón "Editar" */
  .edit-button {
    padding: 0.6em 0.7em; /* Reducir el padding */
    font-size: 0.8em; /* Reducir el tamaño de la tipografía */
    border-radius: 20px; /* Reducir el radio de los bordes */
    width: 7em;
  }

  /* Reducir el tamaño del botón "Eliminar" */
  .delete-agent-button {
    padding: 12px 30px; /* Reducir el padding */
    font-size: 0.8em; /* Reducir el tamaño de la tipografía */
    border-radius: 20px; /* Reducir el radio de los bordes */
  }

  /* Reducir el tamaño del botón "Crear agente" */
  .create-agent-button {
    padding: 8px 40px; /* Reducir el padding */
    font-size: 0.8em; /* Reducir el tamaño de la tipografía */
  }

  /* Reducir el tamaño del botón "Crear base de datos" */
  .create-agent-container .create-agent-button {
    padding: 8px 40px; /* Reducir el padding */
    font-size: 0.8em; /* Reducir el tamaño de la tipografía */
  }

  /* Reducir el tamaño de los botones de eliminación y edición */
  .buttons-container .default-text-button,
  .buttons-container .upload-button,
  .buttons-container .notification-button {
    padding: 5px 15px; /* Reducir el padding */
    font-size: 0.8em; /* Reducir el tamaño de la tipografía */
    border-radius: 20px; /* Reducir el radio de los bordes */
  }

  /* Ajustar el tamaño de los íconos en los botones */
  .plus-icon{
    width: 65px; /* Reducir el tamaño de los íconos */
    height: 85px;
    margin-left: 10px; /* Reducir el margen izquierdo */
  }

  /* Ajustar el tamaño de los textos dentro de los botones */
  .create-text {
    font-size: 0.9em; /* Reducir el tamaño de la tipografía */
  }

  .assistant-card p {
    font-size: 0.9em; /* Reducir el tamaño de la tipografía */
  }

  /* Ajustar el tamaño de los campos de entrada */
  .crear-asistente-input,
  .crear-base-input {
    width: 90%; /* Ocupa todo el ancho disponible */
    padding: 10px 15px; /* Reducir el padding */
    font-size: 0.9em; /* Reducir el tamaño de la tipografía */
    border-radius: 30px; /* Reducir el radio de los bordes */
  }

  /* Ajustar el tamaño del textarea */
  .instrucciones-textarea {
    width: 100% !important; /* Ocupa todo el ancho disponible */
    padding: 1em; /* Reducir el padding */
    font-size: 0.9em; /* Reducir el tamaño de la tipografía */
    border-radius: 15px; /* Reducir el radio de los bordes */
  }



  /* Ajustar el tamaño del modal */
  .modal-background {
    padding: 10px; /* Reducir el padding */
  }

  .modal-creator-content,
  .modal-base-conocimiento {
    width: 70%; /* Reducir el ancho del modal */
    padding: 2.2em; /* Reducir el padding */
    margin-right: 1.5em;
    border-radius: 30px; /* Reducir el radio de los bordes */
    overflow-x: hidden;
    max-height: 55vh;
  }

  /* Ajustar el tamaño del botón de cierre en el modal */
  button.close-button {
    top:5px;
    right: 15px;
    font-size: 25px;
  }
  
  /* Ajustar el tamaño de las imágenes en los botones */
  .assistant-image {
    width: 40px; /* Reducir el tamaño */
    height: 40px;
  }

  /* Ajustar el tamaño de los textos en el modal */
  .crear-asistente,
  .subir-archivos,
  .base-archivos {
    font-size: 1.2em; /* Reducir el tamaño de la tipografía */
  }

  .nombre-asistente,
  .asistente {
    font-size: 0.9em; /* Reducir el tamaño de la tipografía */
  }

  /* Ajustar el tamaño de los placeholders */
  input.crear-asistente-input::placeholder,
  input.crear-base-input::placeholder,
  textarea.instrucciones-textarea::placeholder {
    font-size: 0.8em; /* Reducir el tamaño de la tipografía */
  }

  /* Ajustar el tamaño del botón de crear base de conocimiento */
  .create-button {
    padding: 8px 16px; /* Reducir el padding */
    font-size: 0.8em; /* Reducir el tamaño de la tipografía */
  }

  /* Ajustar el tamaño de las listas */
  .archivos-asociados {
    padding: 5px 20px; /* Reducir el padding */
    font-size: 0.9em; /* Reducir el tamaño de la tipografía */
  }

  .archivos-asociados .check-icon {
    width: 16px; /* Reducir el tamaño del ícono */
    height: 16px;
    right: 20px; /* Ajustar la posición */
  }
}
@media (max-width: 767px) {
  /* ... otros estilos existentes ... */

  /* Ajustes para el contenedor del tutorial */
  .tutorial-video-container {
    max-width: 100%; /* Ocupa todo el ancho disponible */
    padding: 0; /* Elimina el padding para que el contenedor se ajuste al video */
    background: transparent; /* Elimina el fondo oscuro */
    box-shadow: none; /* Elimina la sombra para un diseño más limpio */
    border-radius: 0; /* Elimina el radio de borde */
    margin: 10px 0; /* Ajusta el margen para espaciar adecuadamente */
  }

  /* Ajustar el contenedor responsivo de Vimeo */
  .vimeo-responsive {
    padding-bottom: 56.25%; /* Mantiene la relación de aspecto 16:9 */
    padding-top: 0; /* Elimina el padding superior */
    height: 0; /* Mantiene la altura para la relación de aspecto */
  }

  /* Ajustar el iframe para que ocupe todo el espacio */
  .vimeo-responsive iframe {
    height: 100%; /* Asegura que el iframe ocupe todo el contenedor */
  }

  /* Opcional: Ajustar el tamaño del iframe si es necesario */
  /* Puedes experimentar con diferentes alturas para el iframe */
  /* Por ejemplo, para dispositivos muy pequeños */
  @media (max-width: 480px) {
    .vimeo-responsive iframe {
      height: 200px; /* Ajusta según sea necesario */
    }
  }

  /* ... otros estilos existentes ... */
}

/* ... Otros estilos existentes ... */
