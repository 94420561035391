.solicitud-enviada {
    height: 100vh; /* Ocupa toda la altura de la ventana */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; /* Centrar verticalmente */
    text-align: center; /* Centrar horizontalmente */
    padding: 0;
  }
  
  .security-image {
    max-width: 600px;
  }
  
  .solicitud-enviada h1 {
    color: #ded7dd;
    font-family: "Poppins";
    font-size: 38px;
    margin-top: 0;
    margin-bottom: 2%;
    font-weight: 900;
  }
  
  .solicitud-enviada p {
    font-size: 22px;
    margin-bottom: 20px;
  }
  
  .go-home-button {
    width: 200px;
    height: 50px;
    border: none;
    background-color: transparent;
    background-image: linear-gradient(135deg, #335858, #1e9da4);
    color: white;
    font-size: 18px;
    font-weight: 600;
    font-family: "Poppins";
    border-radius: 8px;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  