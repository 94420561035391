.coming-soon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Asegura que ocupe toda la altura de la ventana */
  background-size: cover;
  background-position: center;
}

.coming-soon-text {
  font-size: 3rem;
  color: #ffffff;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  animation: wave 3s ease-in-out infinite;
}

/* Definición de la animación de onda */
@keyframes wave {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px); 
  }
}

/* Estilos específicos para tabletas */
@media (max-width: 1024px) {
  .coming-soon-text {
    font-size: 2.5rem; /* Reduce el tamaño de la fuente en tablets */
  }
}

/* Estilos específicos para móviles */
@media (max-width: 768px) {
  .coming-soon-text {
    font-size: 2rem; /* Reduce aún más el tamaño de la fuente en móviles */
  }

  .coming-soon-container {
    padding: 10px; /* Reduce el padding en móviles para mejorar el espacio */
  }
}