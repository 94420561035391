/* ChatBubbleFrontend.css */

/* Fuentes Personalizadas */
@font-face {
    font-family: 'Outfit';
    src: url('../assets/fonts/Outfit-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Outfit';
    src: url('../assets/fonts/Outfit-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins';
    src: url('../assets/fonts/Poppins-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins';
    src: url('../assets/fonts/Poppins-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

/* Estilos Generales para la Ventana de Chat */
.chat-window-frontend {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #010321;
    border-radius: 30px;
    padding: 0px;
    width: 26em; /* Ancho máximo para pantallas grandes */
    height: 38em;
    border: none;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    overflow: hidden;
    position: fixed; /* Posición fija */
    bottom: 3em; /* Posición desde abajo */
    right: 92px; /* Posición desde la derecha para dejar espacio al WhatsApp */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Sombra para darle profundidad */
    z-index: 1000; /* Asegúrate de que esté por encima de otros elementos */
}

/* Media Query para Dispositivos Móviles */
@media screen and (max-width: 760px) {

    .whatsapp-bubble-large {
        width: 50px;
        height: 50px;
        bottom: 80px; /* Ajusta según sea necesario */
        right: 20px;   /* Ajusta según sea necesario */
    }

    .whatsapp-icon-large {
        width: 0.6em;
        height: auto;
    }
    
}

/* Imagen dentro del Botón de Envío */
.send-button-img-bubble-frontend {
    width: 3.5em;
    background: none;
    margin: 0;
}

/* Estilos para el Encabezado del Chat */
.chat-header-bubble {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: linear-gradient(170deg, #6268D4, #7400DC);
    padding: 0.8em 2em;
    border-radius: 30px 30px 0px 0px;
}

.close-button-bubble { 
    position: absolute;
    top: 2%;
    right: 30px;
    background: transparent;
    border: none;
    font-size: 2.2em;
    font-weight: 500;
    font-family: 'Outfit';
    color: white;
    cursor: pointer;
    padding: 0px 0px;
}

.h3-bubble-frontend {
    font-family: 'Outfit';
    font-size: 1.6em;
    font-weight: 500;
    text-align: left;
    margin-bottom: 0.2em;
    margin-top: 0.5em;
}

/* Contenedor del Input de Mensaje */
.chat-input-container-bubble-frontend {
    display: flex;
    align-items: center;
    width: 90%;
    max-width: 90%;
    margin: 0 auto;
    padding-top: 1em;
    padding-bottom: 1.5em;
}

/* Input de Mensaje */
.chat-input-bubble-frontend {
    flex: 1 1 0%;
    min-width: 0;
    padding: 0.6em 1em;
    border-radius: 60px;
    border: 1px solid #ffffff;
    background-color: #ffffff53;
    margin-right: 0.5em;
    font-family: 'Poppins', sans-serif;
    color: #ffffff;
    font-size: 1em;
    box-sizing: border-box;
}

.chat-input-bubble-frontend::placeholder {
    color: #fff;
    opacity: 1;
    font-size: 1em;
    font-family: 'Poppins', sans-serif;
}

input.chat-input-bubble-frontend {
    color: #fff ; 
    opacity: 100%;
    font-size: 1em;
    font-family: 'Poppins';
}

/* Botón de Envío */
.send-button-bubble-frontend {
    flex: 0 0 auto;
    padding: 0;
    border: none;
    background: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Imagen dentro del Botón de Envío */
.send-button-img-bubble-frontend {
    width: 3.5em;
    background: none;
    margin: 0;
}

/* Contenedor de Mensajes */
.chat-messages-bubble-frontend {
    display: flex;
    flex-direction: column; /* Apilar mensajes verticalmente */
    flex-grow: 1; /* Ocupa el espacio disponible */ 
    overflow-y: auto; /* Scroll vertical si es necesario */
    padding: 2.5em 1.5em 0em 1.5em; /* Padding */
}

/* Mensajes */
.message {
    border-radius: 20px;
    padding: 1.3em 2em;
    width: fit-content;
    margin-bottom: 2em; /* Espacio entre mensajes */
    color: #FFF;
}

.message.user {
    align-self: flex-end; /* Alinear a la derecha */
    background: linear-gradient(
      to bottom,
      rgba(149, 59, 245, 0.179),
      rgba(87, 35, 143, 0.85)
    );
    text-align: right;
    color: #FFF;
}

.message.assistant {
    align-self: flex-start; /* Alinear a la izquierda */
    background: linear-gradient(
      to bottom,
      rgba(64, 191, 213, 0.39),
      rgba(64, 106, 213, 0.39),
      rgba(103, 64, 213, 0.39)
    );
    text-align: left;
    color: #FFF;
}

.message-loading {
    font-family: 'Poppins', sans-serif;
    align-self: flex-start; /* Alinear a la izquierda */
    background: linear-gradient(
      to bottom,
      rgba(64, 191, 213, 0.39),
      rgba(64, 106, 213, 0.39),
      rgba(103, 64, 213, 0.39)
    );
    text-align: left;
    color: #FFF;
}

/* Animación de latido para el botón de chat */
.chat-bubble-button-frontend {
    position: relative; /* Necesario para las ondas */
    background-color: #ffffff00; /* Transparente */
    border: none;
    border-radius: 100%; /* Botón circular */
    width: 70px; /* Tamaño del botón */
    height: 70px; /* Tamaño del botón */
    /* Animación de latido */
    animation: heartbeat 1s infinite alternate ease-in-out;
    cursor: pointer; /* Mejor UX */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.chat-bubble-container {
    width: fit-content;
    height: fit-content;
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 10000;
}
/* Imagen dentro del Botón de Chat */
.chat-bubble-image-frontend {
    width: 3.8em;  /* Tamaño de la imagen */
    border-radius: 0;
    z-index: 7;
}

@keyframes heartbeat {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(1.1);
    }
}

/* Animación de ondas */
.wave {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 90%;
    height: 90%;
    border-radius: 50%;
    transform: translate(-50%, -50%) scale(1);
    opacity: 0;
    animation: waves 2.5s linear infinite;
    z-index: -1; /* Detrás de la imagen */
}

.wave1 {
    background-color: #a069ed99; /* Color inicial */
    animation-delay: 0s;
}

.wave2 {
    background-color: #b5acea52; /* Segundo color */
    animation-delay: 1s;
}

.wave3 {
    background-color: #9a67dd59; /* Tercer color */
    animation-delay: 2s;
}

@keyframes waves {
    0% {
        transform: translate(-50%, -50%) scale(1);
        opacity: 0.45;
    }
    40% {
        opacity: 0.45;
    }
    100% {
        transform: translate(-50%, -50%) scale(1.35);
        opacity: 0;
    }
}

/* Estilos para la marca de agua */
.watermark {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8em; /* Tamaño de fuente pequeño */
  color: rgba(255, 255, 255, 0.66); /* Color gris con transparencia */
  margin-top: 0; /* Espacio encima */
  padding-bottom: 1em; /* Espacio debajo */
  font-family: 'Poppins', sans-serif; /* Fuente legible */
}

/* Estilo para el texto "Powered by" */
.powered-by {
  margin-right: 5px; /* Espacio entre el texto y el logo */
}

/* Estilos para el enlace */
.watermark-link {
  display: inline-flex;
  align-items: center;
  text-decoration: none; /* Sin subrayado */
  color: rgba(255, 255, 255, 0.66); /* Mismo color que el texto */
}

.watermark-link:hover {
  color: rgba(165, 169, 213, 0.99); /* Color más oscuro al pasar el cursor */
}

.watermark-logo {
  width: 14px; /* Tamaño del logo */
  height: auto;
  margin-right: 5px; /* Espacio entre el logo y el texto */
}

/* Estilos para la burbuja de WhatsApp grande */
.whatsapp-bubble-large {
    position: fixed;
    bottom: 1.8em;
    right: 7.5em; /* Ajusta la posición al lado del botón de WhatsApp */
    width: 56px;
    height: 56px;
    border-radius: 50%;
    background-color: #47C958;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1001;
    transition: transform 0.3s ease;
    
}

.whatsapp-icon-large {
    width: 2.6em; /* Tamaño más grande */
}

.whatsapp-bubble-large:hover {
    transform: scale(1.1); /* Efecto al pasar el cursor */
}
/* Animación Fade-In-Right */
@keyframes fadeInRight {
    from {
        opacity: 0;
        transform: translateX(20px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

/* Clase para aplicar la animación */
.fade-in-right {
    animation: fadeInRight 0.5s ease-out forwards;
}

/* Estilos para el botón del icono de chat */
.chat-icon-button-frontend {
    position: fixed;
    bottom: 1.8em;
    right: 12.5em; /* Ajusta la posición al lado del botón de WhatsApp */
    width: 56px;
    height: 56px;
    border-radius: 50%;
    background-color: #693DD7;
    border: none; 
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1001;
    transition: transform 0.3s ease;
}

.chat-icon-button:hover {
    transform: scale(1.1);
}

.chat-icon {
    font-size: 2em;
    color: #ffffff;
}

/* Animación Fade-In-Right */
@keyframes fadeInRight {
    from {
        opacity: 0;
        transform: translateX(20px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

.fade-in-right {
    animation: fadeInRight 0.5s ease-out forwards;
}

/* Animación Fade-Out-Left */
@keyframes fadeOutRight {
    from {
        opacity: 1;
        transform: translateX(0);
    }
    to {
        opacity: 0;
        transform: translateX(20px);
    }
}

.fade-out-right {
    animation: fadeOutRight 0.5s ease-out forwards;
}


/* Animación Fade-In para la ventana de chat */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.fade-in {
    animation: fadeIn 0.5s ease-out forwards;
}
/* Ajustes para Mobile */
@media screen and (max-width: 760px) {

    .chat-window-frontend {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border-radius: 30px;
        padding: 0px;
        width: 90%;
        max-height: 80vh;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
        border: none;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        overflow: hidden;
        position: fixed;
        bottom: 2em;
        right: 1.3em;
        z-index: 999999; /* por si el tema mete un z-index superior */
      }

    .chat-input-container-bubble-frontend {
        width: 90%;
        max-width: 90%; 
    }

    .send-button-img-bubble-frontend {
        width: 3.2em;
    }

    .whatsapp-bubble-large {
        width: 4em;
        height: 4em;
        bottom: 7.3em;
        right: 2em;
    }

    .whatsapp-icon-large {
        width: 2.4em;
        height: auto;
    }

    .chat-icon-button-frontend {
        bottom: 12em;
        right: 2em;
        width: 4em;
        height: 4em;
    }
}
@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeOutDown {
    from {
        opacity: 1;
        transform: translateY(0);
    }
    to {
        opacity: 0;
        transform: translateY(20px);
    }
}

/* Clases para las animaciones verticales */
.fade-in-up {
    animation: fadeInUp 0.5s ease-out forwards;
}

.fade-out-down {
    animation: fadeOutDown 0.5s ease-out forwards;
}
@media screen and (max-width: 760px) {
    .fade-in-right {
        animation: fadeInUp 0.5s ease-out forwards;
    }

    .fade-out-right {
        animation: fadeOutDown 0.5s ease-out forwards;
    }
}
