/* src/components/WideSidebarMobile.css */
.wide-sidebar-mobile {
    position: fixed;
    top: 50px; /* Debajo del header mobile (ajusta si el header tiene otro alto) */
    left: 0;
    width: 100%;
    background-color: #0F1131;
    color: white;
    z-index: 1050;
    height: 72vh;         /* Forzamos 80% de la altura */
    max-height: 72vh;
    overflow-y: auto;
    transition: transform 0.3s ease;
    /* Oculto por defecto, se desliza hacia arriba */
    transform: translateY(-100%);
  }
  
  .wide-sidebar-mobile.visible {
    transform: translateY(0);
  }
  
  .wide-sidebar-mobile-content {
    padding: 1em;
    max-height: 25vh;
  }
  .wide-sidebar-mobile-content h2{
    font-family: 'Outfit';
    font-weight: 500;
    font-size: 1.4em;
    margin-top: 0.5em;
    margin-bottom: 1em;
    margin-left: 0.5em;
  }
  
  .titulos-threads-mobile, .mobile-title {
    font-family: 'Outfit';
    font-weight: 500;
    font-size: 1.4em;
    margin-top: 1em;
    margin-bottom: 1em;
    text-align: center;
  }
  
  .search-container {
    margin: 0 1em 2em;
    text-align: center;
  }
  
  .search-input-mobile {
    width: calc(100% - 2em);
    padding: 10px 16px;
    border-radius: 20px;
    border: 1px solid white;
    font-size: 0.9em;
    background-color: transparent;
    color: #fefefecc;
    font-family: 'Poppins';
  }
  
  .search-input::placeholder {
    color: #fefefe6d;
  }
  
  .scrollable-list {
    list-style: none;
    padding: 0;
    margin: 0;
    max-height: 400px;
    overflow-y: scroll;
    scrollbar-width: thin;
    scrollbar-color: white transparent;
  }
  
  .scrollable-list::-webkit-scrollbar {
    width: 8px;
  }
  
  .scrollable-list::-webkit-scrollbar-track {
    background: transparent;
  }
  
  .scrollable-list::-webkit-scrollbar-thumb {
    background-color: white;
    border-radius: 4px;
    border: none;
  }
  
  .list-item-mobile {
    padding: 0.8em;
    margin-bottom: 0.5em;
    cursor: pointer;
    transition: background-color 0.3s ease, border-top 0.3s ease;
    text-transform: uppercase;
  }
  
  .list-item-mobile:hover, 
  .list-item-mobile:focus {
    background-color: rgba(172, 158, 212, 0.24);
    border-top: 6px solid #835DF1;
    outline: none;
  }
  
  .agent-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .agent-name {
    margin: 0;
  }
  
  .thread-count, .mobile-thread-count {
    background: linear-gradient(180deg, #271A55 0%, #411E74 25%);
    color: #fff;
    border-radius: 50%;
    padding: 0.8em;
    font-size: 0.9em;
    margin-left: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    min-width: 24px;
  }
/* WideSidebarMobile.css */
/* Contenedor del switch global en versión móvil, alineado al centro */
.global-autoresponse-switch-container-mobile {
  display: flex;
  justify-content: center;    /* <--- Para alinear horizontalmente */
  align-items: center;
  margin: auto;              /* margin auto para centrar extra si es necesario */
  margin-top: 0em;
  padding-bottom: 1.3em;
}

/* Etiqueta contenedora del switch */
.switch-ia-global-mobile {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
  top: 0.2em;
  left: 0.5em;
}

/* Quitar visibilidad al checkbox real */
.switch-ia-global-mobile input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* El fondo del switch cuando está activado */
.switch-ia-global-mobile input:checked + .slider-mobile {
  background: linear-gradient(170deg, #6240a6, #3e1e6e);
}

/* Mover el círculo (toggle) hacia la derecha cuando esté activado */
.switch-ia-global-mobile input:checked + .slider-mobile:before {
  transform: translateX(19px);
}

/* El “track” del switch */
.slider-mobile {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px; /* para un look más circular */
}

/* El “botón” del switch */
.slider-mobile:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  background-color: rgba(255, 255, 255, 0.759);
  transition: 0.4s;
  border-radius: 50%;
}

/* Etiqueta de “Respuesta IA Global” móvil, por si quieres editar el texto */
.global-autoresponse-label-mobile {
  font-size: 1em; /* Ajusta a tu gusto */
  font-family: 'Outfit';
}
