/* src/components/MobileSidebar.css */
.mobile-sidebar {
    position: fixed;
    top: 60px; /* Debajo del header mobile */
    left: 0;
    width: 60px; /* Igual que la Sidebar original */
    background-color: #343457;
    color: white;
    z-index: 1200;
    max-height: 0; /* Oculto por defecto */
    overflow: hidden;
    transition: max-height 0.3s ease;
    display: flex;
    flex-direction: column;
}
  
.mobile-sidebar-visible {
    max-height: 93vh; /* Se expande para mostrar todo el contenido */
}
  
.mobile-sidebar-hidden {
    max-height: 0;
}
  
.mobile-sidebar-close {
    align-self: flex-end;
    margin: 0.5em 0.5em 0 0;
    background: none;
    border: none;
    color: #fff;
    font-size: 1.5em;
    cursor: pointer;
}
  
.mobile-sidebar-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 1em;
    padding-bottom: 5em;
    flex: 1; /* Añadido para que ocupe todo el espacio disponible */
}
  
.mobile-sidebar-top,
.mobile-sidebar-bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
}
  
.mobile-sidebar-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1em 0;
    text-decoration: none;
    position: relative; /* Para el tooltip */
}
  
.mobile-sidebar-icon img {
    width: 32px;
    transition: transform 0.3s ease;
}
  
.mobile-sidebar-icon:hover img {
    transform: translateY(-6px); /* Animación al hacer hover */
}
  
/* Tooltip */
.mobile-sidebar-icon::after {
    content: attr(data-tooltip);
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%) translateY(10px);
    background-color: #444;
    color: #fff;
    padding: 5px 8px;
    border-radius: 5px;
    white-space: nowrap;
    opacity: 0;
    transition: opacity 0.3s, transform 0.3s;
    font-size: 12px;
    pointer-events: none;
}
  
.mobile-sidebar-icon:hover::after {
    opacity: 1;
    transform: translateX(-50%) translateY(0);
}
  
/* Botón de logout */
.mobile-logout-button {
    background: none;
    border: none;
    cursor: pointer;
    color: #fff;
    font-size: 20px;
    transition: transform 0.3s ease, opacity 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.85;
}
  
.mobile-logout-button:hover {
    transform: translateY(-6px);
    opacity: 1;
}
  
/* Tooltip para el botón de logout */
.mobile-logout-button::after {
    content: attr(data-tooltip);
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%) translateY(10px);
    background-color: #444;
    color: #fff;
    padding: 5px 8px;
    border-radius: 5px;
    white-space: nowrap;
    opacity: 0;
    transition: opacity 0.3s, transform 0.3s;
    font-size: 12px;
    pointer-events: none;
}
  
.mobile-logout-button:hover::after {
    opacity: 1;
    transform: translateX(-50%) translateY(0);
}
  
@media (min-width: 768px) and (max-width: 1000px) {
    .mobile-sidebar {
      width: 50px;
      height: 95vh;
    }
    
    .mobile-sidebar-icon {
      margin: 0.7em 0;
    }
    
    .mobile-sidebar-icon img {
      width: 28px;
    }
    
    .mobile-logout-button svg {
      font-size: 24px;
    }
}
  
@media (max-width: 767px) {
    .mobile-sidebar {
      width: 50px;
      height: 95vh;
    }
    
    .mobile-sidebar-icon {
      margin: 0.5em 0;
    }
    
    .mobile-sidebar-icon img {
        width: 28px;
      }
      
      .mobile-logout-button svg {
        font-size: 24px;
      }
}
