.App {
  position: relative;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
}

.App.wide-sidebar-visible {
  margin-left: 18em; /* Ajusta la posición completa de la App cuando la sidebar está visible */
}


.the-thing-global-text-style {
  color: white;
  font-family: 'Poppins', sans-serif;
}

.App.light-mode {
  background-color: #F2F2F2;
  color: #434343;
}

.App.dark-mode {
  background-image: url('./assets/fondo-the-thing.png');
  color: white;
}

.App.login-register {
  background-image: url('./assets/background-shapes-violet.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.App.login-register {
  display: flex;
  justify-content: center;
  align-items: center;
}


/* Ensure the logo is placed within the layout */
.app-logo {
  position: absolute; /* This allows it to stay in the corner */
  top: 20px; /* Adjust top position */
  left: 20px; /* Adjust left position */
  height: 80px; /* Define a suitable height */
  z-index: 1000; /* Keep it above other elements */
}

/* Estilos para content-container */
.content-container {
  position: relative;
  flex: 1;
  margin-top: 60px; /* Altura del header */
  padding: 20px;
  margin-left: 3.5em;
  transition: all 0.3s ease;
  overflow-x: hidden;
  max-height: 100vh;
}


.content-container.is-agent-page:not(.wide-sidebar-visible) {
  margin-left: 8em;
}


@media (max-width: 767px) {
  /* Cuando es una página de agente y la barra lateral ancha no está visible */
  .content-container.is-agent-page:not(.wide-sidebar-visible) {
    margin-left: 35px;
  }
  }




/* Cuando es una página de agente y la barra lateral ancha no está visible */
.content-container.is-profile-page:not(.wide-sidebar-visible) {
  margin-left: 15em;
}

/* Cuando la wide sidebar está visible */
.content-container.is-profile-page.wide-sidebar-visible {
  margin-left: var(--total-sidebar-width, calc(60px + 25em));
}

/* Cuando la wide sidebar está visible */
.content-container.wide-sidebar-visible {
  margin-left: var(--total-sidebar-width, calc(60px + 18em));
}

/* Media queries para ajustar solo el margen izquierdo */
@media (min-width: 1024px) and (max-width: 1366px) {
  .content-container {
    margin-left: 55px;
  }
  
  .content-container.wide-sidebar-visible {
    --total-sidebar-width: calc(55px + 15em);
  }
}

@media (max-width: 1000px) {
  .content-container {
    margin-left: 50px;
  }
  
  .content-container.wide-sidebar-visible {
    --total-sidebar-width: 0;
  }
}



@media (min-width: 1001px) and (max-width: 1023px) {
  .content-container {
    margin-left: 50px;
  }
  
  .content-container.wide-sidebar-visible {
    --total-sidebar-width: calc(50px + 15em);
  }
}





@media (max-width: 767px) {
  .content-container {
    margin-left: 35px;
  }
  
  .content-container.wide-sidebar-visible {
    --total-sidebar-width: calc(35px + 14em);
  }
}

/* Versión sin padding */
.content-container.no-padding {
  padding: 0;
  height: calc(100vh - 60px);
  align-items: normal;
}

.whatsapp-business {
  margin-top: 60px;
}



.archivos-asociados{
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff00 !important; /* Color de fondo púrpura */
  color: white;
  font-family: 'Poppins', sans-serif;
  font-size: medium;
  font-weight: 600;
  padding: 10px 80px 10px 80px; /* Ajustar según sea necesario */
  border-radius: 30px; /* Bordes redondeados */
  border: none;
  cursor: pointer;
  box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.503); /* Borde blanco */
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}


/* El contenedor del chat se hace más grande y ocupa más espacio */
.chat-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: rgba(255, 255, 255, 0.1); /* Fondo semi-transparente */
  border-radius: 10px;
  padding: 20px;
  width: 60vw; /* Aumentar el ancho */
  height: 80vh; /* Aumentar la altura */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(10px); /* Efecto de desenfoque para darle profundidad */
  border: solid 2px #20F4F2;
}


.message-content-bubble {
  max-width: 60%;
  padding: 3% 6%;
  color: white;
  font-size: smaller;
  border-radius: 5px;
}

.message-content-bubble {
  background: #C2FCFB;
  color: #000000;
  box-shadow: 0 3px 5px rgba(128, 128, 128, 0.2);
}
 .message-content-bubble {
  background-color: #AEB2D3;
  color: #000000;
  box-shadow: 0 3px 5px rgba(128, 128, 128, 0.2);
}







.chat-input-container-bubble {
  display: flex;
  align-items: center; /* Alinea verticalmente el input y el botón */
  width: 100%;
  gap: 0;
}

.instrucciones{
  margin-top: 20px;
}



input.chat-input-bubble {
  flex-grow: 1;
  padding: 0% 2%;
  border-radius: 20px;
  font-size: small;
  background: white; /* Fondo blanco */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Sombra de caja gris */
  color: black; /* Texto negro */
  height: 40px;
  border: none;
}




/* Contenedor de los botones de instrucciones */
.instructions-button-container {
  display: flex;
  justify-content: space-between; /* Espaciado entre los botones */
  flex-wrap: wrap; /* Permitir que los botones se alineen debajo cuando no haya espacio */
  gap: 10px; /* Espacio entre los botones */
}

.instructions-button {
  background: #E10BAB;
  flex: 1 1 45%; /* Los botones ocupan un 45% del ancho disponible */
  height: 40px;
  color: white;
  font-family: 'Poppins';
  text-transform: none;
  font-size: 15px;
  font-weight: 400;
  border: none;
  border-radius: 20px;
  padding: 0 15px;
  cursor: pointer;
  margin-top: 10px;
  transition: background-color 0.3s ease;
  margin-left: 10px;
}

/* Para pantallas medianas (tablets y pantallas más pequeñas) */
@media (max-width: 1024px) {
  .instructions-button {
    flex: 1 1 48%; /* Reducir el ancho de los botones para que entren mejor */
    font-size: 14px;
    padding: 0 12px;
  }
}

/* Para pantallas pequeñas (móviles) */
@media (max-width: 768px) {
  .instructions-button {
    flex: 1 1 100%; /* En pantallas pequeñas, los botones ocupan todo el ancho */
    font-size: 13px;
    padding: 0 10px;
  }
}


.back-button {
  background: #E10BAB;
  height: 40px;
  color: white;
  font-family: 'Poppins';
  text-transform: none;
  font-size: 15px;
  font-weight: 400;
  border: none;
  border-radius: 20px;
  padding: 0 60px;
  cursor: pointer;
  margin-top: 0px;
  transition: background-color 0.3s ease;

}


.chat-input::placeholder {
  color: white; /* Color más tenue para el placeholder */
  font-family: 'Poppins';

}






.assistant-chat-bubble {
  color: white;
  font-weight: 400; /* Títulos en negrita */
  font-size: medium;
}



/* Interruptor de encendido/apagado */
.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  border-radius: 50%;
  left: 4px;
  bottom: 3px;
  background-color: white;
  transition: .4s;
}


input:checked + .slider:before {
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/* Para alinear el texto al lado del interruptor */
.toggle-label {
  margin: 20px 10px 20px 5px; /* Margen superior, derecho, inferior, izquierdo */
  font-size: 16px;
  font-weight: 500;
  color: white;
}

.base-conocimiento-select{
  margin-top: 15px;
}


.assistant-page-container {
  display: flex;
  justify-content: space-between;
  width: 92%;
  margin-top: 3%;
}


/* Para pantallas medianas (tablets) */
@media (max-width: 1024px) {
  .chat-container {
    padding: 30px 50px; /* Reducir el padding en tablets */
  }
  .content-container.is-profile-page:not(.wide-sidebar-visible) {
    margin-left: 3em;
}
}

/* Para pantallas pequeñas (móviles) */
@media (max-width: 768px) {
  .chat-container {
    padding: 20px 30px; /* Reducir más el padding en móviles */
  }
  .content-container.is-profile-page:not(.wide-sidebar-visible) {
    margin-left: 3em;
}
}

/* Para pantallas muy pequeñas (móviles pequeños) */
@media (max-width: 480px) {
  .chat-container {
    padding: 15px 20px; /* Reducir el padding al mínimo en pantallas muy pequeñas */
  }
  .content-container.is-profile-page:not(.wide-sidebar-visible) {
    margin-left: 0em;
    padding-left: 3.5em;
}
}



/* Estilo para el select */
.base-conocimiento-select select {
  margin-top: 10px;
  font-family: 'Poppins';
  width: 96%;
  background: linear-gradient(170deg, rgba(226, 18, 195, 0.583), #6C0E62); /* Colores con transparencia */
  color: white; /* Texto en blanco */
  border: none;
  border-radius: 12px; /* Bordes redondeados */
  padding: 15px 10px 15px 10px;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.3s ease;
}

/* Estilo para las opciones */
.base-conocimiento-select option {
  background: linear-gradient(135deg, #335858, #1e9da4); /* Mismo color que los botones */
  color: white; /* Texto en blanco */
  font-family: 'Poppins';
}



.back-button-container {
  width: 90%; /* Ocupa todo el ancho disponible */
  display: flex;
  justify-content: flex-start; /* Alinea el botón a la izquierda */
  margin-bottom: 5px;
  margin-top: 3%;
}

/* Burbuja de chat */



.chat-bubble-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
}

.chat-bubble-button-bubble {
  background-color: #2A368B;
  color: white;
  border: none;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;
  padding: 0;
}


.chat-bubble-image {
  width: 140px;  /* Ajusta el tamaño de la imagen según tu preferencia */
  height: 140px;
  border-radius: 50%;
}

.chat-window {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: linear-gradient(170deg, rgba(32, 244, 241, 0.213), #29032d);
  border-radius: 30px;
  padding: 20px;
  width: 28vw; /* Aumentar el ancho */
  height: 70vh; /* Aumentar la altura */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(10px); /* Efecto de desenfoque para darle profundidad */
  border: solid 2px #20F4F2;
}



.chat-window-bubble {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #fff;
  border-radius: 30px;
  width: 28vw; /* Aumentar el ancho */
  height: 70vh; /* Aumentar la altura */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(10px); /* Efecto de desenfoque para darle profundidad */
  border: none;
}





.bubble-chat-header-bubble{
  background-color: #2A368B;
  border-radius: 30px 30px 0px 0px;
  color: white;
  font-size: medium;
  font-weight: 400;
  text-align: left;
  padding: 10px 10px 10px 30px;
  
}


.chat-messages-bubble {
  margin: 0;
  flex-grow: 1;
  overflow-y: auto;
  padding: 40px;
  padding-top: 50px;
  background: #fff;
}




.chat-input-form-bubble {
display: flex;
padding: 10px;

background: linear-gradient(135deg, #335858, #1e9da4); /* Mismo color que los botones */
}


.bubble-chat-input-form-bubble {
  display: flex;
  padding: 10px 0px 15px 20px;
  background: #fff;
  border-radius: 30px;
  gap: 20px;
  width: 92%;
  align-items: center;
  justify-content: center;
  }
  


.send-button {
background: #E10BAB; /* Colores con transparencia */
font-family: 'Poppins', sans-serif;
color: white;
border: none;
border-radius: 20px;
padding: 8px 15px;
margin-left: 10px;
cursor: pointer;
}
.send-button-bubble {
  background: #2A368B;
  color: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;
  padding: 0;
}

.send-button-bubble i {
  font-size: 24px;
  color: white;
  display: block;
}



.chat-bubble-toggle-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.chat-icon {
  color: #fff;
}

.chat-window-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 10px;
  padding: 20px;
  width: 400px;
  height: 600px;
}


.chat-header-title {
  color: #fff;
  font-size: 1.5rem;
}

.chat-close-button {
  background-color: transparent;
  color: #fff;
  border: none;
  font-size: 1.2rem;
  cursor: pointer;
}

.chat-messages-container {
  flex-grow: 1;
  overflow-y: auto;
  padding: 10px;
  background-color: #222;
  border-radius: 5px;
}

.chat-message {
  margin-bottom: 10px;
}

.chat-message.user {
  text-align: right;
}

.chat-message.assistant {
  text-align: left;
}


.chat-input-form {
  display: flex;
}

.chat-input-field {
  flex-grow: 1;
  padding: 10px;
  border-radius: 5px;
  border: none;
  margin-right: 10px;
}

.chat-submit-button {
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.chat-submit-button:disabled {
  background-color: #888;
}



/* Estilo para el select */
.base-select {
  margin-top: 10px;
  font-family: 'Poppins';
  width: 96%;
  background: linear-gradient(170deg, rgba(226, 18, 195, 0.583), #6C0E62);
  color: white;
  border: none;
  border-radius: 12px;
  padding: 15px 10px;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.3s ease;
}

/* Estilo para las opciones del select */
.base-select option {
  background: linear-gradient(135deg, #335858, #1e9da4);
  color: white;
  font-family: 'Poppins';
}

.whatsapp-business {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.whatsapp-business .instructions-button {
  padding: 10px;
  width: 64%;
}

.select-and-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 1em;
}

.select-and-button .base-select {
  margin-right: 10px;
  font-family: 'Poppins';
  width: 35%;
  background: linear-gradient(170deg, rgba(226, 18, 195, 0.583), #6C0E62);
  color: white;
  border: none;
  border-radius: 12px;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.3s ease;
}

.select-and-button .instructions-button {
  margin-top: 0;
  padding: 10px 20px; /* Ajusta el padding si es necesario */
  height: auto; /* Asegura que la altura se ajuste al contenido */
}


.delete-button {
  background: #E10BAB;
  height: 40px;
  color: white;
  font-family: 'Poppins';
  text-transform: none;
  font-size: 15px;
  font-weight: 400;
  border: none;
  border-radius: 20px;
  padding: 0 15px;
  cursor: pointer;
  margin-top: 0px;
  transition: background-color 0.3s ease;
  margin-left: 10px;
  margin-bottom: 30px;
}

.delete-button:hover {
  background-color:  #ff1a1a;
}

.delete-button-base {
  background: #E10BAB;
  height: 40px;
  color: white;
  font-family: 'Poppins';
  text-transform: none;
  font-size: 15px;
  font-weight: 400;
  border: none;
  border-radius: 20px;
  padding: 0 15px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-left: 10px;
  margin-bottom: 0;
}

.delete-button-base:hover {
  background-color:  #ff1a1a;
}

.delete-button-div {
  margin-top: 30px;
  margin-bottom: 0;
}


.delete-phone-button {
  background: none;
  border: none;
  color: white;
  font-size: 1.2rem;
  font-weight: 600;
  cursor: pointer;
  text-transform: lowercase;
}

.delete-phone-button:hover {
  color: darkred;
}

.header-global {
  z-index: 1000;
  width: 85%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 1em;
}



.mode-icon {
  width: 24px;
  height: 24px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 30px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.pin-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  font-size: 16px;
  text-align: center;
}

.footer-container p {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 1em;
}

.footer-link {
  color: white;
  text-decoration: none;
  font-family: 'Poppins', sans-serif;
  margin: 0 5px;
}

.footer-separator {
  color: white;
  margin: 0 5px;
}


.footer-container.wide-sidebar-visible {
}

.privacy-link {
  color: #E10AAB;
  text-decoration: none;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
}
/* App.css */
.privacy-policy-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.privacy-policy-container h1,
.privacy-policy-container h2,
.privacy-policy-container h3 {
  color: #333;
}

.privacy-policy-container p,
.privacy-policy-container ul {
  color: #555;
  line-height: 1.6;
}

.privacy-policy-container ul {
  list-style-type: disc;
  margin-left: 20px;
}
@media (max-width: 1000px) {
  .content-container.is-agent-page:not(.wide-sidebar-visible) {
    margin-left: 0em;
  }
}

@media (max-width: 1000px) {
  .content-container {
    margin-left: 0em;
  }
}

/* Ajustar el padding-left de .content-container cuando la MobileSidebar está visible */

/* Para pantallas grandes (min-width: 1367px) */
body.mobile-sidebar-visible .content-container {
  padding-left: 50px; /* Igual que el ancho de la Sidebar original */
}

/* Para pantallas medianas (768px a 1000px) */
@media (min-width: 768px) and (max-width: 1000px) {
  body.mobile-sidebar-visible .content-container {
    padding-left: 50px; /* Ajusta según tu CSS modificado */
  }
}

/* Para pantallas pequeñas (menos de 768px) */
@media (max-width: 767px) {
  body.mobile-sidebar-visible .content-container {
    padding-left: 50px; /* Ajusta según tu CSS modificado */
  }
}
