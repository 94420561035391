/* assistantchat.css */

/* Estilos específicos para AssistantChat */
.assistant-chat-page {
  padding-top: 1em;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center; /* Alinea todo el contenido al centro excepto el botón */
  justify-content: center;
  background-color: #010321; /* Añade esta línea para el color de fondo */
  height: fit-content;
  padding-bottom: 2em;
}

.assistant-page-container {
  display: flex;
  justify-content: space-between;
  width: 85%;
  margin-top: 0;
  gap: 2.5em;
}

.buttons-training-container{
align-self: flex-start;
padding-top: 1.3em;
}
.editor-agente {
  cursor: pointer;
}
.name-editor{
margin: 2em 0em;
}

.new-name-input{
background-color: #2A1055;
color: #b4a7cc;
border: none;
padding: 1em;
border-radius: 15px;
margin-right: 0.5em;
}

.save-newname-button{
  background-color: #ffffff3d;
border: 1px solid #fff;
padding: 0.4em 1.5em;
color: #fff;
font-family: 'Poppins';
font-size: medium;
text-transform: uppercase;
border-radius: 30px;
box-sizing: border-box; /* Ensures padding and border are included in element's total width/height */
cursor: pointer;
}


.edit-instructions-button {
background-color: #ffffff3d;
border: 1px solid #fff;
padding: 0.9em 2em;
color: #fff;
font-family: 'Poppins';
font-size: medium;
text-transform: uppercase;
border-radius: 30px;
box-sizing: border-box; /* Ensures padding and border are included in element's total width/height */
cursor: pointer;
}

.edit-instructions-button:hover {
  transform: none; /* Explicitly prevent any transformation */
}
.delete-agent-button {
  margin-left: 0.6em;
  background-color: #ffffff00;
  border: 1px solid #fff;
  padding: 0.9em 2em;
  color: #fff;
  font-family: 'Poppins';
  font-size: medium;
  text-transform: uppercase;
  border-radius: 30px;
  transition: background-color 0.5s ease;
  box-sizing: border-box; /* Ensures padding and border are included in element's total width/height */
  cursor: pointer;
} 

.delete-agent-button:hover {
  background-color: #ff1a1a;
  transform: none; /* Explicitly prevent any transformation */
}

.save-instructions-button{
  background-color: #9100FF;
  border: none;
  padding: 0.9em 2em;
  color: #fff;
  font-family: 'Poppins';
  font-size: medium;
  text-transform: uppercase;
  border-radius: 30px;
  box-sizing: border-box; /* Ensures padding and border are included in element's total width/height */
}
.conect-whatsapp-button{
width: fit-content;
align-self: center;
margin-top: 3em;
background-color: #9100FF;
border: none;
padding: 0.9em 2em;
color: #fff;
font-family: 'Poppins';
font-size: medium;
text-transform: uppercase;
border-radius: 30px;
box-sizing: border-box; /* Ensures padding and border are included in element's total width/height */
}
.modal-implementacion{
  position: relative;
  width: 70vh;
  background: #000321; /* Colores con transparencia */
  padding: 2.3em 3em;
  border-radius: 70px;
  box-shadow: inset 0 0 16px rgba(193, 143, 230, 0.67); /* Sombra interna uniforme */
  height: fit-content;
  height: max-content;
  max-height: 80vh;
  margin-right: 1%;
  border: solid 3px #9101FF;
  overflow-y: auto; /* Oculta cualquier desbordamiento del contenido */
  outline: none;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.close-button-imp {
  position: absolute;
  top: 20px;
  right: 30px;
  background: transparent;
  border: none;
  font-family: 'Outfit';
  font-size: 30px;
  color: white;
  cursor: pointer;
  font-weight: 400;
}
.image-upload-container{
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.number-select {
  margin-right: 10px;
  font-family: 'Poppins';
  width: 35%;
  background: linear-gradient(165deg, rgba(63, 114, 215, 0.691), #75b1d4);
  color: white;
  border: none;
  border-radius: 12px;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.3s ease;
}

.close-button {
  position: absolute;
  color: #9100FF;
  top: 1em;
  right: 1em;
  background: none;
  border: none;
  border-width: 0;
  font-size: 1.6em;
  cursor: pointer;
  font-weight: 500;
  font-family: 'Outfit';
}


.implementacion-h2{
  font-family:'Outfit';
  font-size: 2.8em;
  text-align: center;
  margin-bottom: 0.2em;
  margin-top: 0.5em;
}

.destino{
  font-size: 1.3em;
  margin-right: 0.5em;
  text-align: center;
  padding: 0;
  margin: 0;
}

.button-container {
  display: flex;
  justify-content: center; /* Centra horizontalmente */
  margin-top: 1em; /* Añade margen superior para separación */
}

.copy-script{
  background-color: #9100FF;
  border: none;
  padding: 0.9em 2em;
  color: #fff;
  font-family: 'Poppins';
  font-size: medium;
  text-transform: uppercase;
  border-radius: 30px;
  box-sizing: border-box; /* Ensures padding and border are included in element's total width/height */
  align-self: center;
}
.code-script {
  background-color: #ac9ed433;
  max-height: 37vh; /* Ajusta esta altura según tus necesidades */
  padding: 0.6em;
  font-family: 'Poppins', sans-serif !important;
  font-size: 0.9em;
  border: none;
  border-radius: 22px;
  color: rgba(255, 255, 255, 0.537);
  box-shadow: inset 0 0 8px #000000; /* Sombra interna uniforme */
  overflow: auto; /* Permite el scroll si el contenido excede */
  white-space: pre-wrap; /* Permite el ajuste de líneas */
  word-wrap: break-word; /* Permite romper palabras largas */
  outline: none;
}





/* Estilos para el contenedor de pestañas */
.tabs-container {
display: flex;
justify-content: center;
margin-bottom: 1em;
margin-top: 1em;
}

/* Estilos para cada pestaña */
.tab {
background-color: #2D145C;
border: none;
border-radius: 16px;
color: #fff;
padding: 0.5em 1em;
margin: 0 0.5em;
cursor: pointer;
font-family: 'Poppins', sans-serif;
font-size: 1em;
display: flex;
align-items: center;
transition: background-color 0.3s ease;
gap: 1em;
}

/* Estilo para la pestaña activa */
.tab.active {
background-color: #3A1E70;
}

/* Estilos al pasar el cursor */
.tab:hover {
background-color: #9100FF;
}

/* Estilos para el icono dentro de la pestaña */
.tab-icon {
height: 2em; /* Ajusta el tamaño según necesites */
width: auto;
align-self: center;

}

/* Contenedor del contenido de las pestañas */
.tab-content {
margin-top: 1em;
}

/* Estilos para los botones de plataformas */
.platform-buttons {
display: flex;
justify-content: center;
gap: 0.8em;
}

.platform-button-whatsapp {
display: flex;               /* Convertir el botón en un contenedor flex */
align-items: center;         /* Centrar verticalmente los elementos */
gap: 0.5em;                  /* Espacio entre el icono y el texto */
background-color: #68379D;
border: none;
padding: 0.6em 1.3em;
color: #fff;
font-family: 'Poppins', sans-serif;
font-size: 1em;
border-radius: 13px;
cursor: pointer;
transition: background-color 0.3s ease;
margin-top: 1em;
}


.platform-button {
display: flex;               /* Convertir el botón en un contenedor flex */
align-items: center;         /* Centrar verticalmente los elementos */
gap: 0.5em;                  /* Espacio entre el icono y el texto */
background-color: #68379d64;
border: none;
padding: 0.6em 1.3em;
color: #fff;
font-family: 'Poppins', sans-serif;
font-size: 1em;
border-radius: 13px;
cursor: pointer;
transition: background-color 0.3s ease;
position: relative;
cursor: not-allowed;
margin-top: 1em;
}
.platform-button::after {
content: '';
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
backdrop-filter: blur(2px); /* Aplica el efecto de desenfoque */
border-radius: inherit; /* Mantiene el borde redondeado del botón */
pointer-events: none; /* Permite que los eventos del ratón pasen al botón si es necesario */
}


/* Para alinear el texto al lado del interruptor */
.label-number {
margin: 0px 15px 0px 5px; /* Margen superior, derecho, inferior, izquierdo */
font-size: 16px;
font-weight: 500;
color: white;
}
.editor-agente {
  font-size: 2.2em;
  font-family: 'Outfit';
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 0.4em;
  }
.activar-base{
  font-size: 1.2em;
  margin-right: 0.5em;
}
.vista-preliminar{
  font-size: 1.2em;
  margin-right: 0.5em;
  margin-bottom: 1em;
  text-align: left;
  align-self: self-start;
}
.base-selector {
margin-top: 1em;
display: flex;
flex-direction: column;
}
.base-selector select {
  margin-top: 10px;
  font-family: 'Poppins';
  width: 16em;
  background: #835DF1;
  color: white;
  border: none;
  border-radius: 70px;
  padding: 1em 1em;
  font-size: 1em;
  text-transform: uppercase;
  font-weight: 500;
  transition: all 0.3s ease;
}

.modal-text {
  font-size: smaller;
}

.assistant-editor-container {
  width: 90%;
  background: #000321; /* Colores con transparencia */
  padding: 2.3em 2.3em;
  border-radius: 45px;
  box-shadow: inset 0 0 16px rgba(193, 143, 230, 0.67); /* Sombra interna uniforme */
  height: 73vh;
  margin-right: 1%;
  border: solid 3px #9101FF;
  overflow-y: auto; /* Permite el scroll vertical */
}

.chat-container {
  width: 90%;
  background: #000321; /* Colores con transparencia */
  padding: 2.3em 2.3em;
  border-radius: 45px;
  box-shadow: inset 0 0 16px rgba(143, 200, 230, 0.67); /* Sombra interna uniforme */
  height: 73vh;
  margin-left: 1%;
  align-items: center;
  border: solid 3px #4A96DE; /* Mantener el borde */
}

/* Asumiendo que estas reglas ya existen, solo las ajustaremos */

.editor-agente,
.chat-header {
font-size: 2em; /* Uniformamos el tamaño de fuente */
font-family: 'Outfit';
font-weight: 500;
margin-top: 0;
margin-bottom: 20px; /* Uniformamos el margen inferior */
}

.chat-header {
/* Eliminamos display:flex y justify-content */
color: white;
font-size: 2em; /* Asegurado por la regla anterior */
margin-left: 0em;
font-weight: 500;
text-align: left;
align-self: flex-start;
font-family: 'Outfit';
margin-bottom: 20px; /* Asegurado por la regla anterior */

/* Opcional: eliminar estas líneas si no son necesarias */
/* display: flex;
justify-content: space-between; */
}

.instructions-textarea {
  background-color: #ac9ed433;
  width: 100%; /* Cambiado de 92% a 100% */
  padding: 1em; /* Reducido de 1.6em para evitar desbordamiento */
  padding-bottom: 60px; /* Ajustado según sea necesario */
  font-family: 'Poppins' !important;
  font-size: 1em;
  border: none;
  border-radius: 22px;
  color: white;
  box-shadow: inset 0 0 8px #000000; /* Sombra interna uniforme */
  resize: none;
  outline: none;
  box-sizing: border-box; /* Asegura que el padding esté incluido en el ancho */
  max-width: 100%; /* Evita que el elemento exceda el ancho de su contenedor */
}

/* AssistantChat.css */

/* Altura cuando la base de datos está activada */
.instructions-textarea--active-db {
height: 38vh;
}

/* Altura cuando la base de datos está desactivada */
.instructions-textarea--inactive-db {
height: 45vh;
}


.chat-messages {
  height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Default alignment */
  width: 100%; /* Mantén el ancho máximo deseado */
  flex-grow: 1;
  gap: 0.9em; /* Space between messages */
  overflow-y: auto;
  padding: 1.2em;
  border-radius: 22px;
  background-color: #ac9ed433;
  box-shadow: inset 0 0 8px #000000; /* Sombra interna uniforme */
  margin-bottom: 0em;
}

.chat-section-container {
  display: flex;
  flex-direction: column; /* Alinea los elementos hijos verticalmente */
  width: 50%;
  align-items: center;
}

.message {
  border-radius: 20px;
  padding: 1.3em 2em;
  max-width: 70%;
  align-self: flex-end;
  width: fit-content;
}

.message-content-training{
  color: white;
  text-align: left;
  padding: 0;
}

.message.user {
  align-self: flex-end; /* Push user messages to the right */
  text-align: right;
}

.message.assistant {
  align-self: flex-start; /* Keep assistant messages on the left */
  text-align: left;
}

.message.user {
  /* Aplicación del degradado para mensajes del user */
  background: linear-gradient(
    to bottom,
    rgba(149, 59, 245, 0.179),
    rgba(87, 35, 143, 0.85)
  );
}

.message.assistant {
  /* Aplicación del degradado para mensajes del assistant */
  background: linear-gradient(
    to bottom,
    rgba(64, 191, 213, 0.39),
    rgba(64, 106, 213, 0.39),
    rgba(103, 64, 213, 0.39)
  );
}

.chat-input-container {
  margin-top: 1.3em;
  display: flex;
  width: 100%;
  gap: 10px; /* Espacio entre el input y el botón */
  flex-grow: 1;
}

.chat-input {
  flex: 1 1;
  padding: 13px 20px;
  border-radius: 50px;
  border: 1px solid #ffffff;
  background-color: #ffffff36;
  color: white;
  font-family: 'Poppins';
  font-size: medium;
}

.chat-button {
  background: #4A96DE;
  align-items: center;
  justify-content: center;
  color: white;
  font-family: 'Poppins';
  text-transform: uppercase;
  font-size: 1.1em;
  font-weight: 400;
  border: none;
  border-radius: 80px; /* Borde redondeado */
  padding: 0.8em 1.2em;
  cursor: pointer;
  margin-bottom: 0;
  transition: background-color 0.3s ease;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.828); /* Fondo oscuro */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}




.back-button-container {
  width: 50%; /* Ocupa todo el ancho disponible */
  display: flex;
  justify-content: center; /* Alinea el botón a la izquierda */
  margin-bottom: 3%;
  margin-top: 2%;
  gap: 1em;
}

/* AssistantChat.css */

/* Estilos para la sección de personalización */
.customization-section {
/* Puedes agregar estilos específicos si lo deseas */
}

/* Contenedor de los color pickers */
.color-picker-container {
margin-bottom: 1em;
}

/* Estilos para el contenedor en línea */
.color-picker-inline {
display: flex;
align-items: center;
gap: 15px;
position: relative;
}

/* Estilo del círculo de color (swatch) */
.color-swatch {
width: 24px;
height: 24px;
border-radius: 50%;
border: 1px solid #ccc;
cursor: pointer;
align-items: center;
justify-content: center;
align-self: start;
margin-top: 0.6em;
}

/* Estilo del input de código de color */
.color-picker-inline input {
width: 100px;
padding: 15px 30px;
border-radius: 15px;
border: none;
color: white;
font-size: small;
font-family: 'Poppins';
align-self: start;
justify-content: center;
align-items: center;
/* UI Properties */
background: #2D145C 0% 0% no-repeat padding-box;
box-shadow: inset 0px 0px 50px #1C032F81;
mix-blend-mode: normal;
opacity: 1;
}

/* Estilo del popover del SketchPicker */
.color-picker-popover {
position: absolute;
z-index: 2;
top: 40px; /* Ajusta este valor según sea necesario */
}

/* Estilo de la cubierta que cierra el SketchPicker al hacer clic fuera */
.color-picker-cover {
position: fixed;
top: 0px;
right: 0px;
bottom: 0px;
left: 0px;
}

/* Estilos para la sección de imágenes predeterminadas */
.default-bubble-images {
margin-top: 3.5em;
}

.bubble-image-options {
display: flex;
flex-wrap: wrap;
gap: 10px;
margin-top: 1em;
}

.bubble-image-option {
width: 50px;
height: 50px;
cursor: pointer;
border: 2px solid transparent;
border-radius: 10px;
}

.bubble-image-option.selected {
border-color: #5d38a3; /* Color para indicar selección */
}

/* Estilo para el botón de generar código personalizado */
.generate-custom-script {
background-color: #9100FF;
border: none;
padding: 0.9em 2em;
color: #fff;
font-family: 'Poppins';
font-size: medium;
text-transform: uppercase;
border-radius: 30px;
cursor: pointer;
margin-top: 1em;
}

/* Spinner Styles */
.spinner-assistant {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid #fff;
  border-radius: 50px;
  width: 15px;
  height: 15px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


.form-personalizar {
margin-top: 2em;
font-family: 'Outfit';
font-weight: 700;
font-size: 1.1em;
margin-bottom: 0.4em;
}

.cargar-imagen-bubble{
margin-top: 1.5em;
margin-bottom: 0.5em;
font-family: 'Poppins';
font-weight: 400;
font-size: 1.1em;
}


.modal-pin {
position: relative;
width: 33%;
background: #000321;
padding: 2.3em 3em;
border-radius: 60px;
box-shadow: inset 0 0 16px rgba(193, 143, 230, 0.67);
border: solid 3px #9101FF;
outline: none;
display: flex;
flex-direction: column;
align-items: center;
}

.modal-title {
font-family: 'Outfit';
font-size: 2.8em;
text-align: center;
margin-bottom: 0.2em;
margin-top: 0.5em;
}

.modal-subtitle {
font-size: 1.6em;
text-align: center;
margin-bottom: 1em;
margin-top: 0;
}

.pin-input {
background-color: #ac9ed433;
width: 80%;
padding: 1em;
font-family: 'Poppins';
font-size: 1em;
border: none;
border-radius: 22px;
color: white;
box-shadow: inset 0 0 8px #000000;
text-align: center;
margin-bottom: 1em;
}

.modal-buttons {
display: flex;
gap: 1em;
margin-top: 1em;
}

.submit-pin-button {
background-color: #9100FF;
border: none;
padding: 0.9em 2em;
color: #fff;
font-family: 'Poppins';
font-size: medium;
text-transform: uppercase;
border-radius: 30px;
cursor: pointer;
}

.cancel-pin-button {
background-color: #ffffff3d;
border: 1px solid #fff;
padding: 0.9em 2em;
color: #fff;
font-family: 'Poppins';
font-size: medium;
text-transform: uppercase;
border-radius: 30px;
cursor: pointer;
}

/* Agrega aquí cualquier otro estilo específico de AssistantChat que desees mover */
/* Media Queries para pantallas menores a 1024px */
@media (max-width: 1280px) {
/* Ajustes generales para hacer los contenedores más flexibles */
.assistant-page-container {
  flex-direction: column;
  align-items: center;
  gap: 40px;
}


.assistant-editor-container,
.chat-container {
  width: 100%; /* Asegura que los contenedores ocupen todo el ancho */
  height: auto; /* Permite ajuste según el contenido */
  border: none;
  box-shadow: none;
}

.chat-container {
  box-sizing: border-box;
}

.chat-section-container {
  width: 100%;
}

.buttons-training-container {
  align-self: center;
  padding-top: 1em;
  display: flex;
}

/* Instrucciones y base de conocimientos */
.instructions-textarea {
  height: auto; /* Ajusta la altura automáticamente */
  margin-bottom: 1em;
}


.chat-section-container {
  flex-direction: column;
  align-items: center;
}

.chat-messages {
  height: calc(100vh - 30em); /* Asegúrate de que los mensajes tengan espacio suficiente */
  max-height: 50vh;
  overflow-y: auto;
  width: 100%;
  box-sizing: border-box;
  max-width: 100%;
}

.chat-input-container {
  display: flex;
  flex-direction: row; /* Cambiar de column a row */
  align-items: center; /* Centrar verticalmente los elementos */
  gap: 0px; /* Espacio entre el input y el botón */
}

.chat-input {
  flex: 1;
  padding: 1em;
  min-width: 0;
  /* Eliminar height fijo si existe */
}

.chat-button {
  padding: 0.6em 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.5em; /* Ajusta según necesidad */
  border-radius: 50%; /* Opcional: hacer botón circular */
  background: none; /* Mantener el color de fondo */
  border: none; /* Asegurarse de que no haya bordes no deseados */
  cursor: pointer;
}

/* Opcional: Ajustar el tamaño de la imagen dentro del botón */
.chat-button img {
  width: 3em;
  height: 3em;
}
/* Modal de Implementación */

.modal-pin {
  width: 80%; /* Ancho del modal para pantallas más pequeñas */
  max-height: 80vh;
}

/* Instrucciones de personalización */
.color-picker-inline input {
  width: 80px; /* Reducir el tamaño del input */
  padding: 10px 15px; /* Ajustar el padding */
}

.color-picker-container {
  margin-bottom: 0.5em; /* Mayor espacio para la separación */
}

/* Pestañas de implementación */
.tabs-container {
  flex-wrap: wrap; /* Permite que las pestañas se ajusten a la pantalla */
  justify-content: center;
}

.tab {
  font-size: 1em;
  padding: 0.4em 1em; /* Reducir el padding en pantallas más pequeñas */
  margin: 0.3em; /* Ajustar margen entre pestañas */
  border-radius: 60px;
}

/* Mensajes del chat */
.message {
  max-width: 80%; /* Aumenta el ancho máximo de los mensajes */
  align-self: center; /* Centra todos los mensajes */
}

/* Ajuste de botones y elementos del modal */
.modal-buttons {
  flex-direction: column; /* Coloca los botones uno encima del otro */
  gap: 10px; /* Mayor espacio entre botones */
  align-items: center;
}

.submit-pin-button,
.cancel-pin-button {
  width: 100%;
}

/* Selector de base de datos */
.base-selector select {
  -webkit-appearance: none;   /* elimina estilo por defecto en iOS */
  vertical-align: middle;
  line-height: normal;        /* fuerza altura de línea “normal” */
  height: 3em;
  align-self: center;
  align-items: center;
  justify-content: center;
  padding: .8em 1.2em;
  width: 100%;
}

/* Botones de WhatsApp y plataformas */
.platform-buttons {
  flex-direction: column; /* Coloca los botones uno encima del otro */
  gap: 10px; /* Mayor espacio entre los botones */
  align-items: center;
}


.generate-custom-script {
  width: 100%;
  padding: 1em 0.5em;
  align-self: center;
  justify-self: center;
  margin-bottom: 2em;
  font-size: 0.9em;
  border-radius: 80px;
}
.back-button-container{
  flex-direction: column;
  width: 70%;
  padding-top: 1.5em;
}
}


@media (max-width: 1280px) {
.modal-pin {
  width: 90%; /* Modal más ancho para pantallas muy pequeñas */
}

.modal-implementacion {
  border: none;
  box-shadow: none;
  max-height: 100vh;
  height: 80vh;
  padding: 0 0em;
  width: 90vw;
  background: linear-gradient(180deg, #4B1F8B, #15093C, #15093C);
  border-radius: 0;
}

.code-script {
  margin: 0 1.1em;
}
.customization-section{
  margin: 0 1.2em;
}

.implementacion-h2{
  font-family: 'Outfit';
  font-weight: 500;
  font-size: 1.8em;
  padding-top: 1.5em;
}
.destino{
  font-size: 1.1em;
}

.modal-implementacion .tabs-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Centra los elementos en cada línea */
  gap: 10px; /* Espacio entre pestañas */
  max-width: 500px;
  margin: 0 auto;
  margin-top: 1.2em;
}

.modal-implementacion .tabs-container .tab {
  flex: 0 0 40%;          /* Cada tab ocupará el 40% del ancho del contenedor */
  min-height: 50px;       /* Define una altura mínima (ajústala según lo que necesites) */
  padding: 0 1em;           /* Aumenta el padding para que sean "gorditas" de alto */
  text-align: center;
  margin: 0;              /* Sin margen vertical, o ajústalo si lo requieres */
  display: flex;
  align-items: center;    /* Centra verticalmente el contenido */
  justify-content: center;/* Centra horizontalmente el contenido */
}

.instructions-textarea {
  padding: 1em; /* Reducir padding */
  background-color: #1F1E3F;
  box-shadow: none;
  font-size: 0.8em;
        height: 30vh;
}
  .chat-input-container {
    display: flex;
    flex-direction: row; /* Cambiar de column a row */
    align-items: center; /* Centrar verticalmente los elementos */
    gap: 0; /* Espacio entre el input y el botón */
    padding: 0 1em;          /* Opcional: agrega padding lateral si lo requieres */
  }

  .chat-input {
    flex: 1;
    padding: 1em;
    min-width: 0;
    /* Eliminar height fijo si existe */
  }

  .chat-button {
    padding: 0.6em 1em;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3.5em; /* Ajusta según necesidad */
    border-radius: 50%; /* Opcional: hacer botón circular */
    background: none; /* Mantener el color de fondo */
    border: none; /* Asegurarse de que no haya bordes no deseados */
    cursor: pointer;
  }

  /* Opcional: Ajustar el tamaño de la imagen dentro del botón */
  .chat-button img {
    width: 2.8em;
    height: 2.8em;
  }



.modal-title {
  font-size: 2em; /* Reducir tamaño del título del modal */
}

.modal-subtitle {
  font-size: 1.2em; /* Reducir tamaño del subtítulo */
}

.pin-input {
  padding: 0.8em; /* Reducir padding del input de PIN */
}

.form-personalizar,
.cargar-imagen-bubble {
  font-size: 1em; /* Reducir tamaño de fuente */
}
.color-picker-inline {
  display: flex;
  gap: 10px;
  position: relative;
}

.bubble-image-options {
  display: flex
;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: start;
}

.assistant-chat-page {
  padding: 0;
  max-width: 600px;
  margin: 0 auto; /* Esto centra el contenedor horizontalmente */
}
.editor-agente,
.chat-header{
  font-size: 1.4em;
  text-align: center;
  align-self: center;
  margin-bottom: 0.3em;
}

.vista-preliminar{
  text-align: center;
  align-self: center;
  font-size: 1em;
}
.activar-base{
  font-size: 0.9em;
  text-align: center;
}
.switch{
  top: 0.2em;
}
.toggle-container {
  display: flex;               /* Activar Flexbox */
  justify-content: center;     /* Centrar horizontalmente */
  align-items: center;         /* Centrar verticalmente (opcional) */
  padding-left: 0;             /* Eliminar padding-left existente */
  width: 100%;                 /* Asegurar que el contenedor ocupe todo el ancho */
}

/* Opcional: Ajustar los elementos internos si es necesario */
.activar-base {
  margin-right: 0.5em;         /* Reducir margen derecho */
  text-align: center;          /* Centrar el texto */
}
.select-and-button{
  flex-direction: row;
  gap: 0em;
}
.label-number{
  font-size: 0.8em;
}
.number-select{
  width: 60%;
}
.activar-base
.base-selector{
  margin-top: 0.5em;
}
    /* **Nuevos estilos para los botones en móviles** */
    .edit-instructions-button,
    .save-instructions-button,
    .delete-agent-button {
        padding: 1em 1em;          /* Reducir padding vertical y horizontal */
        font-size: 0.8em;            /* Reducir tamaño de fuente */
        border-radius: 20px;         /* Opcional: Reducir radio de borde para un aspecto más compacto */
    }
    .assistant-page-container{
      gap: 0px;
    }
    .chat-messages {
      background-color: #1F1E3F;
      width: 100%;
      box-sizing: border-box;
      max-width: 100%;
    }
    .chat-container {
      padding-bottom: 0;
      padding: 0;
      padding-top: 3em;
      box-sizing: border-box;
    }
    .message{
      font-size: 0.8em;
      max-width: 75%;
    }
    .non-social-tab-content {
      max-width: 600px;
      margin: 0 auto; /* Centra horizontalmente */
    }
}



/* CAMBIOS APLICADOS  */


/* pop up destino de implementación */
.additional-texts {
  text-align: center;
}

.info-text {
  color: #9100FF;
}

.help-text a {
  text-decoration: none; /* Elimina el subrayado */
  color: #9100FF; /* Cambia el color del enlace */
}

.toggle-chat-icon-button-container {
  margin-top: 1em;
}

@media (max-width: 1280px) {
  .chat-input-container {
    width: 100%;
    padding: 0 1em;          /* Opcional: agrega padding lateral si lo requieres */
  }
}
@media (min-width:768px) and (max-width: 1280px) {
.modal-implementacion{
  width: 70vw;
  height: 90vh;
}
}
/* Ejemplo de animación para "parpadear" suavemente */
@keyframes pulse-animation {
  0%   { transform: scale(1);   opacity: 0.8; }
  50%  { transform: scale(1.05); opacity: 1;   }
  100% { transform: scale(1);   opacity: 0.8; }
}
.options-preview-container{
  display: flex;
  flex-direction: row;
  gap: 2em;
}



.options-container{
  display: flex;
  flex-direction: column;
  width: 50%;
}
/* Contenedor principal “mini” */
.mini-chat-bubble-frontend {
  width: 50%;          /* tamaño reducido */
  border-radius: 22px;   /* esquinas redondeadas */
  font-family: 'Poppins', sans-serif;
  overflow: hidden;
  background-color: #010321; /* color base si no hay imagen */
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  margin: 0em; /* Separación */
  margin-top: 2.5em;
  position: relative;
}

/* Encabezado mini */
.mini-chat-header-bubble {
  display: flex;
  align-items: center;
  justify-content: start;
  padding-left: 1.5em;
  height: 3.5em;
  background: linear-gradient(170deg, #6268D4, #7400DC);
  /* border-radius top left/right si gustas */
}

.mini-h3-bubble-frontend {
  font-size: 1.2em;
  text-align: left;
  color: white;
  margin: 0;
  font-family: 'Outfit', sans-serif;
  font-weight: 500;
}

/* Contenedor de mensajes mini */
.mini-chat-messages-bubble {
  padding: 0.5em;
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  background-color: transparent;
  height: 70%;
}

/* Mensaje mini (user/assistant) */
.mini-message {
  color: #fff;
  border-radius: 12px;
  padding: 0.8em 1em;
  font-size: 0.85em;
  width: fit-content;
  max-width: 55%;
}

.mini-message.user {
  align-self: flex-end;
  text-align: right;
  background: linear-gradient(
    to bottom,
    rgba(149, 59, 245, 0.179),
    rgba(87, 35, 143, 0.85)
  );
}

.mini-message.assistant {
  align-self: flex-start;
  text-align: left;
  background: linear-gradient(
    to bottom,
    rgba(64, 191, 213, 0.39),
    rgba(64, 106, 213, 0.39),
    rgba(103, 64, 213, 0.39)
  );
}

.mini-message-content {
  /* sin mucho styling extra */
  display: inline-block;
}

/* Footer (input) mini (opcional) */
.mini-chat-input-container {
  background-color: rgba(255,255,255,0.12);
  padding: 0.4em;
  display: flex;
}

.mini-chat-input {
  flex: 1;
  border: none;
  background: transparent;
  color: #eee;
  font-size: 0.7em;
}
.mini-chat-input:focus {
  outline: none;
}
.mini-chat-input-container {
  display: flex;
  align-items: center;
  padding: 0.5em;
  background: none;
  color: #fff;
}

.mini-chat-input {
  flex: 1 ;
  border: none;
  outline: none;
  background-color: #ffffff53;
  border: 1px solid #fff;
  color: #fff;
  font-size: 0.7em;
  font-family: 'Poppins', sans-serif;
  padding: 0.4em 0.7em;
  border-radius: 16px;
}

.mini-chat-input::placeholder {
  color: #fff;
  font-family: Poppins;
  font-size: 0.9em;
  opacity: 100%;
}


.mini-send-button {
  border: none;
  background: none;
  cursor: default;    /* O “not-allowed” si prefieres */
  margin-left: 0.3em;
}

.mini-send-button-img {
  width: 2em; 
  opacity: 0.7;
}
.chat-icon-button-preview {
  width: 54px;
  height: 54px;
  border-radius: 50%;
  margin-left: 3em;
  margin-top: -0.5em;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease;
}
@media (max-width: 768px) {
  .burbujas-predet {
    font-size: 0.9em;
  }
  .options-preview-container {
    flex-direction: column;
    gap: 1em;
  }
  
  /* Para forzar que el mini chat se muestre primero */
  .mini-chat-bubble-frontend {
    order: -1;        /* o un valor pequeño, para que aparezca antes que los demás */
    width: 15em;  /* si quieres que ocupe todo el ancho */
    margin: auto;
    margin-bottom: 0em;      /* añade un margen si lo deseas */
    margin-top: 0.5em;
  }
  .options-container{
    width: 100%;
  }
  .chat-icon-button-preview{
    margin-left: 0;
  }
  .color-picker-inline{
    margin-top: 1.4em;
  }
  .form-personalizar{
    margin-top: 1em;
  }
  .default-bubble-images{
    margin-top: 1.8em;
  }
  .image-upload-container{
    margin-bottom: 2em;
  }
}
.chat-messages-wrapper {
  position: relative; /* Permite que los elementos hijos se posicionen de forma absoluta */
  margin-top: 1em;    /* Ajusta según convenga */
}

.chat-header-container {
  display: flex;
  justify-content: space-between; /* Distribuye el espacio para que el label quede a la izquierda y el botón a la derecha */
  align-items: center; /* Centra verticalmente los elementos */
  margin-bottom: 0.5em; /* Espacio inferior (ajusta según necesites) */
  padding: 0 1em; /* Opcional: para dar un poco de margen lateral */
  width: 100%;
}

.clear-thread-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    margin: 0;
    font-size: 1.5em;
}

.slider-training {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 20px;
}

.slider-training:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider-training{
  background: linear-gradient(165deg, rgba(63, 114, 215, 0.691), #75b1d4);
}

input:checked + .slider-training:before {
  transform: translateX(20px);
}
.delete-phone-button {
  background: none;
  border: none;
  color: rgb(255, 255, 255);
  font-size: 1em;
  font-family: 'Poppins';
  font-weight: 600;
  text-transform: lowercase;
  cursor: pointer;
  margin-left: 0em;

}
@media (max-width: 1280px) {
  .chat-header-container{
    width: 95%;
  }
  .clear-thread-button{
    font-size: 1.2em;
  }
}

.instructions-header {
  display: flex;
  justify-content: flex-end; /* Ubica el botón a la derecha, o puedes centrarlo si prefieres */
  margin-bottom: 0em;
  margin-top: 0em;
}

.download-instructions-button {
  background: none;
  color: white;
  border: none;
  padding: 0;
  cursor: pointer;
  border-radius: 4px;
  font-size: 1em;
  font-family: 'Poppins';
  transition: all 0.3s ease;
  margin-left: 0em;
  margin-right: 2em;
  margin-top: 0.5em;
  margin-bottom: -0.8em;
}

