/* Estilos para las imágenes de los templates */
.template-image {
    width: 48%;
    object-fit: cover; /* Asegura que la imagen se ajuste bien al espacio */
    margin-bottom: 10px; /* Espacio entre la imagen y el nombre */

background: transparent 0% 0% no-repeat padding-box;
mix-blend-mode: screen;
opacity: 1;
  }
  
  /* Resto de los estilos generales */

/* Estilos generales para la página */
.templates-page {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 90%; /* Asegura que la página ocupe todo el ancho */
  }
    
    /* Logo de la app */
    .app-logo {
      position: absolute;
      top: 20px;
      left: 20px;
      height: 80px;
      z-index: 1000;
    }
    .templates-container {
        display: grid;
        grid-template-columns: repeat(3, 1fr); /* Crea 3 columnas del mismo ancho */
        gap: 40px; /* Espaciado entre las tarjetas */
        justify-items: center; /* Centra las tarjetas horizontalmente */
        margin-top: 40px;
        max-width: 80%; /* Ajusta el ancho máximo para pantallas más grandes */
        margin: 0 auto; /* Asegura que el contenedor esté centrado */
      }
      
      /* Ajusta el estilo para pantallas de 1079px o menores */
      @media (max-width: 1079px) {
        .templates-container {
          grid-template-columns: repeat(2, 1fr); /* Crea 2 columnas en pantallas más pequeñas */
        }
        .templates-page h2 {
            margin-top: 15%;
          }
      }
      
      /* Ajusta el estilo para pantallas más pequeñas */
      @media (max-width: 768px) {
        .templates-container {
          grid-template-columns: repeat(2, 1fr); /* Mantén 2 columnas en pantallas más pequeñas */
        }
        .templates-page h2 {
            margin-top: 20%;
          }
      }
      
      @media (max-width: 699px) {
        .templates-container {
          grid-template-columns: 1fr; /* Una columna en pantallas muy pequeñas */
        }
        .templates-page h2 {
            margin-top: 30%;
          }
      }
    
    /* Estilos para los templates */
    .template-card {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 200px;
      padding: 20px;
      background: linear-gradient(150deg, rgba(225, 11, 171, 0.28), rgba(32, 244, 241, 0.213) ); /* Colores con transparencia */
      border-radius: 10px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
      text-align: center;
      cursor: pointer;
      transition: transform 0.3s ease, box-shadow 0.3s ease;
    }
    
    /* Efecto hover para las tarjetas */
    .template-card:hover {
      transform: translateY(-10px);
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.4);
    }
    
    /* Títulos y texto */
    .template-card h3 {
      font-size: large;
      color: white;
      font-weight: 400;
      color:#45E3FF;
      margin-bottom: 10px;
    }
    
    .template-card p {
      font-size: 14px;
      color: white;
    }
    
    /* Título de la página */
    .templates-page h2 {
      color: white;
      font-weight: 400;
      font-size: xx-large;
      margin-bottom: 50px;
    }
    
    /* Estilos del modal */
    .modal-background {
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      background-color: rgba(0, 0, 0, 0.8);
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1000;
    }
    
    
