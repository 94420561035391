/* Contenedor de autenticación */
.auth-container {
  position: relative; /* Necesario para que la X se posicione dentro del contenedor */
  background: transparent linear-gradient(180deg, #953BF526 0%, #57238FB3 100%) 0% 0% no-repeat padding-box; /* Colores con transparencia */
  padding: 2.5em 60px;
  border-radius: 50px;
  width: 500px; /* Reducido de 550px */
  max-width: 90vw;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center; 
  justify-content: center;
  border: solid 1px #9101FF;
}
/* Aumentar la especificidad agregando el contenedor padre */
.auth-container .google-button,
.auth-container .email-button,
.auth-container .auth2-button {
  cursor: pointer !important;
}


/* Estilo para el botón de cerrar (la cruz) */
.auth-container .close-button {
  position: absolute;
  top: 8%;
  right: 60px;
  background: transparent;
  border: none;
  font-size: 22px;
  color: white;
  cursor: pointer;
  padding: 2px 8px;
  box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.6); /* Borde fino blanco */
  border-radius: 4px; /* Añade esquinas ligeramente redondeadas */
}


/* Estilo para el asterisco */
.required {
  color: #00e5ff;
  margin-left: 5px;
  font-size: 16px;
  vertical-align: middle;
  position: relative;
  cursor: pointer; /* Para indicar que es interactivo */
}

/* Estilo del tooltip (invisible por defecto) */
.required:hover::after {
  content: 'Campo obligatorio';
  position: absolute;
  background-color: #333;
  color: white;
  padding: 5px 8px;
  border-radius: 5px;
  top: -12px;
  left: 70px;
  transform: translateX(-50%);
  white-space: nowrap;
  font-size: 12px;
  opacity: 0;
  pointer-events: none; /* Hace que el tooltip no interfiera con otros elementos */
  transition: opacity 0.2s ease-in-out;
}

/* Muestra el tooltip cuando haces hover */
.required:hover::after {
  opacity: 1;
}



.auth-container h2{
  font-family: 'Outfit';
  text-align: left;
  font-weight: 700;
  font-size: 36px; /* Reducido de 42px */
  color: white;
  margin-bottom: 1em;
}

  
  /* Formulario de autenticación centrado */
  .auth-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%; /* Asegura que el formulario ocupe todo el ancho disponible */
  }

  
/* Estilo para las etiquetas */
.auth-label {
  color: white;
  font-size: large;
  font-weight: 300;
  text-align: center;
  width: 100%;
  margin-bottom: 5px;
}

/* Estilo para los campos de entrada */
.auth-input {
  width: 88%;
  padding: 15px 30px;
  margin-bottom: 20px; /* Espacio entre los inputs */
  border-radius: 15px;
  border: none;
  color: white;
  font-size: small;
  font-family: 'Poppins';

  /* UI Properties */
background: #2D145C 0% 0% no-repeat padding-box;
box-shadow: inset 0px 0px 50px #1C032F81;
mix-blend-mode: normal;
opacity: 1;
}

/* Aplica estilos personalizados cuando el campo está autofill en navegadores basados en WebKit (como Chrome) */
input:-webkit-autofill {
  background-color: #2D145C !important; /* Tu color de fondo personalizado */
  color: white !important; /* Color del texto personalizado */
    /* UI Properties */
background: #2D145C 0% 0% no-repeat padding-box;
box-shadow: inset 0px 0px 50px #1C032F81;
mix-blend-mode: normal;
opacity: 1;
  box-shadow: 0 0 0px 1000px #2D145C inset !important; /* Para sobrescribir el color de fondo */
  -webkit-box-shadow: 0 0 0px 1000px #2D145C inset !important;
  -webkit-text-fill-color: white !important; /* Color del texto */
  transition: background-color 5000s ease-in-out 0s; /* Evita el cambio de color de fondo después de la autofill */
}


/* Placeholder tenue */
.auth-input::placeholder {
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 15px;
  color: #FEFEFE;
opacity: 0.44;
}

  .a {
    color: #FFF;
    font-weight: 400;
    text-decoration: none;

  }
  .auth-text {
    font-family: 'Outfit';
    font-weight: 600;
    font-size: 18px; /* Reducido de x-large */
  }
  
  /* Botón de autenticación */
  .auth-button {
    background: #9100FF;
    color: white;
    font-family: 'Poppins';
    text-transform: uppercase;
    font-size: 16px; /* Reducido de large */
    font-weight: 400;
    padding: 14px 40px;
    border: none;
    border-radius: 30px;
    cursor: pointer;
    margin-left: 0.5em;
    margin-right: 0.5em;
    margin-bottom: 0;
    margin-top: 40px;
    transition: background-color 0.3s ease;
  }
  
/* Botón de autenticación */
button.auth-button,
button.auth2-button {
  background: #9100FF;
  color: white;
  font-family: 'Poppins';
  text-transform: uppercase;
  font-size: 16px; /* Reducido de large */
  font-weight: 400;
  padding: 14px 40px;
  border: none;
  border-radius: 30px;
  cursor: pointer; /* Asegurado */
  margin: 0.5em 0; /* Simplificado */
  transition: background-color 0.3s ease;
}

/* Hover en botones de autenticación */
button.auth-button:hover,
button.auth2-button:hover {
  background-color: #7a00d4;
}

    
  /* Mensaje de error */
  .error {
    color: red;
    margin-top: 10px;
  }
  
  /* Botón de Google */
  .google-button {
/* UI Properties */
background: #2D145C 0% 0% no-repeat padding-box;
box-shadow: inset 0px 0px 50px #1C032FDD;
mix-blend-mode: normal;
opacity: 1;
color: #ffff;
/* Layout Properties */
width: 350px; /* Reducido de 400px */
height: 45px; /* Reducido de 50px */
padding-right: 8%;
    border-radius: 12px;
    margin-top: 1%;
    margin-bottom: 4%;
    cursor: pointer;
    font-family: 'Outfit';
    font-size: 16px; /* Reducido de larger */
    font-weight: 300;
    text-transform: none;
    align-items: center;
    display: flex;
    justify-content: center;
    text-align: center;
    border: none; /* Quita el borde */
  }
  
  .text-auth{
    color: #E10BAB;
    font-weight: 600;
    font-size: small;
    text-decoration: none;
    margin-bottom: 4%;
  }

  
  .google-icon {
    width: 18%;
    /* UI Properties */
mix-blend-mode: lighten;
opacity: 0.75;
  }

  .email-icon {
    width: 8%;
    margin-right: 4%;
  }

  /* Botón para continuar con email */
.email-button {
/* UI Properties */
background: #2D145C 0% 0% no-repeat padding-box;
box-shadow: inset 0px 0px 50px #1C032FDD;
mix-blend-mode: normal;
opacity: 1;
color: #ffff;
/* Layout Properties */
width: 350px; /* Reducido de 400px */
height: 45px; /* Reducido de 50px */
padding-bottom: 1%;
    border-radius: 12px;
    margin-top: 1%;
    margin-bottom: 4%;
    cursor: pointer;
    font-family: 'Outfit';
    font-size: 16px; /* Reducido de larger */
    font-weight: 300;
    text-transform: none;
    align-items: center;
    display: flex;
    justify-content: center;
    text-align: center;
    border: none; /* Quita el borde */
}

  .login-button {
    margin: 0;
    background: transparent; /* Fondo transparente */
    border: 1px solid rgba(255, 255, 255, 0.5); /* Borde delgado blanco semi-transparente */
    border-radius: 25px; /* Bordes redondeados */
    color: white; /* Color del texto en blanco */
    font-size: large; /* Tamaño de la fuente */
    padding: 1% 30%; /* Espaciado interno */
    font-family: 'Poppins', sans-serif; /* Fuente */
    font-weight: 400;
    text-transform: none; /* Texto en mayúsculas */
    cursor: pointer; /* Cambia el cursor al pasar por encima */
    transition: all 0.3s ease; /* Transición suave en hover */
  }
  .signin-button {
    margin: 0;
    background: transparent; /* Fondo transparente */
    border: 1px solid rgba(255, 255, 255, 0.5); /* Borde delgado blanco semi-transparente */
    border-radius: 25px; /* Bordes redondeados */
    color: white; /* Color del texto en blanco */
    font-size: large; /* Tamaño de la fuente */
    padding: 1% ; /* Espaciado interno */
    width: 250px;
    font-family: 'Poppins', sans-serif; /* Fuente */
    font-weight: 400;
    text-transform: none; /* Texto en mayúsculas */
    cursor: pointer; /* Cambia el cursor al pasar por encima */
    transition: all 0.3s ease; /* Transición suave en hover */
  }

/* Pantallas grandes (1367px en adelante) */
@media (min-width: 1367px) {
  .auth-container {
    padding: 2.5em 60px;
    width: 500px;
  }
}

/* Notebooks (1024px a 1366px) */
@media (min-width: 1024px) and (max-width: 1366px) {
  .auth-container {
    padding: 2em 40px;
    width: 450px;
    border-radius: 40px;
  }

  .auth-container h2 {
    font-size: 32px;
    margin-bottom: 0.8em;
  }

  .google-button,
  .email-button {
    width: 300px;
    height: 40px;
    font-size: 15px;
  }

  .auth-input {
    padding: 12px 25px;
    margin-bottom: 15px;
  }

  .auth-button {
    padding: 12px 40px;
    font-size: 16px;
  }
}

/* Tablets (768px a 1023px) */
@media (min-width: 768px) and (max-width: 1023px) {
  .auth-container {
    padding: 2em 30px;
    width: 400px;
  }

  .auth-container h2 {
    font-size: 28px;
  }

  .google-button,
  .email-button {
    width: 280px;
    height: 40px;
  }
}

/* Actualizar media query móvil existente */
@media (max-width: 767px) {
  .auth-container {
    padding: 1.5em 20px;
    width: 90%;
    max-width: 350px;
    border-radius: 25px;
  }

  .auth-container h2 {
    font-size: 24px;
  }

  .google-button,
  .email-button {
    width: 100%;
    max-width: 280px;
    height: 40px;
    font-size: 14px;
  }

  .auth-text {
    font-size: 16px;
  }

  .auth-input {
    width: 85%;
    padding: 10px 20px;
  }

  .auth-button {
    padding: 10px 30px;
    font-size: 14px;
  }
}