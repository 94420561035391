/* src/components/HeaderMobile.css */
.mobile-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 60px;
    background-color: #010321;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0em;
    z-index: 1100;
}
  
.mobile-header.light-mode {
    background-color: #f0f0f0;
    color: #333;
}
  
.mobile-header.dark-mode {
    background-color: #010321;
    color: #fff;
}
  
.mobile-header-left {
    flex: 0 0 auto;
    width: 3.1em;
    height: 100%;
    align-items: center;
    justify-content: center;
}

/* Nuevo estilo para cuando la MobileSidebar está activa */
.mobile-header-left.active-sidebar {
    background-color: #5D5D7A;
}
  
.hamburger-button {
    background: none;
    border: none;
    color: inherit;
    font-size: 1.5em;
    cursor: pointer;
    align-self: center;
    padding-top: 0.8em;
    padding-left: 0.6em;
}
  
.mobile-header-center {
    flex: 1 1 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5em;
}
  
.mobile-arrow-icon {
    font-size: 1em;
    color: #977FE2;
    transform: rotate(0deg);
}
  
.mobile-header-title {
    font-size: 1.1em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #977FE2;
    font-family: 'Poppins';
    font-weight: 400;
}
  
.mobile-header-right {
    flex: 0 0 auto;
    position: relative;
    width: 3em;
}
  
.notification-button {
    background: none;
    border: none;
    cursor: pointer;
}
  
.bell-icon {
    width: 1.5em;
    height: auto;
}
  
.notification-modal-backdrop {
    position: absolute;
    top: 100%;
    right: 0;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding-top: 0.5em;
    z-index: 1200;
}
  
.notification-modal {
    background-color: #2e2d54;
    box-shadow: inset 0px 0px 30px #ffffffba;
    border-radius: 24px;
    padding: 1em 1.5em;
    width: 13rem;
    color: #fff;
}
  
.notification-title {
    font-size: 1em;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 0.5em;
}
  
.notification-subtitle {
    font-size: 0.9em;
    margin-bottom: 0.3em;
}
  
.notification-text {
    font-size: 0.9em;
}
