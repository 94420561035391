/* src/components/WideSidebar.css */

.wide-sidebar {
  background-color: #0F1131;
  width: 18em;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 60px;
  z-index: 999;
  transition: all 0.3s ease;
  color: white;
  padding-top: 3.8em;
}

.wide-sidebar.hidden {
  transform: translateX(-100%);
}

.wide-sidebar.visible {
  transform: translateX(0);
}

.wide-sidebar-content h2 {
  font-family: 'Outfit';
  font-weight: 500;
  font-size: 1.4em;
  margin-top: 1.5em;
  margin-bottom: 1em;
  margin-left: 1.2em;
}

.wide-sidebar-content ul {
  list-style-type: none;
  padding: 0;
}


.wide-sidebar-content li {
  margin-bottom: 0.2em;
  cursor: pointer;
  font-size: 1.05em;
  text-align: left;
  padding-left: 1.6em; /* Espacio adicional para el borde al hacer hover */
  transition: background-color 0.3s ease, border-left 0.3s ease;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  text-transform: uppercase;
}

.wide-sidebar-content ul li:focus {
  outline: none;
  background-color: rgba(172, 158, 212, 0.24); /* Mismo fondo que en hover */
  border-left: 6px solid #835DF1;
}

.wide-sidebar-content li:hover {
  background-color: rgba(172, 158, 212, 0.24); /* Fondo con 24% de opacidad */
  border-left: 6px solid #835DF1; /* Borde izquierdo */
}

.wide-sidebar-content ul li a {
  text-decoration: none;
  color: #ffffff;
  font-size: 16px;
  text-transform: uppercase;
}

.wide-sidebar-content ul li a:hover {
  color: #835DF1; /* Cambia según tu diseño */
}

.search-container {
  margin-left: 1em;
  margin-right: 1em;
  margin-bottom: 3em;
  text-align: left;
  width: calc(100% - 2em);
}

.search-input {
  flex: 1;
  padding: 10px 16px;
  border-radius: 20px;
  border: 1px solid white;
  font-size: 0.9em;
  background-color: transparent;
  color: #fefefecc;
  width: calc(100% - 32px);
  font-family: 'Poppins';
}

.search-input::placeholder {
  color: #fefefe6d;
}

/* Estilos para la lista scrollable */
.scrollable-list {
  max-height: 400px; /* Ajusta este valor según la altura de 8 elementos */
  overflow-y: scroll; /* Cambiado a 'scroll' para que siempre aparezca el scrollbar */
  padding: 0;
  margin: 0;
  list-style-type: none;

  /* Estilos para el scrollbar */
  scrollbar-width: thin; /* Para Firefox */
  scrollbar-color: white transparent; /* Color del thumb y del track */
}

/* Estilos para el scrollbar en navegadores WebKit */
.scrollable-list::-webkit-scrollbar {
  width: 8px; /* Ancho del scrollbar */
}

.scrollable-list::-webkit-scrollbar-track {
  background: transparent; /* Fondo del track */
}

.scrollable-list::-webkit-scrollbar-thumb {
  background-color: white; /* Color del thumb */
  border-radius: 4px;
  border: none; /* Puedes agregar un borde si lo deseas */
}

/* Estilos para cada elemento de la lista */
.list-item {
  padding: 0.8em;
  margin-bottom: 0.5em;
  cursor: pointer;
  border-radius: 0px;
  transition: background-color 0.3s ease, border-left 0.3s ease;
  white-space: normal; /* Permite el salto de línea */
  word-wrap: break-word; /* Permite el salto de línea en palabras largas */
}

/* Hover y focus para los elementos de la lista */
.list-item:hover, 
.list-item:focus {
  background-color: rgba(172, 158, 212, 0.24); /* Fondo con 24% de opacidad */
  border-left: 6px solid #835DF1; /* Borde izquierdo */
  outline: none; /* Eliminar el outline predeterminado */
}

/* En WideSidebar.css */

.agent-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.thread-count {
  background: linear-gradient(180deg, #271A55 0%, #411E74 25%);
  color: #fff;
  border-radius: 50%;
  padding: 0.8em; /* Eliminamos el padding para controlar el tamaño con width y height */
  font-size: 0.9em;
  margin-left: 8px;
  display: flex; /* Usamos Flexbox para centrar el contenido */
  align-items: center;
  justify-content: center;
  width: 24px; /* Ancho fijo para un círculo perfecto */
  height: 24px; /* Alto fijo igual al ancho */
  min-width: 24px; /* Asegura que el tamaño mínimo sea 24x24px */
  /* Opcional: añade una sombra o borde si lo deseas */
  /* box-shadow: 0 0 5px rgba(0, 0, 0, 0.3); */
  /* border: 2px solid #fff; */
}




/* Media queries para ajustar la posición y el ancho según el tamaño de la pantalla */
@media (min-width: 1000px) and (max-width: 1366px) {
  .wide-sidebar {
    left: 45px;
    width: 16em; /* Reducir el ancho en pantallas más pequeñas */
  }
  .search-container {
    margin-left: 0.8em;
    margin-right: 0.8em;
    width: calc(100% - 1.6em);
  }
  
  .search-input {
    font-size: 0.85em;
    padding: 8px 14px;
  }
}

@media (max-width: 1000px) {
  .wide-sidebar {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .search-container {
    margin-left: 0.7em;
    margin-right: 0.7em;
    width: calc(100% - 1.4em);
  }
  
  .search-input {
    font-size: 0.8em;
    padding: 8px 12px;
  }
}

@media (max-width: 767px) {
  .search-container {
    margin-left: 0.6em;
    margin-right: 0.6em;
    width: calc(100% - 1.2em);
  }
  
  .search-input {
    font-size: 0.75em;
    padding: 7px 10px;
  }
}
.global-autoresponse-switch-container{
  margin-left: 2em;
  margin-top: 2.5em;
}
.switch-ia-global{
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
    top: 0.2em;
    left: 0.5em;
}
.switch-ia-global input:checked + .pending-slider {
  background-color: rgba(141, 246, 117, 0.306);
}
.switch-ia-global input {
  opacity: 0;
  width: 0;
  height: 0;
}

.switch-ia-global input:checked + .pending-slider:before {
  transform: translateX(20px);
  background-color: #59C34B;
}
.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  background-color: rgba(255, 255, 255, 0.759);
  transition: 0.4s;
  border-radius: 50%;
}