.analytics-container {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    width: 100%;
    height: 100vh;
    padding: 20px;
}

.analytics-text {
    font-size: 2.5rem;
    color: #ffffff;
    font-weight: 600;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    text-align: center;
    margin-bottom: 30px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    animation: wave 4s ease-in-out infinite;
}

/* Estilos para la cuadrícula de los contenedores de gráficas */
.analytics-grid {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 20px;
    width: 100%;
    grid-auto-rows: minmax(150px, auto);
    padding-left: 17em;
    padding-top: 5em;
}

/* Diferentes tamaños de contenedores */
.analytics-card {
    position: relative; /* Necesario para posicionar el pseudo-elemento */
    border-radius: 20px;
    overflow: hidden; /* Ocultar los elementos que sobresalen */
    padding: 10px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.2);
    transition: transform 0.6s ease, box-shadow 0.6s ease;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

/* Fondo degradado animado para cada tarjeta */
.analytics-card::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 200%;
    height: 200%;
    background-size: 400% 400%;
    background-color: #1c1c1d60;
    animation: gradientMove 8s ease infinite;
    filter: blur(15px);
    z-index: 0; /* Asegura que el degradado esté detrás del contenido */
}

@keyframes gradientMove {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

/* Efecto de flotación suave */
@keyframes float {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-10px);
    }
}

.analytics-card:hover {
    transform: scale(1.05);
    box-shadow: 0 18px 40px rgba(0, 0, 0, 0.438);
}

/* Cuerpo del contenedor de la gráfica */
.analytics-card-body {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    filter: blur(7px); /* Desenfoque constante para que la gráfica se vea borrosa */
    position: relative;
    z-index: 1; /* Asegura que la gráfica esté encima del fondo degradado */
}

/* Tamaños específicos para un aspecto visual más dinámico */
.large-card {
    grid-column: span 3;
    grid-row: span 2;
    animation: float 4s ease-in-out infinite; /* Añade efecto de flotación */
}

.small-card {
    grid-column: span 2;
    grid-row: span 1;
    animation: float 3s ease-in-out infinite; /* Añade efecto de flotación */
}

.medium-card {
    grid-column: span 2;
    grid-row: span 2;
}

.tall-card {
    grid-column: span 3;
    grid-row: span 1;
    animation: float 4s ease-in-out infinite; /* Añade efecto de flotación */
}

/* Estilos específicos para tabletas y móviles */
@media (max-width: 1024px) {
    .analytics-grid {
        grid-template-columns: repeat(3, 1fr);
        padding-left: 0;
    }

    .analytics-text {
        font-size: 2.5rem;
    }
}

@media (max-width: 768px) {
    .analytics-grid {
        grid-template-columns: repeat(2, 1fr);
        padding-left: 0;
    }

    .analytics-text {
        font-size: 2rem;
    }
}
