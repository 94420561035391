/* Clase para ocultar visualmente elementos manteniendo su accesibilidad */
.visually-hidden {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
  pointer-events: none; 
}

.crm-container {
  color: white;
  padding: 20px;
  height: 100vh;
  width: calc(100%);
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-image: url('../assets/crm-background.png');
  background-size: cover;        /* Ajusta el fondo para que cubra todo el contenedor */
  background-position: center;   /* Centra la imagen de fondo */
  background-repeat: no-repeat;  /* Evita que la imagen se repita */
}

.crm-title {
  font-size: 24px;
  margin-bottom: 20px;
}

.crm-columns-container {
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-wrap: nowrap;
  overflow-x: auto;
  padding-bottom: 20px;
}

.crm-column {
  border-radius: 10px;
  min-width: 250px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 100px);
}

.crm-column-header {
  font-size: 1.5em;
  font-family: 'Outfit';
  font-weight: 500;
  margin-left: 0.4em;
  padding: 10px;
  text-transform: none;
}

/* Estilos para el conteo de tarjetitas en el encabezado de la columna */
.column-count {
font-size: 0.8em;           /* Fuente más pequeña */
color: #ac9ed4;             /* Color sutil que coincide con el diseño existente */
margin-left: 0.5em;         /* Espaciado entre el nombre de la columna y el conteo */
font-family: 'Outfit', sans-serif; /* Asegura consistencia con el resto del texto */
}

/* Opcional: Ajustes para pantallas pequeñas */
@media (max-width: 768px) {
.column-count {
  font-size: 0.7em;
}
}

.crm-column-content {
  padding: 10px;
  overflow-y: auto;
  flex-grow: 1;
}

.crm-card {
  min-height: 6.5em;
  min-width: 260px;
  max-width: 260px;
  justify-content: flex-start; /* Alinear horizontalmente a la izquierda */
  align-items: center;        /* Centrar verticalmente */
  padding: 1.2em;
  margin-bottom: 10px;
  color: #ffffff;
  display: flex;
  flex-direction: row;
  gap: 10px;
  background: #191743 0% 0% no-repeat padding-box;
  box-shadow: 10px 10px 10px #16131D2F;
  border-radius: 35px;
  position: relative;
  transition: transform 0.2s ease, opacity 0.2s ease, border 0.2s ease;
}

.crm-card-inside {
  justify-content: flex-start; /* Alinear horizontalmente a la izquierda */
  align-items: center;        /* Centrar verticalmente */
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding-bottom: 2.6em;
  padding-top: 1.7em;
}

.crm-card-header {
  display: flex;
  align-items: center;
  justify-content: center; /* Centrar el contenido horizontalmente */
  gap: 10px;
  width: 50px;             /* Ancho fijo, ajusta según tus necesidades */
}

.crm-card-body {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: start;
  text-align: left;
  margin-left: 0.2em;
}

.crm-card-body h4 {
  margin: 0;
  font-size: 1.2em;
  font-family: 'Outfit';
  font-weight: 500;
  order: 1;
}

.label-line {
  width: 12em;
  height: 0.5em;
  display: flex;
  margin-top: 0.5em;
  order: 3;
}

.company-name {
  font-size: 0.9em;
  color: #ac9ed4;
  margin: 0em 0 0 0;
  font-style: italic;
  order: 2;
}

.crm-card-body p[style*="margin-top: 0.3em"] {
  order: 4;
  margin: 0.2em 0 0 0 !important;
}

.owner-name {
  font-size: 0.8em;
  color: #ac9ed4;
  margin: 0.2em 0 0 0;
  font-style: italic;
  order: 6;
}

.crm-card-body p:not(.company-name):not(.owner-name):not(.importe-value) {
  margin: 0.2em 0 0 0;
  order: 5;
}

.crm-avatar-container {
  width: 2.5em;
  height: 2.5em;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.crm-avatar-initials {
  font-weight: 500;
  font-size: 1.2em;
  color: #fff;
  z-index: 2;
  font-family: 'Outfit';
}

.crm-whatsapp-icon {
  width: 1.2em; /* Ajusta el tamaño según tus necesidades */
  height: 1.2em;
  position: absolute;
  bottom: -0.5em;
  right: -0.5em;
  padding: 0;
  z-index: 3;
}

.crm-whatsapp-icon-large {
  width: 2em; /* Ajusta el tamaño según tus necesidades */
  height: 2em;
  position: absolute;
  bottom: -0.6em;
  right: -0.8em;
  padding: 0;
  z-index: 3;
}

.crm-webchat-avatar-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.crm-web-chat-icon {
  width: 2.5em;
  height: 2.5em;
}

.crm-web-chat-icon-large {
  width: 3em;
  height: 3em;
}



.no-threads-text {
  color: #ac9ed4;
  text-align: center;
  margin-top: 20px;
}

.crm-new-column {
  margin-top: 2.7em;
  background: #191743;
  border-radius: 35px;
  width: 250px;
  min-width: 250px;
  height: 83.9vh; /* Ajuste solicitado */
  display: flex;
  flex-direction: column; /* Para colocar la imagen arriba y el texto abajo */
  align-items: center;
  justify-content: center;
  color: #5A5891;
  font-family: 'Outfit';
  font-size: 1.3em;
  font-weight: 500;
  cursor: pointer;
  flex-shrink: 0;
  text-align: center;
}

.crm-new-column:hover {
  background: #191743d4;
}

.crm-modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0,0,0,0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

/* Antes: .crm-modal definía max-height + overflow-y: hidden */
.crm-modal {
  border-radius: 10px;
  width: 42em;
  height: fit-content;
  align-items: center;
  justify-content: center;
  position: relative;
  max-height: 70vh;



  background-color: #2D145C;
  border: 4px solid #9101FF;
  box-shadow: inset 0px 0px 50px #100521B1;
  border-radius: 41px;
}

/* Antes: .crm-modal definía max-height + overflow-y: hidden */
.crm-modal-column {
  border-radius: 10px;
  width: 42em;
  height: fit-content;
  align-items: center;
  justify-content: center;
  position: relative;
  max-height: 70vh;
  overflow-y: auto;
  padding: 2em;



  background-color: #2D145C;
  border: 4px solid #9101FF;
  box-shadow: inset 0px 0px 50px #100521B1;
  border-radius: 41px;
}




.crm-close-modal {
  position: absolute;
  top: 1em;
  font-family: 'Outfit';
  font-weight: 600;
  right: 1em;
  background: none;
  border: none;
  color: #9100FF;
  font-size: 1.5em;
  cursor: pointer;
    z-index: 99999; /* para asegurar que quede por encima */
    
}
 

.crm-predefined-item {
  padding: 10px;
  cursor: pointer;
  font-size: 14px;
  color: #ac9ed4;
}

.crm-custom-column {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.crm-custom-input {
  background: #2f2f4a;
  border: none;
  padding: 10px;
  color: #fff;
  border-radius: 5px;
  font-size: 14px;
}



.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px; /* Alto del contenedor, ajusta según tu necesidad */
}

.spinner {
  border: 4px solid #2a2a3f;
  border-top: 4px solid #ac9ed4;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
  margin: 0 auto;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.crm-card-date {
  position: absolute;
  top: 1.5em;
  right: 2em;
  font-size: 0.78em;
  color: #ac9ed4;
  font-family: 'Outfit', sans-serif;
}

.crm-card:hover .delete-thread-button {
  display: block;
}

.delete-thread-button {
  font-size: 0.85em;
  position: absolute;
  bottom: 10px;
  left: 2em;
  display: none;
  cursor: pointer;
  color: #ac9ed4;
  transition: color 0.2s, transform 0.2s;
}

.delete-thread-button:hover .trash-icon {
  color: #FF4D4D;
  transform: scale(1.2);
}

.label-indicator {
  flex: 1;
  margin-right: 2px;       /* Espacio entre indicadores */
  height: 60%;
}

.modal-content-crm {
  overflow-y: auto;
  overflow-x: hidden;
  width: auto;
  display: flex;
  flex-direction: column;
  background: none;
  position: relative;
  padding: 3em;
    border-radius: 15px;
    height: 28em;
    align-items: normal;
}

.modal-thread-info {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.crm-avatar-container-large {
  width: 4em;
  height: 4em;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.crm-avatar-initials-large {
  color: #fff;
  font-size: 1.5em;
  font-weight: bold;
}

.thread-details-modal {
  margin-left: 20px;
  background: none;
}

@media (max-width: 768px) {

.thread-details-modal {
  margin-top: 10px;
  margin-left: 0px;
  background: none;
}
.modal-thread-info {
  margin-bottom: 0.2em;
}
.go-to-chat-button-container {
  margin-bottom: 0.5em;
align-self: center;
}

}

.thread-details-modal h2 {
  font-size: 1.7em;
  font-weight: 500;
  font-family: 'Outfit';
  margin: 0;
  padding: 0;
  padding-bottom: 0.2em;
}

.crm-modal h2 {
  font-size: 1.7em;
  font-weight: 500;
  font-family: 'Outfit';
  margin: 0;
  padding: 0;
  padding-bottom: 0.2em;
}

.crm-modal-column h2 {
  font-size: 1.7em;
  font-weight: 500;
  font-family: 'Outfit';
  margin: 0;
  padding: 0;
  padding-bottom: 0.2em;
}

.thread-details-modal p {
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 1.1em;
  color: #987FE2;
  margin: 0;
  padding: 0;
}

.modal-labels {
  position: relative;
  margin-bottom: 20px;
}

/* Selector de etiquetas */
.label-selector {
  position: relative;
  width: 427px;
  height: 3em;
  background: #472278;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16); /* #00000029 equivale a rgba(0,0,0,0.16) */
  border-radius: 14px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  cursor: pointer;
}

.selected-labels {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  align-items: center;
  justify-content: center;
}

.selected-label {
  background-color: none; /* Puedes ajustar el color según tu preferencia */
  color: #ffffff66;
  font-family: 'Outfit';
  padding: 5px 10px;
  border-radius: 10px;
  font-size: 1.1em;
  font-weight: 300;

}
.label-color-box {
  border-radius: 3px; /* Bordes ligeramente redondeados */
}

.placeholder {
  color: #cccccc;
  font-size: 0.9em;
}

.dropdown-arrow {
  font-size: 1em;
  color: #ffffff;
}

/* Dropdown de etiquetas */
.label-dropdown {
  position: absolute;
  top: 6.5em;
  left: 0;
  width: 89%;
  max-height: 200px;
  overflow-y: auto;
  background: #472278; /* Fondo semi-transparente */
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 0px 0px 14px 14px;
  z-index: 1000;
  padding: 10px;
}

.dropdown-label-option {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  color: #ffffff;
  cursor: pointer;
  position: relative;
  padding-left: 2.5em; /* Espacio para el checkbox personalizado */
}

.dropdown-label-option:last-child {
  margin-bottom: 0;
}

.dropdown-label-option input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.custom-checkbox-display {
  position: relative; /* Cambiado de absolute a relative */
  margin-right: 0.6em; /* Añadir margen a la derecha */
  width: 18px;
  height: 18px;
  background-color: #472278; /* Relleno */
  border: 1px solid #FFFFFF; /* Borde */
  border-radius: 6px; /* Bordes redondeados */
  box-sizing: border-box;
  transition: background-color 0.2s, border-color 0.2s;
  pointer-events: none; /* Asegura que los clics pasen al input */
}

.custom-checkbox:checked + .custom-checkbox-display::after {
  content: "";
  position: absolute;
  left: 5px;
  top: 1px;
  width: 5px;
  height: 10px;
  border: solid #FFFFFF;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

/* Opcional: Efecto hover para el checkbox personalizado */
.custom-checkbox-display:hover {
  background-color: #5a2899; /* Color ligeramente más claro al hover */
}

/* Opcional: Efecto hover para el checkbox personalizado en el dropdown */
.dropdown-label-option:hover .custom-checkbox-display {
  background-color: #5a2899; /* Color ligeramente más claro al hover */
}


/* Sección de descripción */
.modal-description textarea {
  width: 95%;
  padding: 10px;
  resize: vertical;
  border: none;
  color: #ffffff66;
  font-family: 'Outfit';
  font-size: 1.1em;
  font-weight: 300;
  padding: 1em;
  background: #2D145C 0% 0% no-repeat padding-box;
  box-shadow: inset 0px 3px 12px #00000043;
  border-radius: 17px;
}

.modal-actions {
  display: flex;
  justify-content: center;
  padding-top: 2em;
}

.save-button {
  background-color: #9100FF;
  border: none;
  padding: 0.9em 2em;
  color: #fff;
  font-family: 'Poppins';
  font-size: medium;
  text-transform: uppercase;
  border-radius: 30px;
  box-sizing: border-box;
  width: 100%;
  cursor: pointer;
}

/* Estilos para la sección de tareas en el modal */
.tasks-list {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-bottom: 2em;
}

.task-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  position: relative;
  background-color: #472278;
  padding: 0.6em 1em;
  border-radius: 10px;
  font-family: 'Outfit';
  font-size: 1.2em;
  font-weight: 300;
}

.task-label {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
}

.custom-checkbox-display {
  position: relative; /* Cambiado de absolute a relative */
  margin-right: 0.6em; /* Añadir margen a la derecha */
  width: 18px;
  height: 18px;
  background-color: #472278; /* Relleno */
  border: 1px solid #FFFFFF; /* Borde */
  border-radius: 6px; /* Bordes redondeados */
  box-sizing: border-box;
  transition: background-color 0.2s, border-color 0.2s;
  pointer-events: none; /* Asegura que los clics pasen al input */
}

.custom-checkbox:checked + .custom-checkbox-display::after {
  content: "";
  position: absolute;
  left: 5px;
  top: 1px;
  width: 5px;
  height: 10px;
  border: solid #FFFFFF;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.custom-checkbox-display:hover {
  background-color: #5a2899; /* Color ligeramente más claro al hover */
}

.task-item .task-completed {
  margin-left: 0.5em; /* Reducir margen para alineación correcta */
  text-decoration: line-through;
  color: #a0a0a0;
}

.delete-task-button {
  position: absolute;
  right: 0.5em;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  color: #ffffff;
  font-size: 1.2em;
  cursor: pointer;
}

.add-task-container {
  display: flex;
  align-items: center;
  margin-top: 1em;
}

.crm-custom-input::placeholder,
.add-task-input::placeholder,
.add-comment-input::placeholder {
  color: #ac9ed4;
  opacity: 1; /* Asegura que el color se aplique completamente */
}

.add-task-input {
  flex: 1;
  padding: 8px;
  border: none;
  margin-right: 10px;
  background-color: #472278;
  padding: 0.6em 1em;
  border-radius: 10px;
  font-family: 'Outfit';
  font-size: 1.2em;
  font-weight: 300;
  color: #ffffffd8;
}

.add-task-button {
  background: #9100FF;
  border: none;
  padding: 8px 12px;
  height: 2.3em;
  border-radius: 8px;
  color: #fff;
  cursor: pointer;
  font-size: 1.2em;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  align-self: center;
}

/* Estilos para la sección de comentarios */
.modal-comments {
  margin-top: 20px;
}

.comments-list {
  list-style: none;
  padding: 0;
}

.comment-item {
  background-color: rgba(172, 158, 212, 0.1);
  border-radius: 12px;
  padding: 12px;
  margin-bottom: 12px;
  position: relative;
}

.comment-item strong {
  color: #ac9ed4;
  font-size: 0.9em;
  display: block;
  margin-bottom: 4px;
}

.comment-item p {
  color: #fff;
  margin: 0;
  font-size: 0.95em;
  line-height: 1.4;
}

.comment-item small {
  color: #6c6684;
  font-size: 0.8em;
  display: block;
  margin-top: 6px;
}

.comment-item .delete-comment-button {
  position: absolute;
  top: 8px;
  right: 8px;
  background: none;
  border: none;
  color: #6c6684;
  opacity: 0;
  transition: opacity 0.2s;
}

.comment-item:hover .delete-comment-button {
  opacity: 1;
}

.comment-item .delete-comment-button:hover {
  color: #ff4444;
}

.add-comment-container {
  display: flex;
  margin-top: 10px;
}

.add-comment-input {
  flex: 1 1;
  padding: 8px;
  border: none;
  margin-right: 10px;
  background-color: #472278;
  padding: 0.6em 1em;
  border-radius: 10px;
  font-family: 'Outfit';
  font-size: 1.2em;
  font-weight: 300;
  color: #ffffffd8;
}

.add-comment-button {
  background: #9100FF;
  border: none;
  padding: 8px 12px;
  height: 2.3em;
  border-radius: 8px;
  color: #fff;
  cursor: pointer;
  font-size: 1.2em;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  align-self: center;
}

.delete-comment-button {
  background: none;
  border: none;
  color: #ff4d4d;
  font-size: 18px;
  cursor: pointer;
  margin-left: 10px;
}

.delete-comment-button:hover {
  color: #ff1a1a;
}

/* Asegura que los spans no bloqueen los clics en el input */
.custom-checkbox-display,
.label-color-box {
  pointer-events: none;
}

.label-color-box {
  position: relative;
  display: inline-block;
  width: 18px;
  height: 18px;
  margin-right: 8px;
}

.tasks-counter {
  position: absolute;
  bottom: 10px;
  right: 1.3em;
  display: flex;
  align-items: center;
  padding: 4px 8px;
  border-radius: 12px;
  color: #AC9ED4;
  font-size: 0.9em;
  font-weight: 500;
  font-family: 'Outfit';
}

.tasks-icon {
  margin-left: 0.3em;
}

.tasks-count {
  font-weight: medium;
}

.comments-counter {
  position: absolute;
  bottom: 10px;
  right: 4em;
  display: flex;
  align-items: center;
  padding: 4px 8px;
  border-radius: 12px;
  color: #AC9ED4;
  font-size: 0.9em;
  font-weight: 500;
  font-family: 'Outfit';
}

.comments-icon {
  margin-left: 0.3em;
}

.comments-count {
  font-weight: medium;
}

@media (max-width: 768px) {


  /* Ajustes para el modal */
  .crm-modal {
    width: 70%;
    height: auto;
    max-height: 60vh;
    padding: 3em 3em;
    font-size: 0.9em;
  }

  .crm-close-modal {
    font-size: 1.2em;
    top: 0.5em;
    right: 0.5em;
  }

  /* Ajustes para los encabezados dentro del modal */
  .modal-thread-info h2 {
    font-size: 1.4em;
  }

  .modal-thread-info p {
    font-size: 1em;
  }

  .modal-labels h3, 
  .modal-description h3,
  .modal-tasks h3,
  .modal-comments h3 {
    font-size: 1.2em;
  }

  .label-selector {
    width: 100%;
    height: 2.5em;
    font-size: 0.9em;
    padding: 0 8px;
  }

  .selected-labels {
    gap: 3px;
  }

  .selected-label {
    padding: 4px 8px;
    font-size: 0.9em;
  }

  .label-color-box {
    width: 12px;
    height: 12px;
    margin-right: 6px;
  }

  .dropdown-label-option {
    padding-left: 2em;
    font-size: 0.9em;
  }

  .custom-checkbox-display {
    width: 16px;
    height: 16px;
    margin-right: 0.4em;
  }

  .modal-description textarea {
    font-size: 1em;
    padding: 0.8em;
    border-radius: 12px;
  }

  .save-button {
    padding: 0.7em 1.5em;
    font-size: 0.9em;
  }

  /* Ajustes para las listas de tareas y comentarios */
  .tasks-list, .comments-list {
    font-size: 0.9em;
  }

  .task-item, .comment-item {
    padding: 0.4em 0.8em;
    font-size: 0.9em;
  }

  .task-completed {
    font-size: 0.9em;
  }

  .delete-task-button, .delete-comment-button {
    font-size: 1em;
  }

  .add-task-input, .add-comment-input {
    font-size: 1em;
    padding: 0.5em 0.8em;
  }

  .add-task-button, .add-comment-button {
    padding: 0.5em 8px;
    font-size: 1em;
  }

  /* Ajustes para botones dentro del modal */
  .crm-preset-button, .crm-add-custom-button, .crm-delete-column-button {
    font-size: 0.9em;
    padding: 8px;
  }

  /* Ajustes para el selector de etiquetas */
  .label-dropdown {
    top: 6em;
    padding: 8px;
    font-size: 0.9em;
  }

  /* Ajustes para la línea de etiquetas en las tarjetas */
  .label-line {
    width: 5em;
    height: 0.4em;
    margin: 0.3em 0;
  }

  .label-indicator {
    margin-right: 1px;
  }

  /* Ajustes para la sección de descripción */
  .modal-description textarea {
    font-size: 0.9em;
  }
  
  /* Ajustes para el selector de etiquetas dentro del modal */
  .label-selector-checkbox {
    width: 14px;
    height: 14px;
  }

    /* Estilos para la sección de opciones de columnas */
.crm-column-options {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 80%;
  justify-self: center;
}
}

/* Estilos para la sección de opciones de columnas */
.crm-column-options {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

/* Estilos para los botones de selección predeterminada */
.crm-preset-button {
  display: flex;
  background-color: #00000000;
  font-family: 'Outfit';
  align-items: center;
  gap: 10px;
  border: none;
  padding: 0;
  color: #fff;
  font-size: 1em;
  text-transform: uppercase;
}


/* Estilos para la lista de columnas predeterminadas */
.crm-predefined-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  background: #00000000;
  border-radius: 8px;
  padding: 10px;
  overflow-y: auto;
}

.crm-predefined-item {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #ac9ed4;
  cursor: pointer;
  transition: background 0.3s, transform 0.2s;
  font-family: 'Outfit';
  font-size: 1.2em;
  resize: vertical;
  border: none;
  padding: 1em;
  background: #2D145C 0% 0% no-repeat padding-box;
  box-shadow: inset 0px 3px 12px #00000043;
  border-radius: 17px;
}


.item-icon {
  font-size: 14px;
}

/* Estilos para la sección de columna personalizada */
.crm-custom-column {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.crm-custom-input {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #e9e6ef;
  cursor: pointer;
  transition: background 0.3s, transform 0.2s;
  font-family: 'Outfit';
  font-size: 1.2em;
  resize: vertical;
  border: none;
  padding: 1em;
  background: #2D145C 0% 0% no-repeat padding-box;
  box-shadow: inset 0px 3px 12px #00000043;
  border-radius: 17px;
}

.crm-custom-input:focus {
  outline: none;
  background: #341a66 0% 0% no-repeat padding-box;
  box-shadow: inset 0px 3px 12px #00000043;
}

.crm-add-custom-button {
  background-color: #9100FF;
  border: none;
  padding: 0.9em 2em;
  color: #fff;
  font-family: 'Poppins';
  font-size: medium;
  text-transform: uppercase;
  border-radius: 30px;
  box-sizing: border-box;
  width: 100%;
  cursor: pointer;
  margin-top: 0.5em;
}

.crm-add-custom-button:hover {
  transform: translateY(-3px);
}

/* Estilos para la sección de edición de columna */
.crm-edit-section {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.crm-edit-section label{
  font-family: 'Outfit';
  font-size: 1.3em;
}

/* Estilos para el botón de renombrar columna */
.crm-rename-button {
  background-color: #9100FF;
  border: none;
  padding: 0.9em 2em;
  color: #fff;
  font-family: 'Poppins';
  font-size: medium;
  text-transform: uppercase;
  border-radius: 30px;
  box-sizing: border-box;
  width: 100%;
  cursor: pointer;
  margin-top: 0.5em;
}

.crm-rename-button:hover {
  transform: translateY(-3px);
}
.crm-rename-button svg {
  margin-right: 0.5em;
}

/* Estilos para el botón de eliminar columna */
.crm-delete-column-button {
  background: #EF3F35; /* Color rojo para indicar peligro */
  border: none;
  padding: 0.9em 2em;
  color: #fff;
  font-family: 'Poppins';
  font-size: medium;
  text-transform: uppercase;
  border-radius: 30px;
  box-sizing: border-box;
  width: 100%;
  cursor: pointer;
  margin-top: 0.5em;
}

.crm-delete-column-button svg {
  margin-right: 0.5em;
}

.crm-delete-column-button:hover {
  transform: translateY(-3px);
}

/* Estilos para el contenedor del botón */
.go-to-chat-button-container {
  margin-top: 0.2em; /* Ajusta según sea necesario */
  text-align: start; /* Centra el botón */
}

/* Estilos para el botón "Ir al chat" */
.go-to-chat-button {
  display: inline-flex; /* Permite alinear el texto y el ícono */
  align-items: center; /* Centra verticalmente el contenido */
  padding: 0.6em 1.2em; /* Espaciado interno */
  background-color: #987FE2; /* Color de fondo (verde) */
  color: #fff; /* Color del texto */
  border: none; /* Sin bordes */
  border-radius: 8px; /* Bordes redondeados */
  cursor: pointer; /* Cursor de puntero al pasar el mouse */
  font-size: 0.95em; /* Tamaño de fuente */
  transition: background-color 0.3s ease, transform 0.2s ease; /* Transiciones suaves */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Sombra sutil */
  gap: 0.5em;
  font-family: 'Poppins';
}

/* Estilos al pasar el mouse sobre el botón */
.go-to-chat-button:hover {
  transform: translateY(-2px); /* Efecto de elevación */
}

/* Estilos para el ícono dentro del botón */
.go-to-chat-icon {
  margin-left: 0em; /* Espacio entre el texto y el ícono */
  font-size: 1.1em; /* Tamaño del ícono */
  transition: transform 0.3s ease; /* Transición para el ícono */
}

/* Opcional: Efecto al pasar el mouse sobre el ícono */
.go-to-chat-button:hover .go-to-chat-icon {
  transform: translateX(3px); /* Desplaza el ícono ligeramente */
}


/* Estilos para el filtro de ordenamiento personalizado */
.order-filter {
display: flex;
align-items: center;
cursor: pointer;
position: relative;
color: #987FE2;
font-family: 'Poppins', sans-serif;
font-weight: 400;
font-size: medium;
margin-bottom: 1em; /* Espaciado inferior para separar del contenido */
margin-top: 1em;
margin-left: 1.4em;
}

.order-filter span {
user-select: none; /* Evita la selección de texto al hacer clic */
}

.caret-icon {
margin-left: 5px;
color: #987FE2;
cursor: pointer;
}

.order-dropdown {
position: absolute;
top: 2.5em;
left: 0;
background: linear-gradient(
  to bottom,
  #191742,
  #191742
);
border-radius: 20px;
padding: 5px 0;
z-index: 1000;
width: 15em;
padding: 0.8em;
box-shadow: inset 0 0 25px #ffffff54; /* Sombra interna uniforme */
}

.order-dropdown div {
padding: 5px 15px;
color: #ffffff;
font-family: 'Poppins', sans-serif;
font-weight: 400;
font-size: medium;
cursor: pointer;
text-transform: uppercase;
text-align: left;
}

.order-dropdown div:hover {
transform: translateY(-3px); /* Levanta el elemento al hacer hover */
background-color: rgba(255, 255, 255, 0.1); /* Añade un ligero fondo al hover */
border-radius: 10px;
}

/* Opcional: Animación de apertura del dropdown */
.order-dropdown {
animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
from {
  opacity: 0;
  transform: translateY(-10px);
}
to {
  opacity: 1;
  transform: translateY(0);
}
}
/* CRM.css */

/* Nueva sección para campos adicionales en el modal */
.modal-additional-fields {
margin-top: 1em;
display: flex;
flex-direction: column;
width: 95%;
}

.modal-field {
display: flex;
flex-direction: column;
margin-bottom: 0.8em;
}

.modal-field label {
margin-bottom: 0.3em;
font-weight: bold;
}

.modal-input {
width: 100%;
padding: 10px; 
resize: vertical;
border: none;
color: #ffffff66;
font-family: 'Outfit';
font-size: 1.1em;
font-weight: 300;
padding: 1em;
background: #2D145C 0% 0% no-repeat padding-box;
box-shadow: inset 0px 3px 12px #00000043;
border-radius: 17px;
}

/* Opcional: Ajustes para mantener la consistencia */
.modal-description, .modal-additional-fields {
margin-bottom: 0em;
}

/* Asegúrate de que los inputs tengan el mismo estilo que otros elementos del modal */
/* === Personalización del Scrollbar para .crm-modal === */

/* Para navegadores Webkit (Chrome, Safari, Edge) */
.crm-modal::-webkit-scrollbar {
width: 8px; /* Ancho reducido para un scrollbar más pequeño */
}

.crm-modal::-webkit-scrollbar-track {
background: #2D145C; /* Color de fondo de la pista del scrollbar */
border-radius: 4px;   /* Bordes redondeados */
}

.crm-modal::-webkit-scrollbar-thumb {
background-color: #987FE2; /* Color del pulgar del scrollbar */
border-radius: 4px;         /* Bordes redondeados */
border: 2px solid #2D145C;  /* Espaciado alrededor del pulgar para un efecto de padding */
}

.crm-modal::-webkit-scrollbar-thumb:hover {
background-color: #ac9ed4; /* Cambio de color al pasar el mouse */
}

/* Para Firefox */
.crm-modal {
scrollbar-width: thin; /* Hacer el scrollbar más delgado */
scrollbar-color: #987FE2 #2D145C; /* Color del pulgar y de la pista */
}

/* Opcional: Suavizar el scroll para una mejor experiencia */
.crm-modal {
scroll-behavior: smooth;
}
/* Estilo para la advertencia flotante */
.too-fast-warning {
position: fixed;
bottom: 20px;
right: 20px;
background-color: rgba(255, 0, 0, 0.9); /* Fondo rojo semi-transparente */
color: white;
padding: 10px 20px;
border-radius: 5px;
box-shadow: 0px 2px 10px rgba(0,0,0,0.3);
opacity: 0;
animation: fadeInOut 3s forwards;
z-index: 1000;
font-size: 14px;
}

/* Animación para desvanecer la advertencia */
@keyframes fadeInOut {
0% { opacity: 0; }
10% { opacity: 1; }
90% { opacity: 1; }
100% { opacity: 0; }
}
/* Clase para la tarjeta que se está arrastrando */
.crm-card.dragging {
opacity: 0.5;
transform: scale(0.98);
}

/* Clase para la tarjeta sobre la cual se está arrastrando */
.crm-card.drag-over {
border: 2px dashed #ac9ed4;
transform: translateY(5px);
}

/* Clase para el placeholder */
.crm-card.placeholder {
border: 2px dashed #ac9ed4;
height: 6.5em;
margin-bottom: 10px;
border-radius: 35px;
background: transparent;
pointer-events: none;
opacity: 0;
animation: fadeIn 0.2s forwards;
}

@keyframes fadeIn {
from {
  opacity: 0;
  transform: translateY(-10px);
}
to {
  opacity: 1;
  transform: translateY(0);
}
}

/* Transiciones suaves */
.crm-card {
transition: transform 0.2s ease, opacity 0.2s ease, border 0.2s ease;
}
p.company-name {
margin-top: 0.2em;
}

/* Aadir nuevo estado visual para tarjeta en proceso de guardado */
.crm-card.saving {
opacity: 0.8;
filter: blur(0.5px);
background: #191743ee;
position: relative;
transition: all 0.3s ease;
}

.crm-card.saving::before {
content: '';
position: absolute;
top: 0;
left: 0;
right: 0;
bottom: 0;
background: rgba(18, 17, 47, 0.894);
border-radius: 35px;
pointer-events: none; /* Solo el overlay es no interactivo */
}

.crm-card-header.saving::before {
content: '';
position: absolute;
top: 0;
left: 0;
right: 0;
bottom: 0;
background: rgba(18, 17, 47, 0.894);
border-radius: 35px;
pointer-events: none; /* Solo el overlay es no interactivo */
}


.crm-card.saving::after {
content: 'Guardando...';
position: absolute;
top: 45%;
left: 55%;
transform: translate(-50%, -50%);
color: #fff;
font-size: 1.3em;
font-family: 'Outfit';
text-shadow: 0 0 4px rgba(0,0,0,0.5);
pointer-events: none;
animation: pulse 1.5s infinite;
z-index: 1;
}

@keyframes pulse {
0% { opacity: 0.5; }
50% { opacity: 1; }
100% { opacity: 0.5; }
}

/* Estilos para las advertencias de operaciones */
.operation-warning {
position: fixed;
top: 20px;
right: 20px;
padding: 15px;
border-radius: 8px;
display: flex;
align-items: center;
gap: 10px;
z-index: 1000;
animation: slideIn 0.3s ease-out;
}

.operation-warning.warning {
background-color: #ff6b6b;
color: white;
border: 2px solid #ff5252;
}

.operation-warning.caution {
background-color: #ffd93d;
color: black;
border: 2px solid #ffc107;
}

.warning-icon {
font-size: 20px;
}

.warning-message {
font-size: 14px;
font-weight: 500;
}

@keyframes slideIn {
from {
  transform: translateX(100%);
  opacity: 0;
}
to {
  transform: translateX(0);
  opacity: 1;
}
}

/* Estilo hover para tarjetas en estado saving */
.crm-card.saving:hover {
opacity: 0.9;
transform: translateY(-2px);
box-shadow: 0 5px 15px rgba(0,0,0,0.3);
}

/* Estilos para el filtro de agentes */
.agent-filter {
margin-top: 15px;
padding: 0 15px;
}

.agent-select {
width: 100%;
padding: 8px 12px;
border-radius: 8px;
background-color: #191743;
color: white;
border: 1px solid #ac9ed4;
font-family: 'Outfit', sans-serif;
font-size: 14px;
}

.agent-select:focus {
outline: none;
border-color: #ffffff;
}

.agent-select option {
background-color: #191743;
color: white;
}

/* Media queries para dispositivos móviles */
@media (max-width: 768px) {
.crm-modal {
  width: 90%;
  margin: 20px;
  padding: 15px;
  border-radius: 20px;
}

.modal-content-scroll {
  padding: 1em;
}

.modal-thread-info {
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.label-selector {
  width: 100%;
}

.modal-additional-fields {
  display: flex;
  flex-direction: column;
}

.modal-field {
  width: 100%;
  margin-bottom: 10px;
}

.add-task-container,
.add-comment-container {
  flex-direction: row;
  gap: 10px;
}

.add-task-input,
.add-comment-input {
  width: 100%;
}

.save-button {
  width: 100%;
}
}

.dropdown-section-title {
padding: 8px 12px;
font-size: 0.85em;
color: #6c6684;
font-weight: 600;
text-transform: uppercase;
letter-spacing: 0.5px;
border-top: 1px solid rgba(172, 158, 212, 0.2);
margin-top: 8px;
background-color: rgba(172, 158, 212, 0.05);
cursor: default;
pointer-events: none;
}

.owner-name {
font-size: 0.8em;
color: #ac9ed4;
margin-top: 0.2em;
font-style: italic;
}

.company-name {
  font-size: 0.9em;
  color: #ac9ed4;
  margin-top: 0em;
  font-style: italic;
  margin: 0;
  }

.order-dropdown div {
padding: 8px 12px;
cursor: pointer;
transition: background-color 0.2s;
}

.order-dropdown div:hover {
background-color: rgba(172, 158, 212, 0.1);
}

.order-dropdown {
max-height: 24em;
overflow-y: auto;
}

.team-modal {
background: linear-gradient(to bottom, #191742, #191742);
border-radius: 20px;
z-index: 1000;
width: 25em;
padding: 1.5em 1.5em;
box-shadow: inset 0 0 25px #ffffff54;
transform: translateY(-3px);
background-color: rgba(255, 255, 255, 0.1);
border-radius: 30px;
animation: fadeIn 0.3s ease-in-out;
color: #ffffff;
font-family: 'Poppins', sans-serif;
font-weight: 400;
font-size: 1.2em;
text-transform: uppercase;
text-align: left;
max-height: 25em;
overflow-y: auto;
}

.team-modal-item {
padding: 8px 12px;
display: flex;
justify-content: start;
align-items: center;
cursor: pointer;
transition: background-color 0.2s;
}

.team-modal-item:hover {
background-color: rgba(255, 255, 255, 0.1);
}

.remove-owner-button {
color: #6c6684;
background: none;
border: none;
padding: 4px;
cursor: pointer;
opacity: 0;
transition: opacity 0.2s, color 0.2s;
}

.team-modal-item:hover .remove-owner-button {
opacity: 1;
}

.remove-owner-button:hover {
color: #ff4444;
}

@keyframes fadeIn {
from {
  opacity: 0;
  transform: translateY(-10px);
}
to {
  opacity: 1;
  transform: translateY(0);
}
}

@media (max-width: 480px) {
  .team-modal {
      width: 85%;
      padding: 1em;
      max-height: 70vh;
  }
}

@media (max-width: 768px) {
  .team-modal {
      width: 55%;
      max-width: 320px;
      margin: 0 15px;
      padding: 1.2em;
  }
}

/* Añadir estos estilos */
.importe-value {
  font-size: 1.1em;
  color: #d8d3ea;
  margin-top: 0.4em;
  margin-bottom: 0;
  padding-bottom: 0;
  font-family: 'Outfit';
  font-weight: 400;
  order: 7; /* Asegura que aparezca después de todos los párrafos */
}

/* Añadir estos estilos */
.column-total {
  font-size: 0.7em;
  color: #d8d3ea;
  margin-left: 0.8em;
  font-family: 'Outfit', sans-serif;
  font-weight: 400;
}

.column-total::before {
  content: '|';
  margin-right: 0.8em;
  color: #ac9ed4;
  opacity: 0.5;
}
/* Contenedor del botón "Exportar a Excel" */
.export-excel-container {
  display: flex;
  justify-content: flex-end; /* Alinea el botón a la derecha */
  padding: 10px 20px;
}

/* Estilo del botón "Exportar a Excel" */
.export-excel-button {
  background: linear-gradient(165deg, rgba(63, 114, 215, .691), #75b1d4);
  border: none;
  border-radius: 12px;
  color: #fff;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  margin-right: 10px;
  padding: 0.6em 0.9em;
  cursor: pointer;
}

/* Ícono dentro del botón */
.export-icon {
  margin-right: 8px;
}


/* Responsive adjustments para el botón */
@media (max-width: 768px) {
  .export-excel-container {
    justify-content: center; /* Centra el botón en pantallas pequeñas */
    padding: 10px;
  }

  .export-excel-button {
    padding: 8px 16px;
    font-size: 0.9em;
  }

  .export-icon {
    margin-right: 5px;
  }
}
.manual-lead-button {
  /* Tamaño y forma */
  width: 3em;
  height: 3em;
  border-radius: 50%;
  
  /* Colores */
  background-color: #26234f; /* Fondo */
  color: #987FE2;           /* Color del ícono "+" */
  
  /* Centramos el ícono dentro del botón */
  display: flex;
  align-items: center;
  justify-content: center;

  /* Quitar borde y estilos por defecto */
  border: none;
  outline: none;
  cursor: pointer;

  opacity: 1;
  /* Transición de escala al hover */
  transition: transform 0.2s;
}

/* Hover (opcional) */
.manual-lead-button:hover {
  transform: scale(1.08);
}


/* Ajustes para pantallas muy pequeñas */
@media (max-width: 480px) {
  .manual-lead-button {
    width: 2.7em;
    height: 2.7em;
  }
}
