.header {
  position: fixed;
  top: 0;
  left: 0;
  padding-left: 5.5em;
  right: 0;
  height: 60px;
  background-color: #010321;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 5px;
  padding-bottom: 5px;
  z-index: 1000;
}

.header-left {
  display: flex;
  align-items: center;
}

.header-logo {
  height: 40px;
  margin-right: 10px;
}

.header-title {
  font-size: 1.5em;
  color: #fff;
  font-family: 'Outfit';
  font-weight: 500;
}

.header-right {
  display: flex;
  align-items: center;
  margin-top: 0.5em;
  margin-right: 1em;
}

.mode-toggle {
  display: flex;
  align-items: center;
  gap: 0.5em;
}

.header-icon {
  color: #fff;
  font-size: 20px;
  margin-left: 15px;
  text-decoration: none;
}

.slider-mode {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #dfdede;
  transition: 0.4s;
  border-radius: 34px;
}

.slider-mode:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  background-color: #504F67;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider-mode {
  background-color:  #8b8888;
}

input:checked + .slider-mode:before {
  transform: translateX(20px);
  background-color: #ffffff;
}

.toggle-sidebar-button {
  background: none;
  border: none;
  color: #fff;
  font-size: 1.15em;
  cursor: pointer;
  margin-right: 1em;
  margin-left: 2em;
}
button.toggle-sidebar-button {
margin-top: 0.4em;
}

.header-left {
  display: flex;
  align-items: center;
}

.page-title {
  color: #977FE2;
}



.threads-modal {
  position: absolute;
  top: 50px;
  right: 10px;
  background-color: #030320;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  padding: 20px;
  z-index: 1000;
}

.threads-modal h3 {
  margin: 0 0 10px;
  font-size: 16px;
}

.threads-modal button {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 3px;
  padding: 5px 10px;
  cursor: pointer;
}

.threads-modal button:hover {
  background-color: #0056b3;
}

@media (max-width: 1024px) {
  .header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 50px;
    padding-left: 4.3em;
    padding-right: 1em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    background-color: #010321;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    z-index: 1000;
  }

  .header-left,
  .header-right {
    display: flex;
    align-items: center;
  }

  .header-right {
    padding-left: 0.5em;
  }

  .header-title {
    font-size: 1.5em;
    margin-right: 10px;
  }

  .toggle-sidebar-button {
    margin-left: 0em;
  }

  .page-title {
    font-size: 1em;
    margin-left: 5px;
  }


  .threads-modal {
    top: 40px;
    right: 5px;
    padding: 15px;
  }

  .threads-modal h3 {
    font-size: 14px;
  }

  .threads-modal button {
    font-size: 0.9em;
    padding: 5px 8px;
  }
}

@media (max-width: 1024px) {
  .avatar-container {
    width: 2.5em;
    height: 2.5em;
  }

  .toggle-sidebar-button {
    margin-right: 0;
  }

  .thread-sender-name {
    display: inline-block;
    max-width: 10em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .avatar-header-container {
    width: 2em;
    height: 2.1em;
  }

  .thread-info-sender {
    gap: 0;
  }

  .avatar-image {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
}

.conversation-counter {
  position: relative;
  display: inline-block;
  margin-left: 20px;
  cursor: pointer;
}

.counter-icon {
  width: 2.2em;
  height: 2.2em;
}

.counter-badge {
  position: absolute;
  top: -8px;
  right: -15px;
  background-color: white;
  color: #6556D5;
  border-radius: 0;
  padding: 0 0.2em;
  font-size: 0.9em;
  font-family: 'Outfit';
  font-weight: 400;
}

.counter-badge-free-conversations {
  position: absolute;
  top: -8px;
  right: -15px;
  background-color: white;
  color: #E89032;
  border-radius: 0;
  padding: 0 0.2em;
  font-size: 0.9em;
  font-family: 'Outfit';
  font-weight: 400;
}

.conversation-counter .tooltip-text {
  visibility: hidden;
  width: max-content;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 8px;
  position: absolute;
  z-index: 1;
  bottom: -50%;
  left: 0%;
  transform: translateX(-80%);
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 0.75em;
}

.conversation-counter:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}

@media (min-width: 1367px) {
  .header {
    height: 60px;
    padding-left: 5.5em;
  }
  
  .header-title {
    font-size: 1.5em;
  }
  
  .page-title {
    font-size: 1.3em;
  }
  
  .counter-icon {
    width: 2.2em;
    height: 2.2em;
  }
}

@media (min-width: 1024px) and (max-width: 1366px) {
  .header {
    height: 55px;
    padding-left: 5em;
  }
  
  .header-title {
    font-size: 1.3em;
  }
  
  .page-title {
    font-size: 1.3em;
  }
  
  .counter-icon {
    width: 2em;
    height: 2em;
  }
  
  .bell-icon {
    width: 22px;
    height: 22px;
  }
  
  .counter-badge,
  .counter-badge-free-conversations {
    font-size: 0.85em;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .header {
    height: 50px;
    padding-left: 4.5em;
  }
  
  .header-title {
    font-size: 1.2em;
  }
  
  .page-title {
    font-size: 1.2em;
  }
  
  .counter-icon {
    width: 1.8em;
    height: 1.8em;
  }
  
  .bell-icon {
    width: 20px;
    height: 20px;
  }
  
  .toggle-sidebar-button {
    margin-left: 2em;
  }
  
  .counter-badge,
  .counter-badge-free-conversations {
    font-size: 0.8em;
    top: -6px;
    right: -12px;
  }
}

@media (max-width: 767px) {
  .header {
    height: 45px;
    padding-left: 2.2em;
  }
  
  .header-title {
    font-size: 1.1em;
    margin-right: 2px;

          /* **Nuevos estilos para truncamiento con puntos suspensivos** */
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          max-width: 50px; /* Ajusta este valor según tus necesidades */
          display: block; /* Asegura que las propiedades de truncamiento funcionen correctamente */
  }
  
  .page-title {
    font-size: 0.8em;
    margin-left: 0px;
      
      /* **Nuevos estilos para truncamiento con puntos suspensivos** */
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 90px; /* Ajusta este valor según tus necesidades */
      display: block; /* Asegura que las propiedades de truncamiento funcionen correctamente */
  
  }
  
  .counter-icon {
    width: 1.6em;
    height: 1.6em;
  }
  
  .bell-icon {
    width: 18px;
    height: 18px;
  }

  .arrow-icon {
    width: 1em !important;
  }
  
  .toggle-sidebar-button {
    margin-left: 0em;
  }
  
  .counter-badge,
  .counter-badge-free-conversations {
    font-size: 0.75em;
    top: -5px;
    right: -10px;
  }

  /* Ajustes Específicos para .header-right */
  .header-right {
    /* Opcional: Puedes ajustar el padding o la disposición si es necesario */
    margin-top: 0.2em; /* Reducir el margen superior */
    gap: 0.3em; /* Espacio entre los elementos */
  }

  /* Reducir el tamaño y margen de los íconos en .header-right */
  .header-right .conversation-counter {
    margin-left: 10px; /* Reducir el margen-left de los contadores */
  }

  /* Reducir el tamaño y margin-left de .notification-bell */
  .header-right .notification-bell {
    margin-left: 10px; /* Reducir el margen-left */
  }

  /* Ajustar el tamaño del ícono de la campana dentro de .notification-bell */
  .header-right .notification-bell .bell-icon {
    width: 1.3em; /* Reducir el tamaño */
    height: 1.3em;
    margin-right: 0.3em; /* Reducir el margen-right */
  }

  /* Responsividad del Modal de Notificaciones */
  .notification-modal {
    width: 12rem; /* Reducir el ancho del modal en móviles */
    padding: 0.6em 1.2em; /* Reducir el padding */
    top: 1em; /* Ajustar la posición superior */
    right: 2.6em; /* Ajustar la posición derecha */
  }

  .notification-title {
    font-size: 0.9em;
  }

  .notification-subtitle {
    font-size: 0.8em;
  }

  .notification-text {
    font-size: 0.8em;
  }

  /* Opcional: Ajustar el backdrop si es necesario */
  .notification-modal-backdrop {
    padding: 5px; /* Reducir el padding */
    background: rgba(0, 0, 0, 0.1); /* Hacer el fondo menos oscuro */
  }

  /* Ajustar la flecha del modal */
  .notification-modal::before {
    top: -5px;
    right: 1em;
    border-width: 5px;
  }
  
}
/* Estilos para el Contenedor de la Campana de Notificaciones */
.notification-bell {
  position: relative; /* Permite posicionar el modal de forma absoluta respecto a este contenedor */
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  margin-left: 1.5em;
}

/* Icono de la Campana */
.bell-icon {
  width: 24px;
  height: 24px;
  align-self: center;
  margin-right: 0.5em;
}

/* Backdrop del Modal de Notificaciones */
.notification-modal-backdrop {
  position: absolute; /* Posiciona el backdrop relativamente al contenedor .notification-bell */
  top: 100%; /* Justo debajo del contenedor */
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 8px;
  padding-top: 10px;
  z-index: 1600;
}

/* Modal de Notificaciones */
.notification-modal {
  position: absolute;
  top: 0.4em;
  right: 1em;
  width: 13rem; /* Tamaño base del modal */
  border-radius: 8px;
  padding: 1em 1.8em;
  z-index: 1700;
  background: #2E2D54 0% 0% no-repeat padding-box;
box-shadow: inset 0px 0px 30px #FFFFFFBA;
border-radius: 24px;
opacity: 1;
}

/* Título del Modal */
.notification-title {
  font-size: 1em;
  font-family: 'Poppins';
  font-weight: 700;
  text-transform: uppercase;
  color: #ffffff;
  margin-bottom: 0.8em;
}

/* Subtítulo del Modal */
.notification-subtitle {
  font-size: 0.9em;
  font-weight: 500;
  color: #ffffff;
  margin-bottom: 0.2em;
  margin-top: 0;
}

/* Texto del Modal */
.notification-text {
  color: #adb1b9;
  font-size: 0.9em;
  margin-bottom: 1rem;
  margin-top: 0;
}

/* Botón del Modal (si tienes alguno) */
.notification-button {
  background: #502886;
  font-family: 'Outfit';
  color: #ffffff;
  border: none;
  border-radius: 0.5rem;
  padding: 0;
  margin-top: 0.6em;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 500;
}


/* Responsividad del Modal */
@media (max-width: 1024px) {
  .notification-modal {
    width: 13rem; /* Reducir el ancho en pantallas medianas */
  }
}

@media (max-width: 768px) {
  .notification-modal {
    width: 12rem; /* Reducir aún más en pantallas pequeñas */
    padding: 0.8em 1.5em;
    right: 1em;
  }

  .notification-title {
    font-size: 0.95em;
  }

  .notification-subtitle {
    font-size: 0.85em;
  }

  .notification-text {
    font-size: 0.85em;
  }
}

@media (max-width: 480px) {
  .notification-modal {
    width: 11rem; /* Tamaño mínimo para móviles */
    padding: 0.6em 1.2em;
    top: 0.8em;
    right: 1em;
  }

  .notification-title {
    font-size: 0.7em;
  }

  .notification-subtitle {
    font-size: 0.7em;
  }

  .notification-text {
    font-size: 0.75em;
  }
}

