/* Sidebar styles */
.sidebar {
  position: fixed;
  padding-top: 15px;
  padding-bottom: 30px;
  top: 0;
  bottom: 0;
  left: 0;
  width: 60px;
  background-color: #010321;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-top: 4.4em;
  z-index: 1000;
}

.sidebar-top,
.sidebar-bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sidebar-icon {
  color: #fff;
  margin: 8px 0;
  font-size: 24px;
  text-decoration: none;
  position: relative; /* Necesario para el tooltip */
}

.sidebar-icon img {
  width: 32px;
  transition: transform 0.3s ease;
}

.sidebar-icon:hover img {
  transform: translateY(-6px); /* Levanta la imagen al hacer hover */
}

/* Tooltip styles */
.sidebar-icon::after {
  content: attr(data-tooltip);
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #444;
  color: #fff;
  padding: 5px 8px;
  border-radius: 5px;
  opacity: 0;
  white-space: nowrap;
  transition: opacity 0.3s, transform 0.3s;
  transform: translateY(10px);
  font-size: 12px;
  pointer-events: none;
}

.sidebar-icon:hover::after {
  opacity: 1;
  transform: translateY(0);
}

.logout-button {
  background: none;
  border: none;
  cursor: pointer;
  color: #fff;
  padding: 8px;
  margin: 0 0;
  font-size: 26px;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.85;
}

.logout-button svg {
  transform: scale(1.1);
  filter: brightness(1.1);
}

.logout-button:hover {
  color: #fff;
  transform: translateY(-6px);
  opacity: 1;
}

/* Asegurar que el tooltip funcione también para el botón */
.logout-button::after {
  content: attr(data-tooltip);
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #444;
  color: #fff;
  padding: 5px 8px;
  border-radius: 5px;
  opacity: 0;
  white-space: nowrap;
  transition: opacity 0.3s, transform 0.3s;
  transform: translateY(10px);
  font-size: 12px;
  pointer-events: none;
}

.logout-button:hover::after {
  opacity: 1;
  transform: translateY(0);
}

/* Media queries para diferentes tamaños de pantalla */
/* Pantallas grandes (1367px en adelante) */
@media (min-width: 1367px) {
  .sidebar {
    width: 60px;
    padding-top: 4.4em;
  }
  
  .sidebar-icon img {
    width: 32px;
  }
  
  .logout-button svg {
    font-size: 26px;
  }
}

/* Notebooks (1024px a 1366px) */
@media (min-width: 1024px) and (max-width: 1366px) {
  .sidebar {
    width: 55px;
    padding-top: 4em;
  }
  
  .sidebar-icon {
    margin: 6px 0;
  }
  
  .logout-button svg {
    font-size: 24px;
  }
}

/* Tablets (768px a 1023px) */
@media (min-width: 768px) and (max-width: 1023px) {
  .sidebar {
    width: 50px;
    padding-top: 3.5em;
  }
  
  .sidebar-icon {
    margin: 5px 0;
  }
  
  .logout-button svg {
    font-size: 22px;
  }
}

/* Móviles (menos de 768px) */
@media (max-width: 767px) {
  .sidebar {
    width: 35px;
    padding-top: 2.6em;
  }
  
  .sidebar-icon {
    margin: 0px 0;

  }
  .sidebar-icon img{
    width: 0.9em;
  }
  
  .logout-button svg {
    font-size: 0.7em;
  }
}
