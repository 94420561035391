.profile-page {
  padding-top: 2em;
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center; 
  justify-content: center;
  height: fit-content;
  padding-bottom: 2em;
  color: white;
  font-family: 'Poppins', sans-serif;
}

.profile-page-container {
  display: flex
;
  justify-content: space-between;
  width: auto;
  margin-top: 0;
  gap: 2em;
}

.profile-section-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.consumos-container {
  width: 32em;
  background: #000321; 
  padding: 1em 2.3em;
  border-radius: 45px;
  border: 2px solid var(--unnamed-color-9101ff);
  background: transparent linear-gradient(180deg, #953BF526 0%, #57238FB3 100%) 0% 0% no-repeat padding-box;
  border: 2px solid #9101FF;
  border-radius: 58px;
  opacity: 1;
  overflow-y: auto; 
  overflow-x: hidden;
  height: 75vh; 
}
.team-section{
  padding-left: 1.3em;
}

.payments-container {
  width: 90%;
  background: #000321;
  padding: 2.3em 2.3em;
  border: 2px solid var(--unnamed-color-9101ff);
  background: transparent linear-gradient(180deg, #953BF526 0%, #57238FB3 100%) 0% 0% no-repeat padding-box;
  border: 2px solid #9101FF;
  border-radius: 58px;
  opacity: 1;

  /* Elimina la altura y scroll forzado */
  /* height: 75vh; */
  /* overflow-y: auto; */

  /* Añade, por ejemplo, un min-height o deja que se ajuste al contenido */
  min-height: auto;
  height: auto; /* se ajusta al contenido */
  min-width: auto;
  width: auto; /* se ajusta al contenido */
}


/* Títulos */
.profile-title {
  font-size: 2em;
  font-family: 'Outfit', sans-serif;
  margin-bottom: 0.8em;
}

.payments-title {
  font-size: 1.8em;
  text-align: center;
  font-weight: 500;
  font-family: 'Outfit', sans-serif;
  margin-bottom: 0.8em;
}

/* Foto de perfil */
.profile-picture {
  margin: 0;
}

/* Iniciales arriba, centradas */
.initials-circle {
  width: 6em;
  height: 6em;
  background-color: #6556D5; 
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}
.initials-circle p{
  font-size: 2.8em;
  color: white;
  font-weight: 300;
  font-family: 'Outfit';
}

/* Contenedor de toda la info de usuario */
.user-info {
  /* Colocamos en columna y centramos */
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;   /* Centra el texto en este contenedor */
  margin-bottom: 0em;
  gap: 10px;
  width: 100%;
}

/* Alineamos detalles de usuario al centro */
.user-details {
  font-size: 1.2em;
  overflow: hidden; 
  word-wrap: break-word; 
  min-width: 0;
  text-align: center; /* Centra texto del interior */
}

.name-user {
  font-size: 1em;
  margin: 0;
  padding: 0;
  
}
.email-user {
  font-size: 1em;
  margin: 0;
  padding: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 260px;
}


/* Name display y edición */
.name-display {
  display: inline-flex;
  justify-content: center; 
  align-items: center;
  gap: 0.8em;
  flex-wrap: wrap;
  padding: 0;
  margin-bottom: 0.3em;
}

.edit-name-display{
  margin-top: 0.5em;
}

.name-editor {
  display: flex;
  flex-direction: column; /* Para colocar el input y los botones en columna */
  align-items: center;    /* Centra horizontalmente */
  justify-content: center; 
  gap: 0.5em;             /* Espacio vertical entre elementos */
  margin: 0em;
  margin-bottom: 0.8em;
  margin-top: 0.5em;
}
/* Contenedor de los dos botones */
.edit-name-buttons-row {
  display: flex;
  gap: 0em;
  margin-top: 0.5em; /* Pequeño espacio extra arriba */
}

/* Botones de edición */
.edit-name-button {
  background-color: #ffffff3d;
  border: 1px solid #fff;
  padding: 0.2em 1em;
  color: #fff;
  font-family: 'Poppins';
  font-size: 0.7em;
  text-transform: uppercase;
  border-radius: 30px;
  cursor: pointer;
  margin: 0;
}

.new-name-input {
  background-color: #2A1055;
  color: #b4a7cc;
  border: none;
  padding: 1em;
  border-radius: 15px;
  margin-right: 0.5em;
  font-family: 'Outfit';
  font-size: 0.8em;
}

.save-newname-button,
.cancel-edit-button {
  background-color: #ffffff3d;
  border: 1px solid #fff;
  padding: 0.4em 1.5em;
  color: #fff;
  font-family: 'Poppins';
  font-size: 0.7em;
  text-transform: uppercase;
  border-radius: 30px;
  cursor: pointer;
  margin-right: 0.5em;
}

.cancel-edit-button {
  background-color: transparent;
}

/* Sección de Mi Equipo */
.team-title {
  font-size: 1.8em;
  text-align: start;
  font-weight: 500;
  font-family: 'Outfit', sans-serif;
  margin-bottom: 1em;
  margin-top: 1.5em;
}

.team-list {
  list-style: none;
  padding: 0;
  width: 100%;
}

.team-member {
  display: flex;
  align-items: center;
  margin-bottom: 1em;
  flex-wrap: wrap;
  gap: 10px;
}

.member-details {
  flex: 1;
  font-size: 1em;
  overflow: hidden;
  word-wrap: break-word;
}

.delete-member-button {
  background: none;
  border: none;
  color: #AC9ED4;
  font-size: 1.2em;
  cursor: pointer;
  transition: color 0.2s;
}

.delete-member-button:hover {
  color: #FF4D4D;
}

.add-member-button {
  background-color: #ffffff3d;
  border: 1px solid #fff;
  padding: 0.6em 1.2em;
  color: #fff;
  font-family: 'Poppins';
  font-size: 0.9em;
  text-transform: uppercase;
  border-radius: 30px;
  cursor: pointer;
  margin-top: 1em;
  transition: background-color 0.3s, transform 0.2s;
}

.add-member-button:hover {
  background-color: #ffffff55;
  transform: translateY(-3px);
}

.add-member-form {
  margin-top: 0.5em;
  padding: 1.5em;
  align-items: center;
  justify-content: center;
}

.add-member-form h3 {
  font-family: 'Outfit', sans-serif;
  font-size: 1.3em;
  font-weight: 500;
  margin: 0;
  margin-bottom: 1em;
}

.input-field {
  margin-bottom: 0.8em;
  width: 90%;
  display: flex;
  align-items: center;
  gap: 10px;
  color: #beb8d0; /* color del texto real del input */
  cursor: pointer;
  transition: background 0.3s, transform 0.2s;
  font-family: 'Outfit';
  font-size: 1em;
  resize: vertical;
  border: none;
  padding: 1em;
  background: #2D145C 0% 0% no-repeat padding-box;
  box-shadow: inset 0px 3px 12px #00000043;
  border-radius: 17px;
}

/* Cambiamos el color del placeholder */
.input-field::placeholder {
  color: #beb8d0;
}

/* Para mayor compatibilidad, puedes añadir también estos prefijos */
.input-field::-webkit-input-placeholder {
  color: #beb8d0;
}
.input-field:-ms-input-placeholder { 
  color: #beb8d0;
}
.input-field::-ms-input-placeholder { 
  color: #beb8d0;
}


.form-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 1em;
  gap: 1em;
}

.save-button {
  background-color: #9100FF;
  border: none;
  padding: 0.9em 2em;
  color: #fff;
  font-family: 'Poppins';
  font-size: medium;
  text-transform: uppercase;
  border-radius: 30px;
  cursor: pointer;
  transition: transform 0.2s;
}

.save-button:hover {
  transform: translateY(-3px);
}

.cancel-button {
  background: none;
  border: 1px solid #fff;
  padding: 0.9em 2em;
  color: #fff;
  font-family: 'Poppins';
  font-size: medium;
  text-transform: uppercase;
  border-radius: 30px;
  cursor: pointer;
  transition: transform 0.2s;
}

.cancel-button:hover {
  transform: translateY(-3px);
}

.fictitious-team-section h3,
.real-team-section h3 {
  font-size: 1.3em;
  margin-bottom: 0.5em;
  font-family: 'Outfit', sans-serif;
}

/* Contadores */
.conversations-counter {
  display: flex;              /* Para usar flexbox */
  flex-direction: column;     /* Asegura que los elementos se ubiquen en columna */
  align-items: center;        /* Centra horizontalmente */
  justify-content: center;    /* Centra verticalmente */
  
  padding: 0.8em;
  color: white;
  margin-bottom: 2em;
  text-align: center;
  border: 2px solid var(--unnamed-color-9101ff);
  background: transparent linear-gradient(180deg, #953BF526 0%, #57238FB3 100%) 0% 0% no-repeat padding-box;
  border: 2px solid #9101FF;
  border-radius: 50px;
  opacity: 1;
  height: 13em;
}


.conversations-title {
  display: block;          /* Asegura que el elemento sea de bloque */
  margin: 0 auto;          /* Lo centra horizontalmente si tiene un ancho definido */
  text-align: center;      /* Centra el texto dentro del elemento */
  font-size: 1.35em;
  font-family: 'Outfit', sans-serif;
  font-weight: bold;
  margin-bottom: 0.2em;
  max-width: 300px;        /* Opcional, si deseas un ancho máximo */
}


.conversations-info {
  font-size: 1.2em;
  margin-bottom: 0.2em;
  margin-top: 0.5em;
}
.conversations-info-2 {
  font-size: 1em;
  margin-bottom: 0.2em;
  margin-top: 0em;
}

.conversations-count {
  font-size: 2.23em;
  font-weight: bold;
  margin-bottom: 0.2em;
  margin-top: 0.5em;
}

.subscription-counter {
  padding: 2em;
  border-radius: 15px;
  color: #ffffff;
  text-align: center;
  transition: transform 0.3s;
  /* UI Properties */
box-shadow: inset 0px 0px 50px #0000008D;
border-radius: 58px;
opacity: 1;
height: 10em;
display: flex;              /* Para usar flexbox */
flex-direction: column;     /* Asegura que los elementos se ubiquen en columna */
align-items: center;        /* Centra horizontalmente */
justify-content: center;    /* Centra verticalmente */
}

.subscription-counter:hover {
  transform: translateY(-5px);
}

.subscription-title {
  font-size: 1.5em;
  font-family: 'Outfit', sans-serif;
  margin-bottom: 0.5em;
  font-weight: 500;
}

.subscription-date {
  font-size: 1.1em;
  color: #dddddd;
}

/* Responsivo */
@media (max-width: 1520px) {
  .profile-page-container {
    flex-direction: column;
    align-items: center;
    gap: 0em;
  }
  .consumos-container {
    width: 100%;
    background: #000321; 
    padding: 1em 2.3em;
    border-radius: 0px;
    border: none;
    background: #00000000;
    opacity: 1;
    overflow-y: auto; 
    overflow-x: hidden;
    height: auto; /* Ajusta la altura al contenido */
    box-sizing: border-box; /* Incluye padding en el ancho */
    transition: max-width 0.3s ease; /* Suaviza el cambio de ancho */
  }
  
  .payments-container{
    border-radius: 50px;
  }

  .profile-section-container {
    width: 100%;
    margin-bottom: 20px;
  }
  .profile-section-container:nth-child(2) {
    order: -1; /* Mándalo arriba */
  }

  .team-section{
    padding: 0;
  }
}

@media (max-width: 768px) {
  .profile-section-container:nth-child(2) {
    order: -1; /* Mándalo arriba */
  }
  .consumos-container {
    width: 100%;
    background: #000321; 
    padding: 0;
    border-radius: 0px;
    border: none;
    background: #00000000;
    opacity: 1;
    overflow-y: auto; 
    overflow-x: hidden;
    height: auto; /* Ajusta la altura al contenido */
    box-sizing: border-box; /* Incluye padding en el ancho */
    transition: max-width 0.3s ease; /* Suaviza el cambio de ancho */
  }
  
  .profile-title,
  .payments-title,
  .team-title {
    font-size: 1.5em;
  }
  .payments-container{
    border-radius: 45px;
  }
  .conversations-title {
    font-size: 1.2em;
    font-weight: 500;
  }
  .name-user, .email-user{
    font-size: 15px;
  }


  .conversations-count {
    font-size: 1.8em;
  }
  .conversations-info,
  .conversations-info-2{
    font-size: 1em;
  }

  .edit-name-button,
  .save-newname-button,
  .cancel-edit-button {
    font-size: 0.9em;
    padding: 0.5em 1.3em;
  }

  .new-name-input {
    padding: 0.8em;
    font-size: 0.9em;
  }


  .conversations-counter {
    padding: 1em;
  }

  .team-section{
    padding: 0;
  }
  .team-title{
    text-align: center;
  }

  .member-details {
    width: 100%; 
    text-align: left; /* Cambiado de center a left */
  }

  .name-user {
    white-space: nowrap; 
    overflow: hidden; 
    text-overflow: ellipsis; 
    max-width: 100%; 
  }

  .name-display {
    justify-content: center; 
    gap: 5px;
  }

  .user-details {
    font-size: 0.9em;
    text-align: center; 
  }
}
.spinner-payments {
  /* Ajusta el tamaño y estilo de tu spinner */
  border: 4px solid rgba(255, 255, 255, 0.2);
  border-top-color: #fff;
  border-radius: 50%;
  width: 3em;
  height: 3em;
  animation: spin 1s linear infinite;
  margin: 2em auto; /* Centrado horizontalmente */
}

@keyframes spin {
  to { transform: rotate(360deg); }
}
